import React from 'react';
import { useSelector } from 'react-redux';
import { ApiContext } from '../../../services/api/api-config';
import MiscManager from '../components/MiscManagement/MiscManagement';
import MultiSelect from '../components/MultiSelect/MultiSelect';
import DoctorProfileTopBar from '../components/DoctorProfileTopBar/DoctorProfileTopBar';
/*
    //API CALLS
    Medicine groups: 
    - Create groups
    @name: string
    @medicaments: Array<number>

    - Edit groups
    @id : number
    @name: string
    @medicaments: Array<number>

    Endpoint Dependencies: medicine
*/
const MedicineGroups = () => {
    //STATES
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [actualSelectedItems, setActualSelectedItems] = React.useState([]);
    const [wipeData, setWipeData] = React.useState(false);
    const [itemToEditId, setItemToEditId] = React.useState(null);

    // API CALLS
    const api = React.useContext(ApiContext)
    React.useEffect(() => {
        api.medicines.get({params: {pagination:false}});
    }, [api]);

    //DATA FROM API
    const me = useSelector(({ api }) => api.me);
    const medicines = useSelector(({ api }) => api.medicines) || [];

    //HANDLING METHODS
    //Form Methods:
    const handleFormData = (method, sGroups, data) => {
        const getSelectedItemsId = selectedItems.map(item => item.id);

        const params = {
            sGroups: sGroups,
            name: data,
            medicines: getSelectedItemsId
        }
        if (method === 'create') {
            api['medicine_groups'].create({ params }).then(() => {
                setWipeData(true);
            });
        } else {
            api['medicine_groups'].update({ id: itemToEditId, params }).then(() => setWipeData(true));
        }
    };
    const endFormEventListener = listener => {
        if (!listener) {
            setWipeData(true);
            setActualSelectedItems([]);
        } else {
            setWipeData(false);
        }
    }

    const editMode = item => {
        setItemToEditId(item.id);
        setActualSelectedItems(item.medicines)
    };

    //MultiSelect Methods
    const getSelectedItems = selectedItems => setSelectedItems(selectedItems);


    //COMPONENTS PROPS CONFIG
    //MiscManager: sGroups
    const sGroupsConfig = ['medicine_group_read', 'medicine_group_read_medicines', 'medicine_read_id', 'medicine_read_name']

    //MultiSelect Component: Options
    const multiSelectOptions = React.useMemo(() => {
        return medicines.map(medicine => {
            return { name: medicine.name, id: medicine.id }
        });
    }, [medicines])

    return (
        <article className="MedicineGroups">
            <DoctorProfileTopBar me={me} back={true} />
            <section className="container">
                <MiscManager
                    name="Grupo medicinal"
                    entity='medicine_groups'
                    sGroups={sGroupsConfig}
                    noBlameCreator={true}
                    addFormComponent={
                        <MultiSelect
                            labels={["Añadir medicamentos al grupo medicinal", "Editar medicamentos del grupo medicinal:"]}
                            placeholder="Seleccionar medicamentos"
                            options={multiSelectOptions}
                            getSelectedItems={getSelectedItems}
                            editModeActualValues={actualSelectedItems}
                            cleanValues={wipeData}
                        />
                    }
                    addSearchBar={true}
                    searchBarPlaceholder="Buscar grupo medicinal"
                    formPlaceholder={["Añade un nuevo grupo medicinal", "Edita un grupo medicinal"]}
                    letFormDataForMe={true}
                    handleFormData={handleFormData}
                    handleEditMode={editMode}
                    endFormEventListener={endFormEventListener}
                    cleanValues={wipeData}
                />
            </section>
        </article>
    );
}
export default MedicineGroups;