import './MultiSelect.scss'
import React from 'react';
import Exit from '../../../../assets/img/icons/exit.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash';

const MultiSelect = ({ labels, options, getSelectedItems, state, setState, cleanValues, editModeActualValues, placeholder, noInfoMessage = "" }) => {
    const [selected, setSelected] = React.useState([]);

    const handleSelectedItem = (itemName, itemId) => {
        setState
            ? setState([...state, { name: itemName }])
            : setSelected([...selected, { name: itemName, id: itemId }]);
    }
    const removeSelection = id => {
        const index = setState ? state.map(item => item.id).indexOf(id) : selected.map(item => item.id).indexOf(id);
        const newArr = setState ? state.splice(index, 1) : selected.splice(index, 1);

        setState
            ? setState(state.filter(index => index !== newArr))
            : setSelected(selected.filter(index => index !== newArr))
    }
    React.useEffect(() => {
        editModeActualValues && setSelected(editModeActualValues);
        cleanValues && setSelected([]);
    }, [cleanValues, editModeActualValues])
    getSelectedItems && getSelectedItems(selected);

    return (
        <div className="MultiSelect">
            <div className="select-container">
                {
                    editModeActualValues
                        ? <h6>{editModeActualValues.length === 0 ? labels[0] : labels[1]}</h6>
                        : labels ? <h6>{labels[0]}</h6> : null
                }
                <div>
                    {
                        setState
                            ? state.length > 0
                                ? (
                                    state.map((selectedItem, index) =>
                                        <span className="selected-block" key={index}>
                                            {selectedItem.name}
                                            <img src={Exit} alt="Remover Elemento" onClick={() => removeSelection(selectedItem.id)} />
                                        </span>
                                    )
                                )
                                : (
                                    <React.Fragment>
                                        {placeholder && placeholder}
                                        <FontAwesomeIcon icon={faSortDown} />
                                    </React.Fragment>
                                )
                            : selected.length > 0
                                ? (
                                    selected.map((selectedItem, index) =>
                                        <span className="selected-block" key={index}>
                                            {selectedItem.name}
                                            <img src={Exit} alt="Remover Elemento" onClick={() => removeSelection(selectedItem.id)} />
                                        </span>
                                    )
                                )
                                : (
                                    <React.Fragment>
                                        {placeholder && placeholder}
                                        <FontAwesomeIcon icon={faSortDown} />
                                    </React.Fragment>
                                )
                    }
                </div>
            </div>

            <ul className="options-wrapper">
                {
                    options && options.length > 0
                        ?
                        options.map((option, key) =>
                            <li key={key}>
                                <button
                                    type="button"
                                    onClick={() => handleSelectedItem(option.name, option.id)}
                                    disabled={
                                        setState
                                            ? _.find(state,item => item.name === option.name ? true : false)
                                            : _.find(selected, item => item.id === option.id ? true : false)
                                    }
                                >{option.name}</button>
                            </li>
                        )
                        : <div className="alert alert-info">{noInfoMessage}</div>
                }
            </ul>
        </div>
    );
}
export default MultiSelect;