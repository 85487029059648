import React, { useMemo, useState, useEffect, useContext } from 'react';
import './ReportsScreen.scss';
import TopBar from "../../../components/TopBar/TopBar";
import ReactEcharts from 'echarts-for-react';
import { ApiContext } from "../../../services/api/api-config";
import MainTriggersReport from './components/MainTriggersReport/MainTriggersReport';
import { useSelector } from "react-redux";
import moment from 'moment';

const getOption = (xAxis, yAxis) => ({
    title: {
        //text: 'Intensidad',
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#283b56'
            }
        }
    },
    legend: {
        data: ['1', '2']
    },
    dataZoom: {
        show: false,
        start: 0,
        end: 100
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: true,
            data: xAxis
        },
        {
            type: 'category',
            boundaryGap: true,
            data: xAxis,
            show: false
        }
    ],
    yAxis: [
        {
            type: 'value',
            scale: true,
            name: '',
            max: 10,
            min: 0,
            boundaryGap: [0.2, 0.2]
        },
        {
            type: 'value',
            scale: true,
            name: 'Intensidad',
            max: 10,
            min: 0,
            boundaryGap: [0.2, 0.2],
            show: false
        }
    ],
    series: [
        {
            name: 'Intensidad del episodio',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            itemStyle: {
                barBorderRadius: 10
            },
            barWidth: 10,
            data: yAxis
        },
        {
            name: 'Intensidad del episodio',
            type: 'line',
            data: yAxis,
            lineStyle: {
                color: '#3d7572'
            }
        }
    ]
});

const dateFormat = date => date.format('YYYY-MM-DD');
const formatDate = s => moment(s).format('DD/MM/YYYY');
const intensityEcharts = (value) => ({
    value,
    itemStyle: {
        color: (() => {
            if (value < 3) {
                return '#969696';
            }
            if (value < 6) {
                return '#3c912f';
            }
            return '#eb0000';
        })()
    }
});

const ReportsScreen = () => {
    const [reportPeriod, setReportPeriod] = useState('month');
    const api = useContext(ApiContext);

    useEffect(() => {
        const before = dateFormat(moment().add(1, 'day')); //Se añadió un día más a la fecha actual para poder visualizar los registros que se hicieron en un mismo día.

        if (reportPeriod === 'day') {
            api.migraineRecords.get({
                params: {
                    'fromDate[before]': before,
                    'fromDate[after]': dateFormat(moment().subtract(1, 'day').startOf('day'))

                }
            });
            return;
        }
        if (reportPeriod === 'week') {
            api.migraineRecords.get({
                params: {
                    'fromDate[before]': before,
                    'fromDate[after]': dateFormat(moment().subtract(1, 'week').startOf('day')),
                    pagination: false
                }
            });
            return;
        }
        if (reportPeriod === 'month') {
            api.migraineRecords.get({
                params: {
                    'fromDate[before]': before,
                    'fromDate[after]': dateFormat(moment().subtract(1, 'month').startOf('day'))
                }
            });
            return;
        }
        api.migraineRecords.monthlyReport();
    }, [api, reportPeriod]);

    const migraineRecords = useSelector(({ api }) => api.migraineRecords) || [];
    const monthlyReport = useSelector(({ api }) => api.migraineRecordsMonthlyReport) || [];

    const xAxis = useMemo(() => {
        if (reportPeriod === 'week' || reportPeriod === 'month' || reportPeriod === 'day') {
            return migraineRecords.map(({ fromDate }) => fromDate).map(formatDate);
        }
        return monthlyReport.map(({ monthlyPeriod }) => monthlyPeriod).map(formatDate);
    }, [reportPeriod, migraineRecords, monthlyReport]);
    const yAxis = useMemo(() => {
        if (reportPeriod === 'week' || reportPeriod === 'month' || reportPeriod === 'day') {
            return migraineRecords.map(({ intensity }) => intensity).map(intensityEcharts);
        }
        return monthlyReport.map(({ intensity }) => parseInt(intensity)).map(intensityEcharts);
    }, [migraineRecords, reportPeriod, monthlyReport]);

    const echartsOption = useMemo(() => getOption(xAxis, yAxis),
        [xAxis, yAxis]);

    const title = (() => {
        if (reportPeriod === 'day') {
            return 'Reporte diario';
        }
        if (reportPeriod === 'week') {
            return 'Reporte semanal';
        }
        if (reportPeriod === 'month') {
            return 'Reporte mensual';
        }
        return 'Reporte anual';
    })();

    return (
        <div className='ReportsScreen'>
            <TopBar backButton childrenClassName="justify-content-center reportTopBar">
                <h1 className="title">Reportes</h1>
            </TopBar>

            <div className='content scrollable-content'>
                <div className='report-title'>
                    {title}
                </div>
                <div className='graph-info'>
                    <select
                        value={reportPeriod}
                        onChange={e => setReportPeriod(e.target.value)}
                        className='form-control'
                    >
                        <option value='day'>
                            Diario
                        </option>
                        <option value='week'>
                            Semanal
                        </option>
                        <option value='month'>
                            Mensual
                        </option>
                        <option value='year'>
                            Anual
                        </option>
                    </select>
                </div>
                <ReactEcharts option={echartsOption} />
                <MainTriggersReport />
            </div>
        </div>
    );
};

export default ReportsScreen;
