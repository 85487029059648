import React, {useCallback, useContext, useEffect, useState} from 'react';
import './MedicineList.scss';
import MedicineCard from "./MedicineCard/MedicineCard";
import {ApiContext} from "../../services/api/api-config";
import {useSelector} from "react-redux";
import {medicineListSGroups} from "../../services/modelUtils/medicineUtils";
import _ from 'lodash';
import {getNotifier} from '../../services/notifierUtils';

const loadingId = 'MedicineList';
const MedicineList = ({
                          disableDots = false,
                          dynamicMode = false, //Indica la activación del filtro de medicamentos, se necesita especificar qué se necesita filtrar con el prop «searchMedicineType»
                          doctorsCanManageMedicines = false,
                          enableEditMode,
                          onChange,
                          patientsCanAddMedicines = false,
                          selected = [],
                          medicinesAsProp,
                          searchText,
                          withQuantity,
                          searchMedicineType, //Filtra medicamentos: Abortivos/Preventivos
                          onQuantityChange,
                          customProp = ""
                      }) => {

    const api = useContext(ApiContext);
    const me = useSelector(({api}) => api.me);
    const [medicines, setMedicines] = useState([]);

    useEffect(() => {
        if (medicinesAsProp) {
            return setMedicines(medicinesAsProp);
        } else {
            const params = {
                name: searchText,
                pagination: false,
                sGroups: medicineListSGroups
            }
            if (dynamicMode && !patientsCanAddMedicines) params.type = searchMedicineType;
            api.medicines.get({params, loadingId}).then(setMedicines);
        }
        ;
    }, [api, searchText, dynamicMode, searchMedicineType, me.id, patientsCanAddMedicines, medicinesAsProp]);

    const handleSelect = useCallback((medicine) => {
        if (!onChange)
            return;

        const newVal = selected ? [...selected] : [];
        const oldIndex = _.findIndex(selected, opt => opt.id === medicine.id);

        if (oldIndex === -1)
            newVal.push(medicine);
        else
            newVal.splice(oldIndex, 1);
        onChange(newVal);
    }, [onChange, selected]);

    const handleQuantityChange = useCallback((medicine, quantity) => {
        if (!onQuantityChange)
            return;

        const newMedicines = [...medicines];
        const med = _.find(newMedicines, (med) => med.id === medicine.id);
        med.quantity = med.quantity ? med.quantity + quantity : 1;
        onQuantityChange(newMedicines);
    }, [onQuantityChange, medicines]);

    /* ---- FORM METHODS: ADD MEDICINE FOR PATIENTS ---- */
    const [formValue, setFormVal] = useState('');
    const handleInputValue = event => {
        const {value} = event.target;
        const valRegex = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g;
        const valueValidate = valRegex.test(value);

        valueValidate === true
            ? setFormVal(value)
            : setFormVal('');
    }


    const cancelForm = () => setFormVal('');
    const addMedicine = event => {
        event.preventDefault();
        api.medicines.create({params: {name: formValue, sGroups: medicineListSGroups, customProp}})
            .then(res => {
                onChange([...selected || [], {...res, quantity: 1}]);
                setMedicines([...medicines, {...res, quantity: 1}]);
                setFormVal('');
            }).catch((e) => {
            return getNotifier().error(e.detail);
        });
    }
    const removeMedicine = async (id) => {
        try {
            await api.medicines.delete({id, customProp});
            const newSelection = selected.filter(x => x.id !== id);
            onChange && onChange(newSelection);

            setMedicines(() => medicines.filter(x => x.id !== id));
            return getNotifier().success('El medicamento ha sido eliminado exitosamente');
        } catch (error) {
            return getNotifier().error(error ? error.detail : "Error desconocido: Hubo un problema al eliminar el medicamento");
        }
        ;
    };

    const getMedicineToEdit = medicine => {
        const dataEditModel = {
            id: medicine.id,
            name: medicine.name,
            type: medicine.type,
            medicineGroup: medicine.medicineGroup !== null ? medicine.medicineGroup : null
        }
        return enableEditMode(dataEditModel);
    }

    return (
        <div className="MedicineList">
            {
                medicines && medicines.length && medicines.map((medicine, i) => {
                    return (
                        <MedicineCard
                            key={i}
                            disableDots={disableDots}
                            selected={!!(selected && _.find(selected, s => s.id === medicine.id))}
                            medicineInfo={medicine}
                            onSelect={handleSelect}
                            patientPermissions={patientsCanAddMedicines}
                            doctorPermissions={doctorsCanManageMedicines}
                            getMedicineToEdit={getMedicineToEdit}
                            userInfo={me}
                            removeMedicine={removeMedicine}
                            withQuantity={withQuantity}
                            onQuantityChange={handleQuantityChange}
                        />
                    )
                })
            }
            {
                patientsCanAddMedicines &&
                <form onSubmit={addMedicine}>
                    <input
                        className="addElement--type__input"
                        type="text"
                        placeholder="Añade otro medicamento"
                        onChange={handleInputValue}
                        value={formValue}
                    />
                    {
                        formValue &&
                        <div className="addElement--type__controls">
                            <button className="addElement--type__button cancel" type="button"
                                    onClick={cancelForm}>Cancelar
                            </button>
                            <button className="addElement--type__button add" type="submit">Añadir</button>
                        </div>
                    }
                </form>
            }
        </div>
    )
};
export default MedicineList
