import React from 'react';
import './DotsButton.scss';
import classNames from 'classnames';

const DotsButton = ({className, ...props})=>{

    return (
        <button
            {...props}
            className={classNames('DotsButton', className)}>
            <span/><span/><span/>
        </button>
    );
};

export default DotsButton;
