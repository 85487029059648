
import React from 'react';
import MiscManagement from '../../components/MiscManagement/MiscManagement';
import { useSelector } from 'react-redux';
import DoctorProfileTopBar from "../../components/DoctorProfileTopBar/DoctorProfileTopBar";

const TriggersManagement = () => {
    const groups = ['trigger_read', 'blame_creation', 'user_read_id'];
    const me = useSelector(({ api }) => api.me);

    return (
        <article className="TriggersManagement">
            <DoctorProfileTopBar me={me} back={true} />
            <section>
                <MiscManagement
                    name="Detonante"
                    entity="triggers"
                    sGroups={groups}
                    addSearchBar={true}
                    formPlaceholder={['Escribe un nuevo detonante', 'Edita un detonante']}
                    searchBarPlaceholder="Buscar detonante"
                />
            </section>
        </article>
    );
}
export default TriggersManagement;