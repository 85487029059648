import './MiscManagement.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApiContext } from '../../../../services/api/api-config';
import Plus from '../../../../assets/img/icons/plus.svg';
import Edit from '../../../../assets/img/icons/edit.svg';
import Trash from '../../../../assets/img/icons/trash.svg';
import SearchBar from '../../../../components/Searchbar/SearchBar';

const MiscManagement = ({
    addFormComponent,
    addSearchBar,
    cleanValues,
    entity,
    endFormEventListener,
    name,
    noBlameCreator = false,
    formPlaceholder,
    letFormDataForMe = false,
    handleFormData,
    handleEditMode,
    searchBarPlaceholder,
    sGroups
}) => {
    const [errorState, setErrorState] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [updateId, setUpdateId] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [inputValue, setInputValue] = React.useState('');
    const [searchVal, setSearchVal] = React.useState('');
    const api = React.useContext(ApiContext);

    React.useEffect(() => {
        api[entity].get({
            params:
            {
                name: searchVal,
                sGroups: sGroups,
                pagination: false
            }
        });
    }, [api, entity, searchVal, sGroups]);

    const me = useSelector(({ api }) => api.me);
    const data = useSelector(({ api }) => api[entity]) || [];
    const loadingId = '@Management.loader';
    const loading = useSelector(({ loadingIds }) => !!loadingIds[loadingId]);

    const searchValues = values => setSearchVal(values);
    const handleInputValue = event => {
        const { value } = typeof event.target === 'string'? event.target.trim(): event.target;
        setInputValue(value);
    }
    const handleSubmitForm = (event, method, sGroups, data) => {
        event.preventDefault();
        const params = {
            sGroups: sGroups
        };

        const letData = () => handleFormData(method, sGroups, data);
        const sendData = () => {
            params.name = data;

            if (method === 'create') {
                api[entity].create({ params, loadingId })
                    .then(() => {
                        setInputValue('');
                    })
                    .catch(error => {
                        setErrorState(true);
                        setErrorMessage(error.detail);
                    });
            } else {
                api[entity].update({ id: updateId, params, loadingId })
                    .then(() => {
                        setEditMode(false)
                        setInputValue('');
                    }
                    )
                    .catch(error => {
                        setErrorState(true);
                        setErrorMessage(error.detail);
                    });
            }
            setErrorState(false);
        };


        if (method === 'create') {
            if (!data) {
                setErrorState(true);
                setErrorMessage(`No puedes crear un ${name} sin antes nombrarlo.`);
            } else {
                letFormDataForMe ? letData() : sendData();
                setErrorState(false)
            }
        } else {
            letFormDataForMe ? letData() : sendData();
        }
    };
    const editElement = element => {
        setEditMode(true);
        setUpdateId(element.id);
        setInputValue(element.name);
        letFormDataForMe && handleEditMode(element);
        endFormEventListener && endFormEventListener(true)
    }
    const removeElement = id => {
        api[entity].delete({ id: id }).catch(error => alert(error.detail));
    }
    const exitEditMode = () => {
        setEditMode(false);
        setInputValue('');
        endFormEventListener && endFormEventListener(false)
    }
    React.useEffect(() => {
        cleanValues && setInputValue('');
        cleanValues && setEditMode(false);
    }, [cleanValues])


    return (
        <div className="MiscManagement container">
            <div className="row">
                <div className="col-lg-6">
                    <form
                        className={` manageElements ${errorState ? 'animated shake' : ''} `}
                        onSubmit={
                            (event) => {
                                editMode
                                    ? handleSubmitForm(event, 'update', sGroups, inputValue)
                                    : handleSubmitForm(event, 'create', sGroups, inputValue)
                            }
                        }
                    >
                        <fieldset>
                            <label>
                                {name + ':'}
                                <input
                                    type="text"
                                    onChange={(event) => handleInputValue(event)}
                                    value={inputValue}
                                    placeholder={
                                        editMode
                                            ? formPlaceholder && formPlaceholder[1]
                                            : formPlaceholder && formPlaceholder[0]
                                    }
                                />
                            </label>
                            {addFormComponent && addFormComponent}
                            {errorState &&
                                <span className="alert alert-danger error" role="alert">
                                    {errorMessage}
                                </span>
                            }
                            {
                                editMode &&
                                <button
                                    type="button"
                                    className="exitEditMode"
                                    onClick={() => exitEditMode()}
                                >
                                    Cancelar
                                    </button>
                            }
                            <button
                                type="submit"
                                className="submit"
                            >
                                <img src={Plus} alt="Añadir elemento" />
                                {
                                    editMode
                                        ? loading ? 'Editando...' : `Editar ${name}`
                                        : loading ? 'Creando...' : `Crear ${name}`
                                }
                            </button>
                        </fieldset>
                    </form>
                </div>

                <div className="col-lg-6 data-cards">
                    <div>
                        {addSearchBar && <SearchBar placeHolder={searchBarPlaceholder} className="miscSearchBar" onInputChange={searchValues} mustDebounceValues={true} debounceTime={100} />}
                    </div>
                    {
                        data.map(element => {
                            return (
                                noBlameCreator || element.createdBy.id === me.id ?
                                    <div className="data-card" key={element.id}>
                                        <p>{element.name}</p>
                                        <button className="actions" onClick={() => {
                                            editElement(element)
                                        }}>
                                            <img src={Edit} alt="Editar elemento" />
                                        </button>
                                        <button className="actions" onClick={() => removeElement(element.id)}>
                                            <img src={Trash} alt="Eliminar elemento" />
                                        </button>
                                    </div>
                                    : null
                            );
                        })
                    }
                </div>
            </div>

        </div>
    );
};
export default MiscManagement;