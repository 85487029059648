export const insert = (array, index, item) => [...array.slice(0, index), item, ...array.slice(index)];

export const customIndexInsert = (array) => {
    let newArray = [];
    for( let item of array ){
        if(typeof item.index === "number"){
            const {index, ...rest} = item;
            newArray = [...newArray.slice(0, index), rest, ...newArray.slice(index)];
        }
        else
            newArray = [...newArray, item];
    };
    return newArray;
};