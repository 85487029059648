import React from 'react';
//Headers for DataTable Component
export const DataTableHeaders = () =>
    <>
        <th className="th-name">Listado de Doctores</th>
        <th className="th-contactName">Correo Electrónico</th>
        <th>Número de Licencias</th>
        <th>Comparte información</th>
        <th>Acciones</th>
    </>
/* --- SORTER CONFIG --- */
export const sorterConfig = {
    defaultValue: "name",
    options: [
        {value: 'name', title: 'Orden alfabético'},
        {value: 'licenses', title: 'Número de licencias'}
    ]
};