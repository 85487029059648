import moment from 'moment';
import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {initFormModel} from './form.config';
import ImageUploader from 'react-images-upload';
import GenderPicker from '../GenderPicker/GenderPicker';
import { validatePatientDataForServer } from './helpers';
import { getNotifier } from '../../../../services/notifierUtils';
import { ApiContext } from '../../../../services/api/api-config';
import FormCreator from '../../../../components/FormCreator/FormCreator';
import {customIndexInsert} from '../../../../utils/modelUtils/arrayModels/insert';
import { patientListSGroups as sGroups } from '../../../../utils/modelUtils/patientUtils';
import SimpleDateSelector from '../../../../components/SimpleDateSelector/SimpleDateSelector';

const PatientForm = ({patient, onSubmitFinished = () => {}, customProp}) => {
    //Initializers
    const api = useContext(ApiContext);
    const loadingId = "@FormPatient.Loader";

    //State
    const [error, setError] = useState();
    const [gender, setGender] = useState(false);
    const [date, setDate] = useState({year: 1970, month:1, day: 1});
    const [dataModel, setDataModel] = useState({});
    const [profileImage, setProfileImage] = useState(null);

    //Redux
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);

    //EditMode Check
    useEffect(() => {
        if(patient) {
            const {birthDate, gender, user, ...rest} = patient;
            setDataModel({...rest, ...user});
            setGender(() => (gender === "F") ? true : false);

            if(birthDate){
                setDate({
                    year: Number(moment.utc(birthDate).format('YYYY')),
                    month: Number(moment.utc(birthDate).format('M')),
                    day: Number(moment.utc(birthDate).format('D'))
                });
            }
        } else {
            setDate({year: 1960, month: 1, day:1});
        }
    }, [patient]);

    //Methods
    const handleFormData = async (data) => {
        const result = validatePatientDataForServer(data, setError, {date, gender, patient});

        if(result){
            const files = profileImage;
            const params = {...result, sGroups};
            const payload = {params, files, customProp, loadingId};

            if(patient && patient.id){
                payload.id = patient.id;
            };

            try {
                const response = await api.patients[(patient) ? "update" : "create"](payload);
                if(response){
                    getNotifier().success(`La cuenta se ha ${(patient) ? "editado" : "salvado"} correctamente`);
                    onSubmitFinished();
                };
            } catch (error) {
                getNotifier().error(error?.detail || `Hubo un problema al ${(patient) ? "editar" : "salvar"} la cuenta`);
                return;
            }
        }
    };

    //Form fields config:
    const formModelWithActions = [
        {
            index: 2,
            title: "Fecha de nacimiento",
            component:  <SimpleDateSelector date={date} onChange={setDate}/>
        },
        {
            index: 3,
            title: "Género:",
            component: <GenderPicker defaultValue={gender} setValue={setGender}/>
        },
        {
            title: 'Foto de perfil',
            component: <ImageUploader
                singleImage
                withPreview
                withIcon={true}
                onChange={setProfileImage}
                buttonText='Seleccionar imagen'
                imgExtension={[".jpg", ".png"]}
                maxFileSize={5242880}
            />

        }
    ];
    const formModel =
        customIndexInsert([...initFormModel, ...formModelWithActions])
        .map( model =>
            (model.type === 'password')
            ? (patient)
                ? {...model, placeholder: "Puedes dejar este campo vacío para conservar tu contraseña actual."}
                : model
            : model
    );

    return (
        <FormCreator
            dataModel={dataModel}
            formModel={formModel}
            handleFormData={handleFormData}
        >
            {(error) && <div className="alert alert-danger animated fadeIn" role="alert">{error}</div>}
            <button type="submit" onClick={handleFormData}>
                {
                    (loading)
                    ? (!patient) ? "Creando..." : "Editando..."
                    : (!patient) ? "Crear paciente" : "Editar paciente"
                }
            </button>
        </FormCreator>
    );
};
export default PatientForm;

PatientForm.propTypes = {
    patient: PropTypes.object,
    onSubmitFinished: PropTypes.func.isRequired,
};