import React from 'react';
import './WelcomeMessage.scss';
import moment from 'moment';
import PainFace from "../../../../components/PainFace/PainFace";

const WelcomeMessage = ({patient}) => {



    const getDaysSinceLastRecord = (patient) => {
            if (!patient.lastMigraineRecord)
                return null;
            return moment().diff(moment(patient.lastMigraineRecord.fromDate), 'days')
        }
    ;

    const getMessage = (patient) => {
        if (!patient.lastMigraineRecord) {
            return (
                <>
                    Registrar tus episodios ayuda a obtener mejores resultados
                </>
            );
        }

        const daysSinceLastRecord = getDaysSinceLastRecord(patient);

        return (
            daysSinceLastRecord > 5 ?
                <>
                    <strong>¡Felicidades,</strong> ha sido una buena semana!
                </> : <>
                    <strong>Continua</strong> registrando tus episodios para obtener mejores resultados
                </>
        )
    };

    return (
        <div className='WelcomeMessage'>

            {!patient?<h3>User should have a patient to look at this scene</h3>:<div>
                <br/>
                {getDaysSinceLastRecord(patient) ?
                    <div className='face-container'>
                        <PainFace intensity={2}/>
                    </div> : null}
                <p className='home-message'>{getMessage(patient)}</p>
                {getDaysSinceLastRecord(patient)!==null?
                    <div className='day-streak'>
                        <div className='streak-count-lightRed'>
                            <div className='streak-count'>
                                <span className='number'>{getDaysSinceLastRecord(patient)}</span>
                            </div>
                        </div>
                        <p className='streak-message'>
                            <span className='light'>Días</span> sin episodios
                        </p>
                    </div>:null}
            </div>}

        </div>

    );

};

export default WelcomeMessage;
