import React, {useState, useCallback, useContext} from 'react';
import LogoWhite from "../../assets/img/logo.png";
import './ForgotPassword.scss';
import {ApiContext} from '../../services/api/api-config';
import {getNotifier} from "../../services/notifierUtils";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {

    const [email, setEmail] = useState('');

    const api = useContext(ApiContext);
    const history = useHistory();

    const onEmailChange = useCallback((e) => {
        const email = e.target.value;
        setEmail(email);
    }, [setEmail]);

    const onSubmit = useCallback(()=>{
        if(!email) {
            getNotifier().error('Debes escribir tu correo electrónico');
            return;
        }

        api.users.forgotPassword({email}).then(()=>{
            getNotifier().success('Consulta tu correo para reestablcer la contraseña');
            history.push('/login');
        }).catch((e)=>{
            getNotifier().error(e.data.message);
        });
    },[email, api, history]);

    return (
        <div className='ResetPassword'>
            <div className='login-form'>
                <div className="logo-wrapper">
                    <img src={LogoWhite} alt="Amcemig logo" className="desktop-logo"/>
                    <h5 className="logo-title">Diario de <span>Migraña</span></h5>
                </div>
                <input value={email} onChange={onEmailChange} className='form-control Input' type='email'
                       placeholder='Escribe aquí tu correo electrónico'/>
                <button onClick={onSubmit} className='Button' disabled={false}>{'Recuperar contraseña'}</button>
            </div>
        </div>
    );
};

export default ForgotPassword;