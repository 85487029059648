import MainScreen from "../../scenes/Patient/MainScreen/MainScreen";
import NewEpisode from "../../scenes/Patient/NewEpisode/NewEpisode";
import Recommendations from "../../scenes/Patient/Recommendations/Recommendations";
import EpisodeList from "../../scenes/Patient/EpisodeList/EpisodeList";
import Medicine from "../../scenes/Patient/Medicine/Medicine";
import ReportsScreen from "../../scenes/Patient/ReportsScreen/ReportsScreen";
import AssociationHome from "../../scenes/Association/SponsorList/SponsorList";
import DoctorsHome from "../../scenes/Doctors/DoctorsHome";
import Laboratories from "../../scenes/Laboratories/DoctorsList/DoctorsList";
import PatientProfile from "../../scenes/Doctors/PatientProfile/PatientProfile";
import MedicineListPage from "../../scenes/Doctors/MedicineListPage/MedicineListPage";
import MedicineGroupsPage from '../../scenes/Doctors/MedicineGroupsPage/MedicineGroupsPage';
import TriggersManagement from "../../scenes/Doctors/ManagementPages/TriggersManagement/TriggersManagement";
import SymptomsManagement from "../../scenes/Doctors/ManagementPages/SymptomsManagement/SymptomsManagement";
import PainCharacteristicsManagement from '../../scenes/Doctors/ManagementPages/PainCharacteristicsManagement/PainCharacteristicsManagement';
import DiagnosticsManagement from '../../scenes/Doctors/ManagementPages/DiagnosticsManagement/DiagnosticsManagement';

export const paths = {
    mainScreen: '/',
    newEpisode: '/add',
    recommendations: '/recommendations',
    associationHome: '/association',
    doctorsHome: '/doctors',
    medicineListPage: '/medicine-list',
    medicineGroupsPage: '/medicine-groups',
    triggersManagement: '/triggers-management',
    symptomsManagement: '/symptoms-management',
    diagnosticsManagement: '/diagnostic-management',
    painCharacteristicsManagement: '/pain-management',
    patientProfile: '/doctors/patient/:id',
    laboratoriesHome: '/laboratories',
    editEpisode: '/edit/:id',
    episodeList: '/episodes',
    medicine: '/medicine',
    reports: '/reports'
};

const getAppRoutes = (securityManager) => {


    switch (securityManager.role) {
        //	laboratorio@amcemig.com amcemig
        case 'ASSOCIATION':
            return [
                { path: paths.associationHome, component: AssociationHome }
            ];
        case 'SPONSOR':
            return [
                { path: paths.laboratoriesHome, component: Laboratories }
            ];
        case 'DOCTOR':
            return [
                { path: paths.doctorsHome, component: DoctorsHome },
                { path: paths.reports, component: ReportsScreen },
                { path: paths.patientProfile, component: PatientProfile },
                { path: paths.medicineListPage, component: MedicineListPage },
                { path: paths.triggersManagement, component: TriggersManagement },
                { path: paths.symptomsManagement, component: SymptomsManagement },
                { path: paths.medicineGroupsPage, component: MedicineGroupsPage },
                { path: paths.diagnosticsManagement, component: DiagnosticsManagement },
                { path: paths.painCharacteristicsManagement, component: PainCharacteristicsManagement }
            ];
        default:
            return [
                { path: paths.mainScreen, component: MainScreen },
                { path: paths.medicine, component: Medicine },
                { path: paths.reports, component: ReportsScreen },
                { path: paths.newEpisode, component: NewEpisode },
                { path: paths.editEpisode, component: NewEpisode },
                { path: paths.episodeList, component: EpisodeList },
                { path: paths.recommendations, component: Recommendations }
            ];

    }
};
export default getAppRoutes;