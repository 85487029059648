import React, {useEffect, useMemo} from 'react';
import {setNotifier} from "./services/notifierUtils";
import {ToastContainer} from "react-toastr";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'rc-time-picker/assets/index.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Api from 'tide-api';
import apiConfig from './services/api/api-config'
import {ApiContext} from "./services/api/api-config";
import {LOGIN_STATE} from "tide-api";
import store from "./services/redux/store";
import {useSelector} from "react-redux";
import Splash from "./components/Splash/Splash";
import SecurityManager from "./services/SecurityManager";
import getAppRoutes from "./services/routes/appRoutes";
import notLoggedRoutes from "./services/routes/notLoggedRoutes";
import 'moment/locale/es';
import useBoolean from "./hooks/useBoolean";
import './assets/fonts/fonts.scss';
import './assets/styles/App.scss';
import './assets/styles/notifications.scss';
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const api = new Api({...apiConfig, reduxStore: store});
window.logout = api.logout;

function App() {

    const loggedIn = useSelector(({api}) => api.loggedIn === LOGIN_STATE.LOGGED_IN);
    const loading = useSelector(({loadingIds}) => !!loadingIds['Initializing.me']);
    const me = useSelector(({api}) => api.me);

    //To prevent a flicker on start up, we force the splash screen to be visible for a second
    const [forceSplash, , stopSplash] = useBoolean(true);
    useEffect(() => {
        setTimeout(stopSplash, 1000);
    }, [stopSplash]);

    useEffect(() => {
        if (!me && !loading && loggedIn)
            api.me.get({loadingId: 'Initializing.me'}).catch(() => api.logout());
    }, [me, loading, loggedIn]);


    const securityManager = useMemo(() => me ? new SecurityManager(me) : null, [me]);

    const routes = loggedIn && me ?
        getAppRoutes(securityManager)
        : notLoggedRoutes;

    const splash = forceSplash || loading || (loggedIn && !me);


    return (
        <div className="App">
            <ApiContext.Provider value={api}>
                <ErrorBoundary>
                    <ToastContainer
                        ref={setNotifier}
                        preventDuplicates
                    />
                    <Router>
                        {splash ?
                            <Splash/>
                            :
                            <Switch>
                                {routes.map(route =>
                                    <Route key={route.path} path={route.path} component={route.component}
                                           exact={route.exact !== false}/>
                                )}
                                <Redirect from='/' to={routes[0].path}/>
                            </Switch>
                        }
                    </Router>
                </ErrorBoundary>
            </ApiContext.Provider>
        </div>
    );
}

export default App;
