import React, {useState, useEffect, useCallback} from 'react';
import './DateCarousel.scss';
import moment from "moment";
import classNames from 'classnames';
//import useCallbackCreator from "use-callback-creator";
import {Carousel} from 'react-responsive-carousel';

const DateCarousel = ({value, onChange})=>{

    const [slideIndex, setSlideIndex]=useState(0);
    const [weeks, setWeeks]=useState(()=>createWeeksArray(value, 0));

    useEffect(() => {
        setWeeks( () => createWeeksArray(value, slideIndex));
    }, [value, slideIndex]);


    const handleSelect = date => {
        const newVal=moment(date);
        if(value)
            newVal.hour(value.hour()).minute(value.minute());
        onChange && onChange(newVal);
        setWeeks( () => createWeeksArray(newVal, slideIndex) );
    };

    const handleMove=useCallback((index)=>{
        const newDate=weeks[index][0];
        onChange(newDate);
        setWeeks( () => createWeeksArray(newDate, index) );
        setSlideIndex(index);
    },[weeks, onChange]);

    return (
        <div className='DateCarousel'>

            <Carousel
                emulateTouch
                infiniteLoop
                showThumbs={false}
                showStatus={false}
                showArrows={true}
                showIndicators={false}
                selectedItem={slideIndex}
                onChange={handleMove}>

                {weeks.map((week, index)=>
                    <div className='week' key={index}>
                        {week.map((day)=>
                            <button
                                key={day.format('MM-DD')}
                                className={classNames('date-btn', day.isSame(value, 'day') && 'active')}
                                onClick={() => handleSelect(day.format('YYYY-MM-DD'))}>
                                <div className='week-day'>{day.format('dd').substring(0,1).toUpperCase()}</div>
                                <div className='date'>{day.format('D')}</div>
                            </button>
                        )}
                    </div>
                )}
            </Carousel>
        </div>
    );
};
export default DateCarousel;

const createWeeksArray=(selectedDate, slideIndex)=>{
    const weeks = [];
    weeks[slideIndex]=getWeekDays(moment(selectedDate));
    weeks[(slideIndex+1)%3]=getWeekDays(moment(selectedDate).add(1,'week'));
    weeks[(slideIndex+2)%3]=getWeekDays(moment(selectedDate).subtract(1,'week'));
    return weeks;
};

const getWeekDays=(date)=>{
    const start=moment(date).startOf('week').hour(date.hour()).minute(date.minute());
    const days=[];
    for(let i=0; i<7; i++)
        days.push(moment(start).add(i,'days'));
    return days;
};