import 'promise-polyfill/src/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from "./services/redux/store";
import {Provider} from "react-redux";
import "core-js/stable";
import "regenerator-runtime/runtime";
import assign from 'es6-object-assign';
if (!Object.assign)
    assign.polyfill();

ReactDOM.render(
    <Provider store={store} >
        <App />
    </Provider>
    , document.getElementById('root'));

serviceWorker.register();
