import React, {useEffect, useState} from 'react';
import './TopBar.scss';
import {Link, useHistory} from 'react-router-dom';
import {ReactComponent as BackBtn} from "../../assets/img/icons/Back-icon.svg";
import {useSelector} from "react-redux";

const TopBar = ({backButton, isInDesktop = false, children, childrenClassName="", topBarClassName=""}) => {
    const history = useHistory();
    const [sponsor, setSponsor] = useState(null);
    const me = useSelector(({api}) => api.me);

    useEffect(()=>{
       if(me.patient)
           setSponsor(me.patient.doctor.sponsor);
       if(me.doctor)
           setSponsor(me.doctor.sponsor);
    }, [me, setSponsor]);
    return (

        !isInDesktop
        ? (
            <div className={`TopBar green ${topBarClassName}`}
                    style={{background: (sponsor && sponsor.appConfig && sponsor.appConfig.color) ? sponsor.appConfig.color.hex : null}}>
                
                {backButton &&
                <button className='backBtn'>
                    <BackBtn onClick={history.goBack}/>
                </button>}

                <div className={`${childrenClassName} align-items-center`}>
                    <Link to={'/'}>
                        <h3 className="appTitleThin align-self-center">Diario de <span className="appTitle">Migraña</span></h3>
                    </Link>
                    {children}
                </div>
            </div>
        )
        : (
            <div className={`TopBar ${topBarClassName}`}
                 style={{background: (sponsor && sponsor.appConfig && sponsor.appConfig.color) ? sponsor.appConfig.color.hex : null}}
            >
                <div className={`${childrenClassName} d-flex align-items-center`}>
                    <Link to={'/'}><h3 className="appTitleThin align-self-center">Diario de <span
                        className="appTitle">Migraña</span></h3></Link>
                    {children}
                </div>
            </div>
        )
    )
};

export default TopBar;
