import './Modal.scss';
import Exit from '../../assets/img/icons/exit.svg';
import React from 'react';

const Modal = ({ children, title, description, onClose, classNames = "" }) => {
    return (
        <div className={`Modal ${classNames}`}>
            <div onClick={onClose} className="exit-zone"></div>
            <article className="modal-container animated fadeIn">
                <header className="modal-header">
                    <h3>{title}</h3>
                    <img src={Exit} alt="Cerrar modal" onClick={onClose} />
                </header>
                <section>
                    {description}
                    {children}
                </section>
            </article>
        </div>
    );
};
export default Modal;