import React from 'react';
import join from 'url-join';
import semver from 'semver-lite';
import {version} from '../../pckg';
import qs from 'qs';
const querySerialize = (obj) => qs.stringify(obj, { arrayFormat: 'brackets' });

const config = {
    host: 'https://calendario-back.amcemig.com',
    commonPath: 'api',
    credentials: 'include',
    saveTokenToLocalStorage: true,
    getDataFromResponse: handleResponse,
    getMetaDataFromResponse: (r) => r.meta,
    endpoints: [
        'me',
        'triggers',
        'symptoms',
        'medicines',
        'diagnostics',
        'medicine_groups',
        'triggers',
        'sponsors',
        'doctors',
        'logout',
        'pain_characteristics',
        {
            name: 'appFiles',
            preventDefaultMethods: true,
            customMethods: {
                getFileUrl: function (appFile) {
                    return join(this.host, 'file', appFile.id + '')
                }
            }
        },
        {
            name: 'error',
            preventDefaultMethods: true,
            customMethods: {
                send: function( error, stack, user, ...additionalInfo ){
                    let params = { stack,
                        error: error && error.message,
                        user: user && {id: user.id, username: user.username},
                        ...additionalInfo };
                    return this.apiCall( '/frontend_error', "error_sent", params, { method: "POST", queueable: true, useCommonPath: false }  )
                }
            }
        },
        {
            name: 'migraineRecords',
            customMethods: {
                triggersReport: function ({customProp = 'triggersReport'} = {}) {
                    return this.apiCall('/migraine_records/triggers_report', customProp);
                },
                monthlyReport: function ({customProp = 'migraineRecordsMonthlyReport'} = {}) {
                    return this.apiCall('/migraine_records/monthly_report', customProp)
                },
                generalStatics: function (id, fromDate, toDate, props) {
                    return this.apiCall(`/migraine_records/general_statistics?patient=${id}&fromDate=${fromDate}&toDate=${toDate}`, 'generalStatics', {...props})
                },exportUrl: function( {patient } )
                {
                    const query = querySerialize({ token: window.localStorage.tideApiToken});
                    return join(this.host, 'api/migraine_records/export_to_excel/'+patient.id+'?'+query);
                }
            }
        },
        {
            name: 'users',
            customMethods: {
                sendWelcome: function ({userId, customProp}) {
                    return this.apiCall(`/users/send_welcome/${userId}`, customProp, {}, {method: 'PUT'})
                },
                resetPassword: function ({token, newPassword, customProp}) {
                    return this.apiCall(`/reset_password`, customProp, {token, password: newPassword}, {
                        method: 'POST', useCommonPath: false
                    })
                },
                forgotPassword: function ({email, customProp}) {
                    return this.apiCall('/recover_password_request', customProp, {email}, {
                        method: 'POST', useCommonPath: false
                    })
                },
                toggleDataSharingPermissions: function ({customProp, patient}){
                    return this.apiCall('/users/toggle_data_sharing', customProp, {patient}, {method: 'POST'})
                },
                toggleShowLogoToPatients: function ({customProp}){
                    return this.apiCall('/users/toggle_show_logo_to_patients', customProp, {}, {method: 'POST'})
                },
                toggleShowLogoToDoctors: function ({customProp}){
                    return this.apiCall('/users/toggle_show_logo_to_doctors', customProp, {}, {method: 'POST'})
                }
            }
        },
        {
            name: "patients",
            customMethods: {
                getMedicines: function({id, params, customProp}) {
                    return this.apiCall(`patients/${id}/medicines`, customProp, params, {method: 'GET'})
                }
            }
        }
    ],
};

function handleResponse(response, headers) {

    try {
        let server = headers.get("X-App-Version");
        let force = !!server && server[server.length - 1] === 'f';
        if (force) server = server.slice(0, server.length - 1);

        if (semver.validate(version) && semver.validate(version) && semver.gt(server, version)) {
            if (!this.newerVersion)
                console.log("Newer version detected " + server);
            if (!this.newerVersion && window.swRegistration) {
                window.swRegistration.update()
                    .then(() => {
                        //This will broadcast the refresh message to all active tabs of the app through the sw
                        if (force)
                            window.navigator.serviceWorker.controller.postMessage('force_refresh');
                    });
            }
            this.newerVersion = version;
            if (force && !window.swRegistration)
                window.location.reload();
        }
    } catch (e) {
        console.error('Error reading versions: ' + e);
    }

    return response.data;
}

if (process.env.REACT_APP_BUILD === 'dev')
    config.host = localStorage.mighost || 'https://migraine-back.tide.company/';

export default config;
export const ApiContext = React.createContext(null);
