import React, {useState, useCallback, useContext, useEffect} from 'react';
import LogoWhite from "../../assets/img/logo.png";
import './ResetPassword.scss';
import {ApiContext} from '../../services/api/api-config';
import {getNotifier} from "../../services/notifierUtils";
import queryString from 'query-string';
import { useHistory } from "react-router-dom";

const ResetPassword = () => {

    const [password, setPassword] = useState('');
    const [hash, setHash] = useState(null);

    const api = useContext(ApiContext);
    const history = useHistory();

    useEffect(()=>{
        setHash(queryString.parse( window.location.search ).h);
    }, [setHash])

    const onPasswordChange = useCallback((e) => {
        const password = e.target.value;
        setPassword(password);
    }, [setPassword]);

    const onSubmit = useCallback(()=>{
        if(!password || !hash) {
            getNotifier().error('Debes escribir la contraseña');
            return;
        }

        api.users.resetPassword({token: hash, newPassword: password}).then(()=>{
           getNotifier().success('Contraseña establecida');
           history.push('/login');
        }).catch((e)=>{
           getNotifier().error(e.data.message);
        });
    },[password, api, hash, history]);

    return (
        <div className='ResetPassword'>
            <div className='login-form'>
                <div className="logo-wrapper">
                    <img src={LogoWhite} alt="Amcemig logo" className="desktop-logo"/>
                    <h5 className="logo-title">Diario de <span>Migraña</span></h5>
                </div>
                <input value={password} onChange={onPasswordChange} className='form-control Input' type='password'
                       placeholder='Escribe aquí tu contraseña'/>
                <button onClick={onSubmit} className='Button' disabled={false}>{'Establecer contraseña'}</button>
            </div>
        </div>
    );
};

export default ResetPassword;