import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import './SimpleDateSelector.scss';
import moment from 'moment';
import prependZero from "../../utils/prependZero";

const SimpleDateSelector = ({date, onChange}) => {

    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);


    useEffect(()=>{
      setYears(_.range(moment().format('YYYY'), 1900));

      setMonths([
          {
              label: 'Enero',
              value: 1,
          },
          {
              label: 'Febrero',
              value: 2,
          },
          {
              label: 'Marzo',
              value: 3,
          },
          {
              label: 'Abril',
              value: 4,
          },
          {
              label: 'Mayo',
              value: 5,
          },
          {
              label: 'Junio',
              value: 6,
          },
          {
              label: 'Julio',
              value: 7,
          },
          {
              label: 'Agosto',
              value: 8,
          },
          {
              label: 'Septiembre',
              value: 9,
          },
          {
              label: 'Octubre',
              value: 10,
          },
          {
              label: 'Noviembre',
              value: 11,
          },
          {
              label: 'Diciembre',
              value: 12,
          }
      ]);
    },[setYears, setMonths]);

    useEffect(()=>{
        let lastDay = 31;
        if(date.year && date.month){
             lastDay = moment(`${date.year}-${prependZero(date.month)}-01`).endOf('month').format('D');
        }
        setDays(_.range(1, parseInt(lastDay)+1));

    }, [date,setDays])

    const onMonthChange = (e)=>{
        const month = e.currentTarget.value;
        onChange({...date, month});
    };

    const onYearChange = (e)=>{
        const year = e.currentTarget.value;
        onChange({...date, year});
    }

    const onDayChange = (e)=>{
        const day = e.currentTarget.value;
        onChange({...date, day});
    }

    return (
        <div className='DateSelector'>

            <select onChange={onYearChange} name="year" placeholder={"Año"} value={date.year||""}>
                <option disabled selected >Año</option>
                {years.map((year)=>(
                    <option key={year} value={year} >{year}</option>
                ))}
            </select>

            <select onChange={onMonthChange} name="month" placeholder={"Mes"} value={date.month||""}>
                <option disabled selected>Mes</option>
                {months.map((month)=>(
                    <option key={month.value} value={month.value}>{month.label}</option>
                ))}
            </select>

            <select name="day" onChange={onDayChange} placeholder="Día" value={date.day||""}>
                <option disabled selected>Día</option>
                {days.map((day)=>(
                    <option  key={day} value={day}>{day}</option>
                ))}
            </select>
        </div>
    );
};

export default SimpleDateSelector;
