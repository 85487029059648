import {cleanDuplicatedObjects} from '../../../../utils/modelUtils/arrayModels/cleanDuplicatedObjects';
import _ from "lodash";

export const getOptions = (array, key) => {
    return _.orderBy(cleanDuplicatedObjects(
        array
            .reduce((a, o) => a.concat(o[key]), [])
            .map(item => ({label: item.name.trim(), value: item.id})
            ),
        "value"
    ), 'label', 'asc');
}

export const filterPatientsBy = (patients, key, dictionary) =>
    patients
    .filter( (patient) => patient[key].some( ({id}) => dictionary.includes(id)) );