import './PatientProfile.scss';
import _ from 'lodash';
import moment from "moment";
import React, {useContext, useEffect, useMemo, useState} from 'react';
import ReactEcharts from "echarts-for-react";
import DoctorProfileTopBar from '../components/DoctorProfileTopBar/DoctorProfileTopBar';
import PainLocationPicker from "../../../components/PainLocationPicker/PainLocationPicker";
import MigraineRecordCalendar from "./components/MigraineRecordCalendar/MigraineRecordCalendar";
import {useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import {ApiContext} from "../../../services/api/api-config";
import {processBarChart} from "./intensityAndDurationChart";
import DatePicker from 'react-date-picker';
import Plus from '../../../assets/img/icons/plus.svg';
import Modal from '../../../components/Modal/Modal';
import {patientListSGroups} from '../../../utils/modelUtils/patientUtils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faPlus} from '@fortawesome/free-solid-svg-icons';
import UserCircle from '../../../assets/img/icons/user-circle.svg';

const patientCustomProp = "@PatientProfile.Store";
const PatientProfile = () => {

    //Loading ID
    const loadingId = '@PatientProfile.Actions';
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);

    //STATES
    const dateFormat = date => date.toDate();
    const [initPeriod, setInitPeriod] = useState(dateFormat(moment().startOf('month')));
    const [endPeriod, setEndPeriod] = useState(dateFormat(moment().endOf('month')));
    const [topListOfPainLocations, setTopListOfPainLocations] = useState([]);

    //API CALLS
    const api = useContext(ApiContext);
    const {id} = useParams();

    useEffect(() => {
        api.migraineRecords.get(
            {
                id,
                params: {
                    "fromDate[after]": moment(initPeriod).utcOffset(0, true).format(),
                    "fromDate[before]": moment(endPeriod).utcOffset(0, true).format(),
                    patient: id,
                    pagination: false
                }
            }
        );
        api.medicines.get({params:{pagination:false}});
        api.diagnostics.get({params:{pagination:false}});
        api.patients.get({params: {id: parseInt(id), sGroups: patientListSGroups}, customProp: patientCustomProp});
        api.migraineRecords.generalStatics(id, moment(initPeriod).utcOffset(0, true).format(), moment(endPeriod).utcOffset(0, true).format());
    }, [api, id, initPeriod, endPeriod]);
    //API CALL DATA
    const me = useSelector(({api}) => api.me);
    const medicines = useSelector(({api}) => api.medicines) || [];
    const [patient] = useSelector(({api}) => api[patientCustomProp]) || [];
    const generalStatics = useSelector(({api}) => api.generalStatics) || {};
    const migraineRecords = useSelector(({api}) => api.migraineRecords) || [];
    const diagnostics = useSelector(({api}) => api.diagnostics) || [];
    // --- GRAPHICS CONFIG (38:193) ---
    const getOptionsTopTriggers = (data) => ({

        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} :({d}%)'
        },
        legend: {
            top: '50px',
            type: 'scroll',
            orient: 'horizontal',
            left: 'left',
            data: data ? _.map(data, data.name) : ''
        },
        series: [
            {
                name: 'Detonante',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    });
    const getOptionsTopSymptoms = (data) => ({

        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : ({d}%)'
        },
        legend: {
            top: '50px',
            type: 'scroll',
            orient: 'horizontal',
            left: 'left',
            data: data ? _.map(data, data.name) : ''
        },
        series: [
            {
                name: 'Síntoma',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    });
    const getOptionsTopMedicines = (data) => ({
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : ({d}%)'
        },
        legend: {
            top: '50px',
            type: 'scroll',
            orient: 'horizontal',
            left: 'left',
            data: data ? _.map(data, (med)=>(med.name+' ('+med.count+')')) : ''
        },
        series: [
            {
                name: 'Medicamento',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                data: data,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    });
    const getOptionsTopPainCharacteristics = (data) => ({

        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : ({d}%)'
        },
        legend: {
            top: '50px',
            type: 'scroll',
            orient: 'horizontal',
            left: 'left',
            data: data ? _.map(data, data.name) : ''
        },
        series: [
            {
                name: 'Característica',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                data: data ? data : 'No hay información',
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    });


    //Get statics data
    const topData = (topDataName) => {
        if (generalStatics.topTriggers || generalStatics.topSymptoms || generalStatics.topMedicines || generalStatics.topPainCharacteristics) {
            switch (topDataName) {
                case 'trigger':
                    return generalStatics.topTriggers.map((data) => {
                        return {value: data.count, name: data.triggerName+' ('+data.count+')'}
                    });
                case 'symptoms':
                    return generalStatics.topSymptoms.map((data) => {
                        return {value: data.count, name: data.symptomName+' ('+data.count+')'}
                    });
                case 'preventiveMedicine':
                    return generalStatics.topPreventiveMedicines.map((data) => {
                        return {value: data.count, name: data.medicineName+' ('+data.count+')'}
                    });
                case 'abortiveMedicine':
                    return generalStatics.topAbortiveMedicines.map((data) => {
                        return {value: data.count, name: data.medicineName+' ('+data.count+')'}
                    });
                case 'topPainCharacteristics':
                    return generalStatics.topPainCharacteristics.map(data => {
                        return {value: data.count, name: data.painCharacteristicName+' ('+data.count+')'}
                    });
                default:
                    break;
            }
        }
    };
    //Data config to ReactEcharts
    const topTriggerConfig = getOptionsTopTriggers(topData('trigger'));
    const topSymptomConfig = getOptionsTopSymptoms(topData('symptoms'));
    //const topPreventiveMedicineConfig = getOptionsTopMedicines(topData('preventiveMedicine'));
    const topAbortiveMedicineConfig = getOptionsTopMedicines(topData('abortiveMedicine'));
    const topPainCharacteristicConfig = getOptionsTopPainCharacteristics(topData('topPainCharacteristics'))


    //React Echarts: Data objects for Chart intensity and duration.
    const chartIntensity = migraineRecords.length !== 0 ?
        processBarChart(initPeriod, endPeriod, "intensityAverage", generalStatics.statsInTime, 'Intensidad total del día') : {};

    const chartDuration = migraineRecords.length !== 0 ?
        processBarChart(initPeriod, endPeriod, 'durationMinutesAverage', generalStatics.statsInTime, 'Duración') : {};


    //Direct render values
    const topTriggers = useMemo(() => {
        if (generalStatics.topTriggers) {
            return _.head(generalStatics.topTriggers) ? _.head(generalStatics.topTriggers).triggerName : 'Sin información'
        }
    }, [generalStatics]);
    const topSymptoms = useMemo(() => {
        if (generalStatics.topSymptoms) {
            return _.head(generalStatics.topSymptoms) ? _.head(generalStatics.topSymptoms).symptomName : 'Sin información'
        }
    }, [generalStatics]);
    const topPainCharacteristic = useMemo(() => {
        if (generalStatics.topPainCharacteristics) {
            return _.head(generalStatics.topPainCharacteristics) ? _.head(generalStatics.topPainCharacteristics).painCharacteristicName : 'Sin información'
        }
    }, [generalStatics]);

    //Calendar functions
    const handleInitDate = value =>
        setInitPeriod(value);
    const handleEndDate = value =>
        setEndPeriod(value);

    //Top locations data for location picker
    useEffect(() => {
        if (typeof generalStatics !== 'undefined') {
            setTopListOfPainLocations(generalStatics.topPainLocations);
        }
    }, [generalStatics]);
    //COMPONENT: Patient Diagnostic
    const [diagnosticSelected, setDiagnosticSelected] = useState([]);
    const [checking, setChecking] = useState(false);

    useEffect(() => {
        const diagnostics = patient !== undefined && patient.diagnostics.map(diagnostic => diagnostic);
        setDiagnosticSelected(diagnostics);
    }, [patient])

    const PatientDiagnosticSelector = () => {

        const selectDiagnostic = diagnostic => {
            setChecking(true);
            setDiagnosticSelected([...diagnosticSelected, diagnostic]);
        };

        const unselectDiagnostic = diagnosticID => {
            setChecking(true);
            const index = diagnosticSelected.map(diagnostic => diagnostic.id).indexOf(diagnosticID);
            const newArr = diagnosticSelected.splice(index, 1);
            setMedicineStuff(diagnosticSelected.filter(index => index !== newArr));
        };

        const saveDiagnosticsSelected = diagnostics => {
            const diagnosticsIds = diagnostics.map(diagnostic => diagnostic.id);
            api.patients.update(
                {
                    id: id,
                    params: {
                        sGroups: patientListSGroups,
                        diagnostics: diagnosticsIds
                    },
                    loadingId
                }
            ).then(() => setChecking(false));
        }

        return (
            <div className="patient-diagnostic-section">
                <ul className="patient-diagnostic-container">
                    {
                        diagnostics.map(diagnostic =>
                            <li key={diagnostic.id}>
                                {diagnostic.name}
                                {
                                    diagnosticSelected && _.find(diagnosticSelected, selected => selected.id === diagnostic.id)
                                        ? <button onClick={() => unselectDiagnostic(diagnostic.id)}>
                                            <FontAwesomeIcon icon={faCheck}/>
                                        </button>
                                        : <button onClick={() => selectDiagnostic(diagnostic)}>
                                            <FontAwesomeIcon icon={faPlus}/>
                                        </button>
                                }
                            </li>
                        )
                    }
                </ul>
                {
                    checking &&
                    <button className="saveData" onClick={() => saveDiagnosticsSelected(diagnosticSelected)}>
                        {loading ? 'Guardando...' : 'Guardar Cambios'}
                    </button>
                }
            </div>
        );
    }


    //Patient Management: Functions.
    //Modal State
    const [modalReason, setModalReason] = useState('');
    const [modalState, setModalState] = useState(false);
    const [modalReasonTitle, setModalReasonTitle] = useState('');
    const [modalReasonDescription, setModalReasonDescription] = useState('');

    //Medicine assignment states:
    const [medicineStuff, setMedicineStuff] = useState([]);
    const [selecting, setSelecting] = useState(false);

    //Use Effect for Medicine assignment:
    useEffect(() => {
        const patientMedicines = typeof patient !== 'undefined' && patient.medicines.map(medicines => medicines);
        setMedicineStuff(patientMedicines);
    }, [patient]);

    const selectMedicine = medicine => {
        setSelecting(true);
        setMedicineStuff([...medicineStuff, medicine]);
    }

    const removeAssignedMedicine = id => {
        setSelecting(true);
        const index = medicineStuff.map(medicine => medicine.id).indexOf(id);
        const newArr = medicineStuff.splice(index, 1);
        setMedicineStuff(medicineStuff.filter(index => index !== newArr));
    };

    const updateMedicineAssignment = () => {
        const medicineStuffIds = medicineStuff.map(medicine => medicine.id);
        api.patients.update({
            id: id,
            params: {sGroups: patientListSGroups, medicines: medicineStuffIds},
            loadingId
        }).then(() => {
            setModalState(false);
            setSelecting(false);
        });
    };

    //MIDAS & HIT6
    const [formState, setFormState] = useState({midas: '', hit6: ''});
    const [changing, setChanging] = useState(false);

    useEffect(() => {
        patient && setFormState({
            midas: patient.midas === null ? 0 : patient.midas,
            hit6: patient.hit6 === null ? 0 : patient.hit6
        })
    }, [patient]);
    const handleInputValue = event => {
        const {name, value} = event.target;
        setFormState({...formState, [name]: value});
        setChanging(true);
    }
    const handleMidasAndHit6Data = data => event => {
        event.preventDefault();
        api.patients.update(
            {
                id: parseInt(id),
                params: {
                    sGroups: patientListSGroups,
                    midas: parseFloat(data.midas),
                    hit6: parseFloat(data.hit6)
                },
                loadingId
            }).then(() => {
            api.patients.get({params: {id: id, sGroups: patientListSGroups}});
            setModalState(false);
        });
    };

    const NoInfoAlert = ({mockTitle, message, classNames}) => (
        <div className={`${classNames ? classNames : "no-info-container"}`}>
            <h5>{mockTitle}</h5>
            <p className="alert alert-info">{message}</p>
        </div>
    );

    const onCalendarMonthChange = (monthDate)=>{


        setInitPeriod(new Date(monthDate.startOf('month').format()));
        setEndPeriod(new Date(monthDate.endOf('month').format()));
    }

    return (
        <div className="container-fluid p-0 PatientProfile">
            <DoctorProfileTopBar me={me} back={true}/>

            <div className="row ParentRow">

                <div className="col-lg-4 LeftSide">
                    {patient ?

                        <div className="profile-init-data widget">
                            <p className='title'>Perfil del Paciente</p>

                            <img
                                className={patient.profileImage !== null ? "profile-photo" : 'no-photo'}
                                src={patient.profileImage !== null ? api.appFiles.getFileUrl(patient.profileImage) : UserCircle}
                                alt="Fotografía del paciente"
                            />

                            <div className='personalInfo'>
                                <h5>Datos personales</h5>
                                <p className='title-name'>{patient.name + ' ' + patient.lastName}</p>
                                <p>Sexo: {patient.gender ? (patient.gender === 'M' ? 'Masculino' : 'Femenino') : 'Sin especificar'}</p>
                                <p>Edad: {patient.birthDate ? moment().diff(patient.birthDate, 'years') : 'Sin especificar'}</p>
                                <p>Correo: {patient.user.email}</p>
                            </div>
                        </div> :
                        <h4>Cargando...</h4>
                    }
                    <div className="patient-diagnostic widget">
                        <h5>Diagnostico del Paciente:</h5>
                        <PatientDiagnosticSelector key="patientDiagnosticSelector"/>
                    </div>
                    <div className="patient-management widget">
                        <h5>Gestión del Paciente:</h5>
                        <div className="patient-management-options">
                            <button onClick={() => {
                                setModalState(true);
                                setModalReason('patientMedicineAssignment');
                                setModalReasonTitle('Asignar Medicamentos');
                                setModalReasonDescription('Asigna los medicamentos que el paciente verá al momento de registrar un episodio de migraña');
                            }}>
                                Asignar medicamentos
                                <img src={Plus} alt="Más Información"/>
                            </button>
                            <button onClick={() => {
                                setModalState(true);
                                setModalReason('patientMidasAndHit6');
                                setModalReasonTitle('Midas/Hit6 del Paciente');
                                setModalReasonDescription('Consulta o asigna los valores de tu paciente');
                            }}>
                                Midas/Hit6 del Paciente
                                <img src={Plus} alt="Más Información"/>
                            </button>
                        </div>
                        {
                            modalState &&
                            <Modal
                                onClose={() => {
                                    setModalState(false);
                                    setSelecting(false);
                                    setChanging(false);
                                }}
                                title={modalReasonTitle}
                                description={<p className="modal-description">{modalReasonDescription}</p>}
                            >
                                {
                                    modalReason === "patientMedicineAssignment"
                                        ? (
                                            <div className="patient-medicineAssignment-management container row">
                                                <div className="col-6">
                                                    <h6>Medicinas disponibles al paciente:</h6>
                                                    {
                                                        medicineStuff.length > 0
                                                            ? (
                                                                <ul>
                                                                    {medicineStuff.map((medicine, index) =>
                                                                        <li key={index}>
                                                                            {medicine.name}
                                                                            <button className="remove"
                                                                                    onClick={() => removeAssignedMedicine(medicine.id)}>Remover
                                                                            </button>
                                                                        </li>)
                                                                    }
                                                                </ul>
                                                            )
                                                            : <NoInfoAlert classNames="no-medicine-info"
                                                                           message="Tu paciente no tiene medicamentos asignados. Intenta añadir uno."/>
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <h6>Todas las medicinas disponibles:</h6>
                                                    <ul>
                                                        {
                                                            medicines.map(medicine =>
                                                                <li key={medicine.id}
                                                                    className={_.find(medicineStuff,stuff => stuff.id === medicine.id) ? "disabled" : ""}>
                                                                    {medicine.name}
                                                                    <button
                                                                        className="add"
                                                                        type="button"
                                                                        onClick={() => selectMedicine(medicine)}
                                                                    >Añadir
                                                                    </button>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                <button type="button" className="send" onClick={updateMedicineAssignment}
                                                        disabled={selecting ? false : true}>
                                                    {loading ? 'Actualizando...' : 'Actualizar Asignación'}
                                                </button>
                                            </div>
                                        )
                                        : (
                                            <form className="midas-and-hit6-container">
                                                <label>
                                                    Midas:
                                                    <input type="number" step="0.01" name="midas" value={formState.midas}
                                                           onChange={handleInputValue}/>
                                                </label>
                                                <label>
                                                    Hit6:
                                                    <input type="number" step="0.01" name="hit6" value={formState.hit6}
                                                           onChange={handleInputValue}/>
                                                </label>
                                                <button
                                                    type="submit"
                                                    onClick={handleMidasAndHit6Data(formState)}
                                                    disabled={changing ? false : true}
                                                >
                                                    {loading ? 'Guardando...' : 'Guardar Cambios'}
                                                </button>
                                            </form>
                                        )
                                }
                            </Modal>
                        }
                    </div>
                    {/* PATIENT COUNTER */}

                    {/* PATIENT CALENDAR */}
                    <div className='calendar'>
                        <h5>Calendario de episodios</h5>

                        <MigraineRecordCalendar patientId={id} onMonthChange={onCalendarMonthChange}/>
                    </div>

                </div>


                <div className="col-lg-8 RightSide">

                    <div className='datePickers-wrapper'>
                        <div className='datePickers-init'>
                            <label>
                                Fecha inicio
                                <DatePicker className="datePicker-filter" clearIcon={null} format="d-MM-y"
                                            value={initPeriod} onChange={handleInitDate}/>
                            </label>
                        </div>
                        <div className='datePickers-end'>
                            <label>
                                Fecha fin
                                <DatePicker className="datePicker-filter" clearIcon={null} format="d-MM-y"
                                            value={endPeriod} onChange={handleEndDate}/>
                            </label>
                        </div>
                    </div>

                    {/* CHARTS SECTION */}
                    <div className="row">
                        <div className='col-lg-12'>
                            <h5>Estadísticas del {moment(initPeriod).format('DD/M/YYYY')} <span
                                className="toDate">al</span> {moment(endPeriod).format('DD/MM/YYYY')}</h5>
                        </div>
                        <div className='col-lg-12'>
                            <div className="patient-time-results">
                                <div className='row'>
                                    <div className='col-sm-12 col-md-4 monthlyResult'>
                                        <div>
                                            <p>Episodios</p>
                                            <p className='subtitle'>Días con episodios: {generalStatics.daysWithEpisodesCount}</p>
                                            <p className='subtitle'>Totales en el periodo: {generalStatics.episodesCount}</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4 monthlyResult'>
                                        <div>
                                            <p>nro. medicamentos tomados</p>
                                            <p className='subtitle'>Abortivos: {generalStatics.abortiveMedicinesCount?generalStatics.abortiveMedicinesCount:'Sin información'}</p>
                                            <p className='subtitle'>Preventivos: {generalStatics.preventiveMedicinesCount?generalStatics.preventiveMedicinesCount:'Sin información'}</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4 monthlyResult'>
                                        <div>
                                            <p>Duración promedio</p>
                                            <p className='subtitle'>{Number(parseFloat(generalStatics.durationMinutesAverage / 60).toFixed(2))} Horas</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4 monthlyResult'>
                                        <div>
                                            <p>Detonante común</p>
                                            <p className='subtitle'>{topTriggers}</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4 monthlyResult'>
                                        <div>
                                            <p>Síntoma acompañante común</p>
                                            <p className='subtitle'>{topSymptoms}</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4 monthlyResult'>
                                        <div>
                                            <p>Característica de dolor común</p>
                                            <p className="subtitle">{topPainCharacteristic}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* DURATION CHARTS AND PAIN LOCATION */}

                        <div className='col-lg-6 stats-widget'>
                            <div className='graphic-background'>
                                {
                                    generalStatics.statsInTime !== undefined &&
                                    generalStatics.statsInTime.length > 0
                                        ? <div>
                                            <h5>Intensidad</h5>
                                            <ReactEcharts option={chartIntensity}/>
                                        </div>
                                        : <NoInfoAlert mockTitle="Intensidad"
                                                       message="Por el momento el paciente no ha registrado información."/>
                                }
                            </div>
                        </div>
                        <div className='col-lg-6 stats-widget'>
                            <div className='graphic-background'>
                                {
                                    generalStatics.statsInTime !== undefined &&
                                    generalStatics.statsInTime.length > 0
                                        ? <div>
                                            <h5>Duración</h5>
                                            <ReactEcharts option={chartDuration}/>
                                        </div>
                                        : <NoInfoAlert mockTitle="Duración"
                                                       message="Por el momento el paciente no ha registrado información."/>
                                }
                            </div>
                        </div>

                        {/*
                        <div className='col-lg-6 stats-widget'>
                            <div className="graphic-background">
                                {
                                    generalStatics.topPreventiveMedicines &&
                                    generalStatics.topPreventiveMedicines.length > 0
                                        ? <div><h5>Medicinas preventivas</h5><ReactEcharts option={topPreventiveMedicineConfig}/></div>
                                        : <NoInfoAlert mockTitle="Medicinas preventivas"
                                                       message="Por el momento el paciente no ha registrado información."/>
                                }
                            </div>
                        </div>
                        */}

                        <div className='col-lg-6 stats-widget'>
                            <div className="graphic-background">
                                {
                                    generalStatics.topAbortiveMedicines &&
                                    generalStatics.topAbortiveMedicines.length > 0
                                        ? <div><h5>Medicinas abortivas</h5><ReactEcharts option={topAbortiveMedicineConfig}/></div>
                                        : <NoInfoAlert mockTitle="Medicinas abortivas"
                                                       message="Por el momento el paciente no ha registrado información."/>
                                }
                            </div>
                        </div>



                        <div className='col-lg-6 stats-widget'>
                            <div className='graphic-background'>
                                {
                                    generalStatics.topTriggers &&
                                    generalStatics.topTriggers.length > 0
                                        ? <div><h5>Desencadentantes</h5><ReactEcharts option={topTriggerConfig}/></div>
                                        : <NoInfoAlert mockTitle="Detonantes"
                                                       message="Por el momento el paciente no ha registrado información."/>
                                }
                            </div>
                        </div>
                        <div className='col-lg-6 stats-widget'>
                            <div className='graphic-background'>
                                {
                                    generalStatics.topSymptoms &&
                                    generalStatics.topSymptoms.length > 0
                                        ? <div><h5>Síntomas</h5><ReactEcharts option={topSymptomConfig}/></div>
                                        : <NoInfoAlert mockTitle="Síntomas"
                                                       message="Por el momento el paciente no ha registrado información."/>
                                }
                            </div>
                        </div>
                        <div className='col-lg-6 stats-widget'>
                            <div className='graphic-background'>
                                {
                                    generalStatics.topPainCharacteristics &&
                                    generalStatics.topPainCharacteristics.length > 0
                                        ? <div><h5>Características de dolor</h5><ReactEcharts option={topPainCharacteristicConfig}/></div>
                                        : <NoInfoAlert mockTitle="Características de dolor"
                                                       message="Por el momento el paciente no ha registrado información."/>
                                }
                            </div>
                        </div>
                        <div className='col-lg-6 stats-widget'>
                            <div className="pain-locations-section">
                                <h5>Puntos de dolor más frecuentes:</h5>
                                <PainLocationPicker
                                    showStatistics={true}
                                    getStatistics={topListOfPainLocations}
                                    disableRemoveItems={true}
                                    className="painLocationsStatistics"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
};
export default PatientProfile;