export const initFormModel = [
    {
        title: "Nombre del Paciente:",
        tag: "input",
        name: "name",
        type: "text",
        placeholder: "John"
    },
    {
        title: "Apellido del Paciente",
        tag: "input",
        name: "lastName",
        type: "text",
        placeholder: "Doe"
    },
    {
        title: "Correo Electrónico:",
        tag: "input",
        name: "email",
        type: "text",
        placeholder: "email@domain.com"
    },
    {
        title: "Contraseña",
        tag: "input",
        name: "password",
        type: "password",
        placeholder: "Escribe una contraseña"
    },
];