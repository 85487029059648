import './DoctorsList.scss';

import React, {useCallback, useState} from 'react';
import { useSelector } from 'react-redux';
import Plus from '../../../assets/img/icons/plus.svg';
import Trash from '../../../assets/img/icons/trash.svg';
import { ApiContext } from '../../../services/api/api-config';
import Modal from '../../../components/Modal/Modal';
import TopBar from '../../../components/TopBar/TopBar';
import Sorter from '../../../components/Sorter/Sorter';
import DataTable from '../../../components/DataTable/DataTable';
import SearchBar from '../../../components/Searchbar/SearchBar';
import FormCreator from '../../../components/FormCreator/FormCreator';
import LogoutButton from '../../../components/LogoutButton/LogoutButton';
import { listSGroups } from '../../../utils/modelUtils/doctorUtils';
import ConfigurationModal from "../Configuration/ConfigurationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { dataCreateModel, formCreateModel, formEditModel, prepareEditModel } from './form.config';
import { DataTableHeaders, sorterConfig } from './components.config';
import { doctor } from '../../../utils/modelUtils/roles';
import {getNotifier} from "../../../services/notifierUtils";
import useCallbackCreator from "use-callback-creator";
import moment from "moment";

function Laboratories() {
    /* ---- STATES ---- */
    const [error, setErrorState] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [dataEditModel, setDataEditModel] = React.useState(false);
    const [typingState, setTypingState] = React.useState(false);    //Checks the getEditInputData function
    const [modalState, setModalState] = React.useState(false);      //Form create state
    const [editMode, setEditMode] = React.useState(false);          //Form edit state
    const [searchVal, setSearchVal] = React.useState('');           //SearchBar State
    const [showConfig, setShowConfig] = React.useState(false);
    const [order, setOrder] = React.useState('order[name]');
    const [orderVal, setOrderVal] = React.useState('ASC');
    const [removingDoctorId, setRemovingDoctorId] = useState(null);

    const loadingId = '@Sponsors.apiCalls';
    const sendingWelcomeLoadingId = '@Sponsors.users.sendingWelcome';
    const loading = useSelector(({ loadingIds }) => !!loadingIds[loadingId]);
    const loadingIds = useSelector(({loadingIds}) => loadingIds);

    /* ---- API CALLS---- */
    const api = React.useContext(ApiContext);


    const fetchDoctors = useCallback(()=>{
        api.doctors.get({
            params: {
                sGroups: listSGroups,
                name: searchVal,
                [order]: orderVal
            }
        });
    }, [api, searchVal, order, orderVal]);

    React.useEffect(() => {
        fetchDoctors();
    }, [fetchDoctors]);

    const data = useSelector(({ api }) => api.doctors);
    const me = useSelector(({ api }) => api.me);

    const sendWelcome =  useCallbackCreator((id, e) => {
        e.stopPropagation();
        api.users.sendWelcome({userId: id, customProp: sendingWelcomeLoadingId + id})
            .then((response) => {
                fetchDoctors();
                getNotifier().success('Contraseña enviada');
            })
            .catch(e => {
                getNotifier().error(e.detail);
            });
    }, [fetchDoctors])

    /* --- FORM MODEL CONFIG--- */
    const preparedEditModel = data => setDataEditModel(prepareEditModel(data));
    const ModalDescription = ({ isEditing }) => <p className="modal-description">Completa los campos para {isEditing ? 'editar' : 'crear'} una cuenta</p>;

    /* --- COMPONENTS METHODS ---- */
    //SEARCH METHODS
    const handleSearchValues = values => setSearchVal(values.trim());

    //FORM METHODS
    const getEditInputData = () => setTypingState(true);
    const removeDoctor= id => () => api.doctors.delete({ id: id }).catch(e => alert(`Error: ${e.detail}`)).finally(()=>setRemovingDoctorId(null));

    const handleFormData = (sGroups, method, currentCachedData) => data => {
        const params = {
            sGroups: sGroups,
            name: data.name,
            lastName: data.lastName,
            assignedLicences: parseInt(data.assignedLicences)
        };

        const sendData = () => {
            if (method !== 'create') {
                if (data.email !== currentCachedData.email) {
                    params.user = { ...params.user, id: data.userId, email: data.email }
                }
            } else {
                if (data.email) {
                    params.usedLicences = 0;
                    params.sponsor = me.sponsor.id;
                    params.user = {
                        role: doctor,
                        email: data.email
                    };
                }
            }
            api.doctors[method](
                method === 'create'
                    ? { params: params, loadingId }
                    : { id: data.id, params: params, loadingId }
            )
                .then(() => {
                    setModalState(false)
                    api.me.get();
                })
                .catch(error => {
                    setErrorState(true);
                    setErrorMessage(error.detail);
                });
            setErrorState(false);
        }

        //Validations
        if (method === 'create') {
            const wordLength = 3;
            if (!data['name'] || data['name'].length < wordLength) {
                setErrorState(true);
                setErrorMessage(`El nombre del doctor debe tener más de ${wordLength} caracteres`);
            } else if (!data['lastName'] || data['lastName'].length < wordLength) {
                setErrorState(true);
                setErrorMessage(`El apellido del doctor debe tener más de ${wordLength} caracteres`);
            } else if (!data['email'] || data['email'].length < wordLength) {
                setErrorState(true);
                setErrorMessage(`El email debe tener más de ${wordLength} caracteres`);
            } else if (data['email'].includes('@') === false || data['email'].includes('.') === false) {
                setErrorState(true);
                setErrorMessage('Parece ser que el email no es válido');
            } else {
                sendData();
            }
        }
        if (method === 'update') {
            if (me.sponsor.usedLicences > me.sponsor.assignedLicences) {
                setErrorState(true);
                setErrorMessage(`No es posible usar más licencias de las asignadas: Actualmente tienes ${me.sponsor.assignedLicences} licencias asignadas.`);
            } else if (data["assignedLicences"] < data["usedLicences"]) {
                setErrorState(true);
                setErrorMessage(`No es posible asignar menos licencias de las utilizadas: Actualmente la cuenta ha utilizado ${data.usedLicences} licencias.`);
            } else {
                sendData();
            }
        }
    }

    // SORTER METHODS
    const handleSelectedOption = option => {
        if (option === 'name') {
            setOrder('order[name]');
            setOrderVal('ASC');
        } else {
            setOrder('order[assignedLicences]');
            setOrderVal('DESC');
        }
    }

    const onCloseModal = useCallback(() => {
        setShowConfig(false);
    }, [setShowConfig]);

    const onClickConfiguration = useCallback(() => {
        setShowConfig(true);
    }, [setShowConfig]);

    return (
        <div className='Laboratories fluid-container'>
            <TopBar isInDesktop={true} customClassName="topBar" childrenClassName="justify-content-between">
                <h1 className="title">Asignación de Licencias</h1>
                <LogoutButton classNames="logout-white" />
            </TopBar>

            <div className="laboratory-content">
                <header className="row align-items-center">
                    <div className="col-lg-6 d-flex align-items-center">
                        <SearchBar className="searchBar" onInputChange={handleSearchValues} placeHolder="Buscar Doctor" mustDebounceValues={true} debounceTime={200} />
                        <Sorter title="Ordenar por:" config={sorterConfig} getSelectedOption={handleSelectedOption} />
                    </div>
                    <div className='buttons col-lg-6 d-flex justify-content-between align-items-center flex-wrap'>
                        <span className="licensesStatus">Licencias utilizadas: {me.sponsor.usedLicences} de {me.sponsor.assignedLicences}</span>

                        <div className='config-button' onClick={onClickConfiguration}>
                            <FontAwesomeIcon icon={faCogs} />
                        </div>

                        <button className="create-button" onClick={() => { setModalState(true); setEditMode(false); }}>
                            <img src={Plus} alt="Add Element" />
                            Nueva Cuenta
                        </button>
                    </div>
                </header>


                <DataTable headers={<DataTableHeaders />}>
                    {data && data.map((element, rowIndex) =>
                        <tr role="row" key={rowIndex}>
                            <td>{element.lastName ? `${element.name} ${element.lastName}` : element.name}</td>
                            <td>{element.user.email}</td>
                            <td>{element.usedLicences + '/' + element.assignedLicences}</td>
                            <td>{element.allowDataSharing?'Si':'No'}</td>
                            <td>
                                <span className="actions">
                                    <button className="edit" onClick={() => {
                                        setModalState(true);
                                        setEditMode(true);
                                        preparedEditModel(data[rowIndex]);
                                    }}>Editar</button>

                                       <button className="edit send-welcome" onClick={sendWelcome(element.user.id)}
                                               disabled={!!loadingIds[sendingWelcomeLoadingId + element.id]}>
                                        {element.welcomeMailSentDate ? 'Reenviar' : 'Envíar'} bienvenida

                                           {element.welcomeMailSentDate &&
                                           <small>Envíada el {moment(element.welcomeMailSentDate).format('DD-MM-Y hh:mm')}</small>
                                           }
                                    </button>

                                    <button className="remove" onClick={()=>setRemovingDoctorId(element.id)}>
                                        <img src={Trash} alt="Eliminar" />
                                    </button>
                                </span>
                            </td>
                        </tr>
                    )}
                </DataTable>
                {
                    modalState &&
                    <Modal
                        title={editMode ? 'Editar cuenta' : 'Nueva cuenta'}
                        description={<ModalDescription isEditing={editMode} />}
                        onClose={() => {
                            setModalState(false);
                            setErrorState(false);
                            setTypingState(false);
                        }
                        }
                    >
                        <FormCreator
                            editMode={editMode ? true : false}
                            dataModel={editMode ? dataEditModel : dataCreateModel}
                            formModel={editMode ? formEditModel : formCreateModel}
                            formClassName={error ? 'animated shake' : ''}
                            handleFormData={editMode ? handleFormData(listSGroups, 'update', dataEditModel) : handleFormData(listSGroups, 'create')}
                            handleInputData={getEditInputData}
                            mustGetInputData={true}
                        >
                            {error &&
                                <div className="alert alert-danger animated fadeIn" role="alert">
                                    {errorMessage}
                                </div>
                            }

                            <button
                                type="submit"
                                disabled={typingState ? false : true}
                                className={typingState ? "active" : "muted"}
                            >
                                {
                                    !editMode
                                        ? loading ? 'Enviando...' : 'Crear Cuenta'
                                        : loading ? 'Enviando...' : 'Actualizar Cuenta'
                                }
                            </button>

                        </FormCreator>
                    </Modal>
                }
            </div>

            {showConfig ? <ConfigurationModal sponsor={me.sponsor} onClose={onCloseModal} showModal /> : null}
            {
                removingDoctorId &&
                <Modal
                    title="Advertencia!"
                    onClose={() => setRemovingDoctorId(null)}>
                    <div className="animated shake alert alert-danger m-0" role="alert">
                        <p>Todos los datos asociados a este doctor serán eliminados, incluidos pacientes y sus registros.</p>
                        <p>¿Estás seguro de borrar esa información?</p>
                        <button type="button" className="btn btn-danger"
                                onClick={removeDoctor(removingDoctorId)}>Si, borrar todo.
                        </button>
                        &nbsp;
                        <button type="button" className="btn btn-info"
                                onClick={() => setRemovingDoctorId(null)}>Cancelar
                        </button>
                    </div>
                </Modal>
            }
        </div>
    );
}
export default Laboratories;