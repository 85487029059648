// --- Data model for send to server ---
export const dataCreateModel = {
    name: '',
    lastName: '',
    assignedLicences: 0,
    email: ''
}

// --- FORM STRUCT --- 
export const formCreateModel = [
        {
            name: "name",
            tag: "input",
            type: 'text',
            title: 'Nombre del Doctor:',
            placeholder: 'John'
        },
        {
            name: "lastName",
            tag: "input",
            type: "text",
            title: "Apellido del Doctor:",
            placeholder: 'Doe'
        },
        {
            name: "assignedLicences",
            tag: "input",
            type: 'number',
            title: 'Número de licencias:',
            placeholder: 0,
            attributes: {
                min: 0
            }
        },
        {
            name: "email",
            tag: "input",
            type: 'text',
            title: 'Correo Electrónico:',
            placeholder: 'john@laboratorio.com'
        }

    ];

export const prepareEditModel = data => {
    const editModel = {
        id: data.id,
        name: data.name,
        lastName: data.lastName,
        assignedLicences: data.assignedLicences,
        usedLicences: data.usedLicences,
        email: data.user.email,
        userId: data.user.id
    }
    return editModel;
};

export const formEditModel = [
    {
        title: 'Nombre:',
        name: 'name',
        tag: 'input',
        type: 'text',
    },
    {
        title: "Apellido:",
        name: "lastName",
        tag: "input",
        type: "text"
    },
    {
        title: 'Licencias asignadas:',
        name: 'assignedLicences',
        tag: 'input',
        type: 'number',
        attributes: {
            min: 0
        }
    },
    {
        title: "Correo electrónico:",
        name: "email",
        tag: 'input',
        type:"text"
    }

];