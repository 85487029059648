import moment from 'moment';

export const processBarChart = (
    initPeriod,
    endPeriod,
    entity,
    data,
    chartDescription
) => {

    if (typeof data !== 'undefined') {
        //Setting up our array of date ranges.
        const dateFormat = 'D/MM';
        //Setting up our array dates of server data.
        const serverDates = data.map((data) => ({
            date: moment(data.fromDate).format(dateFormat),
            value: Math.trunc(data[entity]),
        }));
        const findSameDate = serverDates.reduce((acc, item) => {
            acc[item.date] = acc[item.date] || [];
            acc[item.date].push(item);
            return acc;
        }, {});
        const sameDateTemp = Object.values(findSameDate);
        //console.log(sameDateTemp);

        const processedValues = sameDateTemp.map((key) => ({
            date: key[0].date,
            value: entity === 'durationMinutesAverage'
                ? Number(
                    parseFloat(key.reduce((a, b) => a + b.value, 0) / 60)
                        .toFixed(2)
                )
                : key.reduce((a, b) => Math.max(a , b.value), 0)
        }));


        //Now, we're going to add values to datesBetween array.
        /*
        const entityData = datesBetween.map((data) =>
            processedValues.find((time) => time.date === data.date)
                ? processedValues.shift()
                : { date: data.date, value: 0 }
        );
         */
        const entityData = processedValues;


        //Get the highest value in array
         let highestVal =
            entity === 'durationMinutesAverage'
                ? entityData.reduce((a, b) => a + b.value, 0)
                : Math.max(...entityData.map((data) => data.value), 0);

        if(entity === 'durationMinutesAverage')
            highestVal = 5;

        //Coloring chart bars
        const coloringChartBars = (array) => {
            return array.map((item) => {
                return (item.itemStyle = {
                    padding: [0, 50],
                    width: 100,
                    color: (() => {
                        if (item.value === highestVal) {
                            return 'red';
                        } else if (
                            item.value >= highestVal / 2 &&
                            item.value < highestVal
                        ) {
                            return '#0A801A';
                        } else {
                            return '#707070';
                        }
                    })(),
                });
            });
        };
        coloringChartBars(entityData);

        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999',
                    },
                },
            },
            dataZoom: [
                {
                    type: 'slider',
                    end: (() => {
                        if (entityData.length > 100) {
                            return 2;
                        } else if (entityData.length > 50) {
                            return 8;
                        } else if (entityData.length > 30) {
                            return 15;
                        } else {
                            return 100;
                        }
                    })(),
                },
            ],
            xAxis: [
                {
                    type: 'category',
                    data: entityData.map((time) => time.date),
                    //splitNumber: 5,
                    axisTick:{
                        show: false,
                        alignWithLabel: true,
                        length: 5,
                        interval: 5
                    },
                    axisLabel: {
                        // force to display all labels
                        interval: 'auto',
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    max: highestVal,
                    splitNumber: 7,
                    nameTextStyle: {
                        fontSize: 12,
                        padding:
                            entity === 'intensity'
                                ? [0, 0, 0, 60]
                                : [0, 0, 0, 60],
                    },
                    axisLabel: {
                        // force to display all labels
                        interval: 0,
                    },
                },
                {
                    type: 'value',
                    min: 0,
                    max: highestVal,
                    splitNumber: 7,
                    nameTextStyle: {
                        fontSize: 12,
                        padding:
                            entity === 'intensity'
                                ? [0, 60, 0, 0]
                                : [0, 60, 0, 0],
                    }
                },
            ],
            series: [
                {
                    name: chartDescription,
                    type: 'bar',
                    barWidth: 10,
                    data: entityData.map((data) => data),
                },
                {
                    name: '',
                    type: 'line',
                    yAxisIndex: 1,
                    symbolSize: 10,
                    data: entityData.map((data) => data),
                },
            ],
        };
    }
};
