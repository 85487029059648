// --- Data model for send to server ---
export const dataCreateModel = {
    name: '',
    contactName: '',
    assignedLicences: 0,
    email: '',
}

// --- FORM STRUCT ---
export const formCreateModel = [
    {
        name: "name",
        tag: "input",
        type: 'text',
        title: 'Nombre de laboratorio:',
        placeholder: 'Laboratorio'
    },
    {
        name: "contactName",
        tag: "input",
        type: 'text',
        title: 'Nombre de contacto:',
        placeholder: 'John Doe'
    },
    {
        name: "assignedLicences",
        tag: "input",
        type: 'number',
        title: 'Número de licencias:',
        placeholder: 0,
        attributes: {
            min: 0
        }
    },
    {
        name: "email",
        tag: "input",
        type: 'text',
        title: 'Correo Electrónico:',
        placeholder: 'john@laboratorio.com'
    }
];


export const formEditModel = [
    {
        title: 'Nombre:',
        name: 'name',
        tag: 'input',
        type: 'text',
    },
    {
        title: 'Nombre de contacto:',
        name: "contactName",
        tag: 'input',
        type: 'text',
    },
    {
        title: 'Licencias asignadas:',
        name: 'assignedLicences',
        tag: 'input',
        type: 'number',
        attributes: {
            min: 0
        }
    },
    {
        title: "Correo electrónico",
        name: "email",
        tag: "input",
        type: "text"
    },
];

/* --- FORM MODEL CONFIG--- */
export const prepareEditModel = data => {
    const editModel = {
        id: data.id,
        name: data.name,
        contactName: data.contactName,
        assignedLicences: data.assignedLicences,
        usedLicences: data.usedLicences,
        userId: data.user.id,
        email: data.user.email,
        isActive: data.user.isActive
    }
    return editModel;
};