import React from 'react';

/* --- SORTER CONFIG --- */
export const sorterConfig = {
    defaultValue: "name",
    options: [
        {value: 'name', title: 'Orden alfabético'},
        {value: 'licenses', title: 'Número de licencias'}
    ]
};

//Headers for DataTable Component
export const DataTableHeaders = () =>
<>
    <th className="th-name">Nombre de laboratorio</th>
    <th className="th-contactName">Nombre de contacto</th>
    <th>Correo Electrónico</th>
    <th>Número de Licencias</th>
    <th>Información compartida</th>
    <th>Acciones</th>
</>