import './PatientFilters.scss';
import { isEmpty } from 'lodash';
import { getOptions, filterPatientsBy } from './helpers';
import React, {useState, useMemo, useEffect, useRef} from 'react';
import useInput from '../../../../hooks/useInput';
import MultiSelect from 'react-multi-select-component';
import SearchBar from '../../../../components/Searchbar/SearchBar';

const PatientFilters = ({patients, setFilteredPatients}) => {
    //Initializers
    const didMount = useRef(true);
    const searchResults = useRef(null);
    const searchInput = useInput();
    const medicineOptions = useMemo(() => getOptions(patients, "medicines"), [patients]);
    const diagnosticOptions = useMemo(() => getOptions(patients, "diagnostics"), [patients]);

    //State
    const [medicines, setMedicines] = useState([]);
    const [diagnostics, setDiagnostics] = useState([]);

    //Search Filter
    useEffect(() => {
        if(didMount.current){
            didMount.current = false;
        } else {
            if(!isEmpty(patients)){
                if(searchResults.current === false){
                    setFilteredPatients(patients);
                };

                //Search
                if(searchInput.value.trim()){
                    const text = searchInput.value.trim().toLowerCase();
                    setFilteredPatients( patient => patient.filter( p => p.name.toLowerCase().indexOf(text) !== -1));
                } else {
                    searchResults.current = false;
                }

                //MultiSelect
                if(!isEmpty(medicines) || !isEmpty(diagnostics)) {
                    const medicinesSelected = medicines.map( ({value:id}) => id );
                    const diagnosticsSelected = diagnostics.map( ({value:id}) => id );

                    const medicinesFiltered = filterPatientsBy(patients, "medicines", medicinesSelected);
                    const diagnosticsFiltered = filterPatientsBy(patients, "diagnostics", diagnosticsSelected);

                    const result = [...new Set([...medicinesFiltered, ...diagnosticsFiltered])];

                    if(!isEmpty(result)){
                        setFilteredPatients(result);
                    };
                } else {
                    searchResults.current = false;
                }
            }
        }
    }, [patients, setFilteredPatients, searchInput.value, medicines, diagnostics]);

    return (
        <div className="PatientFilters">
            <SearchBar
                className="filter "
                placeHolder="Buscar paciente"
                inputProps={searchInput.bind}
            />

            <MultiSelect
                className="filter multi-select"
                hasSelectAll={false}
                options={medicineOptions}
                value={medicines}
                onChange={setMedicines}
                overrideStrings={{
                    "selectSomeItems": "Buscar por medicina",
                    "allItemsAreSelected": "Se han seleccionado todas las medicinas",
                    "search": "Buscar medicina"
                }}
            />

            <MultiSelect
                className="filter multi-select"
                hasSelectAll={false}
                options={diagnosticOptions}
                value={diagnostics}
                onChange={setDiagnostics}
                overrideStrings={{
                    "selectSomeItems": "Buscar por diagnóstico",
                    "allItemsAreSelected": "Se han seleccionado todos los diagnósticos",
                    "search": "Buscar diagnóstico"
                }}
            />
        </div>
    );
};
export default PatientFilters;