import React, {useCallback, useContext, useEffect, useState} from 'react';
import './MainScreen.scss';
import TopBar from "../../../components/TopBar/TopBar";
import { ReactComponent as GraphIcon } from "../../../assets/img/icons/graph.svg";
import { ReactComponent as PillsIcon } from "../../../assets/img/icons/pills.svg";
import { ReactComponent as ListIcon } from "../../../assets/img/icons/list.svg";
import { ReactComponent as CommentIcon } from '../../../assets/img/icons/comment.svg';
import { Link } from "react-router-dom";
import { paths } from "../../../services/routes/appRoutes";
import LogoutButton from '../../../components/LogoutButton/LogoutButton';
import WelcomeMessage from "./components/WelcomeMessage";
import { useSelector } from "react-redux";
import { ApiContext } from '../../../services/api/api-config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";
import SponsorSplash from "../../../components/SponsorSplash/SponsorSplash";
const MainScreen = () => {
    const api = useContext(ApiContext);
    const [sponsor,setSponsor] = useState(null);
    React.useEffect(() => {
        api.me.get();
    }, [api])
    const me = useSelector(({ api }) => api.me);

    useEffect(()=>{
        if(me.patient){
            setSponsor(me.patient.doctor.sponsor)
        }
    }, [me])

    const [shareDataAllowed, setShareDataAllowed] = useState(false);
    const toggleDataSharing = useCallback(()=>{
        api.users.toggleDataSharingPermissions({}).then((data)=>{
            setShareDataAllowed(data.allowDataSharing);
        })
    },[api, setShareDataAllowed]);

    useEffect(()=>{
        if(me.patient){
            setShareDataAllowed(me.patient.allowDataSharing);
        }
    }, [me, setShareDataAllowed]);

    return (
        <div className='MainScreen'>
            <SponsorSplash sponsor={sponsor}/>
            <TopBar childrenClassName="patientTopBar">
                <h1 className="title">Bienvenido</h1>
                <LogoutButton classNames="Logout-Custom-Button" legend="Cerrar Sesión" />
            </TopBar>

            <div className='content scrollable-content'>

                <WelcomeMessage patient={me.patient} />

                <Link to={paths.newEpisode}>
                    <button className='Button new-episode-btn'>+ Nuevo episodio</button>
                </Link>

                <div className='nav-buttons'>
                    <Link className='nav-btn' to='/episodes'>
                        <div><span className='icon green'><ListIcon /></span></div>
                        <div className='label green'>Historial</div>
                    </Link>
                    <Link className='nav-btn' to='/medicine'>
                        <div><span className='icon green'><PillsIcon /></span></div>
                        <div className='label green'>Medicinas</div>
                    </Link>
                    <Link className='nav-btn' to='/reports'>
                        <div><span className='icon green'><GraphIcon /></span></div>
                        <div className='label green'>Reporte</div>
                    </Link>
                    <Link className='nav-btn' to='/recommendations'>
                        <div><span className='icon green'><CommentIcon /></span></div>
                        <div className='label green large-label'>Recomendaciones</div>
                    </Link>
                </div>
                <br/>
                <div className='toggleDataSharing text-center' onClick={toggleDataSharing}>
                                                <span className="plusIcon">
                                                    <FontAwesomeIcon icon={shareDataAllowed?faCheckSquare:faSquare}/>
                                                </span> Deseo compartir información con AMCEMIG para fines estadísticos. Compartir la información es opcional y anónimo.
                </div>
            </div>

        </div>
    );
};

export default MainScreen;
