import React, {useCallback, useMemo} from 'react';
import './PainLocationPicker.scss';
import classNames from 'classnames';
import backFace from './assets/cara-atras.png';
import frontFace from './assets/cara-frente.png';
//import SquareRenderer from "./components/SquareRenderer";
import Exit from '../../assets/img/icons/exit.svg';
import painLocationConfig, {painLocationMaps} from "./utils/painLocationConfig";
import {coordInsideRect} from "./utils/locationMathUtils";
import _ from 'lodash';

/**
 *
 * @param className
 * @param value [] It's an array of int representing the location indexes
 * @param onChange
 * @returns {*}
 * @constructor
 */
const PainLocationPicker=({className, value=[], onChange, disableRemoveItems = false, showStatistics = false, getStatistics=[] })=>{
    
    if(showStatistics) typeof getStatistics !== 'undefined' && getStatistics.map( values => value.push(values.location));

    const locations=useMemo(
        ()=>value.map((index)=> _.find(painLocationConfig, (loc)=>loc.index===index))
    ,[value]);

    const clickHandler = useCallback((e)=>{
        if(!onChange) return;

        const click={x:e.nativeEvent.offsetX/e.target.offsetWidth, y:e.nativeEvent.offsetY/e.target.offsetHeight};
        const map=painLocationMaps[e.target.dataset.name];
        const selected = _.find(map, (loc)=>coordInsideRect(click, loc));
        if(selected) {
            const old=_.findIndex(locations, (old)=>old.index===selected.index);
            const newVal=_.map(locations,'index');
            if(old===-1)
                newVal.push(selected.index);
            else
                newVal.splice(old, 1);
            onChange(newVal);
        }
    },[onChange, locations]);

    const unselectLocation = id => {
        const index = locations.map( x => x.index).indexOf(id);
        const newArr = value.splice(index, 1);
        onChange( value.filter( index => index !== newArr) );
    };

    const printPainRange = statistics => {
        statistics.map( values => 
            locations.map( location => 
                    location.index === values.location 
                    ? location.topPickedLocations = values.count
                    : null
            )
        );
    }
    showStatistics && printPainRange(getStatistics);



    const painTopColors = painIntensity => {
        const getPickedLocationsNumbers = locations.map( numbers => numbers.topPickedLocations);
        const allPickedLocationsNumbers = [...getPickedLocationsNumbers];
        const sumPickedLocationsNumbers = allPickedLocationsNumbers.reduce( (a, b) => a + b, 0) / allPickedLocationsNumbers.length;
        

        if(typeof sumPickedLocationsNumbers === 'number'){
            if( painIntensity >= sumPickedLocationsNumbers ){
                return 'pain-high';
            } else if ( painIntensity >= sumPickedLocationsNumbers / 2 | 0 && painIntensity <= sumPickedLocationsNumbers ) {
                return 'pain-medium';
            } else {
                return 'pain-low';
            }
        }
    }
    return (
        <div className={classNames('PainLocationPicker', className)}>
            {!showStatistics && 
                <div className="location-labels">
                    {
                        locations.map( location =>
                            location.map === 'front'
                            ?   <span key={location.index} className="location-label">
                                    {location.name}
                                    
                                    {
                                        !disableRemoveItems &&
                                        <button type="button" className="remove-location" onClick={() => unselectLocation(location.index)}>
                                            <img src={Exit} alt="Eliminar localización de dolor."/>
                                        </button>
                                    }
                                </span>
                            :   <span key={location.index} className="location-label">
                                    {location.name}
                                    {
                                        !disableRemoveItems && 
                                        <button type="button" className="remove-location" onClick={() => unselectLocation(location.index)}>
                                            <img src={Exit} alt="Eliminar localización de dolor."/>
                                        </button>
                                    }
                                </span>
                        )
                    }
                </div>
            }

            <h6>Frente</h6>
            <div className='head-wrap d-flex align-items-center justify-content-center' data-name='front' onClick={clickHandler}>
                <p><small>Derecha</small></p>
                <img src={frontFace} className='face-wire' alt='Frente de la cabeza'/>
                <p><small>Izquierda</small></p>

                {/*<SquareRenderer squares={_.filter(painLocationConfig, (loc)=>loc.map==='front')} />*/}
                {locations.map((l)=>
                    l.map==='front'?
                    <img src={l.img} alt={l.name}  className={`location-image ${ showStatistics && painTopColors(l.topPickedLocations, l)}`}  key={l.index}/>
                    :null
                )}
            </div>

            
            <h6>Atrás</h6>
            <div className='head-wrap d-flex align-items-center justify-content-center' data-name='back' onClick={clickHandler}>
                <p><small>Derecha</small></p>
                    <img src={backFace} className='face-wire' alt='Atrás de la cabeza'/>
                <p><small>Izquierda</small></p>
                {/*<SquareRenderer squares={painLocationConfig.back} />*/}
                {locations.map((l)=>
                    l.map==='back'?
                        <img 
                            src={l.img} alt={l.name} 
                            className={`location-image ${showStatistics && painTopColors(l.topPickedLocations)}`} 
                            key={l.index}
                        />
                        :null
                )}
            </div>
        </div>
    )
};

export default PainLocationPicker;
