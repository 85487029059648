import './Medicine.scss';
import { useSelector } from 'react-redux';
import React, {useState, useContext, useEffect} from 'react';
import TopBar from "../../../components/TopBar/TopBar";
import { ApiContext } from '../../../services/api/api-config';
import SearchBar from "../../../components/Searchbar/SearchBar";
import MedicineList from "../../../components/MedicineList/MedicineList";
import MedicineFilter from '../../../components/MedicineList/MedicineFilter/MedicineFilter';
import { medicineListSGroups as sGroups } from '../../../services/modelUtils/medicineUtils';

const customProp = "@MyMedicines.Store";
const Medicine = () => {
    //Initializers
    const api = useContext(ApiContext)
    const {patient : {id}} = useSelector(({api}) => api.me);

    //State
    const [searchText, setSearchText]     = useState('');
    const [medicineType, setMedicineType] = useState(null);

    //Api Call
    useEffect(() => {
        const params = {sGroups};
        if(searchText) params.name = searchText;
        if(medicineType) params.type = medicineType;

        const payload = {id, params, customProp};
        api.patients.getMedicines(payload);
    }, [id, api, searchText, medicineType]);

    //Handlers
    const onInputChange = (inputText)=>{
       setSearchText(inputText.trim());
    };

    //Redux
    const myMedicines = useSelector(({api}) => api[customProp]) || [];


    return (
        <div className="Medicine">
            <TopBar backButton childrenClassName="justify-content-center medicineTopBar">
                <h1 className="title">Medicamentos</h1>
            </TopBar>

            <div className='fixed-searchBar'>
                <SearchBar onInputChange={onInputChange} placeHolder="Buscar Medicamento"/>
                <MedicineFilter onChange={setMedicineType}/>
            </div>

            <div className='medicine-list scrollable-content'>
                {
                    (!myMedicines.length)
                    ? "Aún no tienes medicamentos asignados"
                    : (
                        <MedicineList
                            disableDots={true}
                            medicinesAsProp={myMedicines}
                        />
                    )
                }
            </div>
        </div>
    );
};
export default Medicine;