import React from 'react';
import Switch from "react-switch";

const GenderPicker = ({ defaultValue, setValue }) => {
    const handleChecks = checked => {
        setValue(checked);
    }

    return (
        <div className='gender-switch-container'>
            <span>Masculino</span>
            <Switch
                checked={defaultValue}
                onChange={handleChecks}
                offColor='#5ab3ff'
                onColor='#ff9bc9'
                checkedIcon={false}
                uncheckedIcon={false}
            />
            <span>Femenino</span>
        </div>
    );
};
export default GenderPicker;