import './ConfigurationModal.scss';
import React, {useState, useEffect, useCallback} from 'react';
import Modal from '../../../components/Modal/Modal';
import {ChromePicker} from 'react-color';
import ImageUploader from "react-images-upload";
import {ApiContext} from '../../../services/api/api-config';
import {getNotifier} from '../../../services/notifierUtils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";
import {useSelector} from "react-redux";

function ConfigurationModal({showModal, onClose, sponsor}) {

    const api = React.useContext(ApiContext);
    const [color, setColor] = useState("#fff");
    const [logo, setLogo] = useState([]);
    const me = useSelector(({api}) => api.me);

    useEffect(() => {
        if (sponsor.appConfig)
            setColor(sponsor.appConfig.color);
    }, [sponsor]);

    const onDrop = logo => {
        setLogo(logo);
    };

    const onSave = () => {

        api.sponsors.update({id: sponsor.id, params: {appConfig: {color}}, files: logo}).then(() => {
            api.me.get();
            getNotifier().success('Configuración guardada');
            onClose();
        }).catch(() => {
            getNotifier().error('Hubo un error guardando la configuración');
        });
        //

    };

    const [showLogoToPatients, setShowLogoToPatients] = useState(false);

    const toggleShowLogoToPatients = useCallback(() => {
        api.users.toggleShowLogoToPatients({}).then((data) => {
            setShowLogoToPatients(data.showLogoToPatients);
        })
    }, [api, setShowLogoToPatients]);

    useEffect(() => {
        setShowLogoToPatients(me.sponsor.showLogoToPatients);
    }, [me, setShowLogoToPatients]);


    const [showLogoToDoctors, setShowLogoToDoctors] = useState(false);

    const toggleShowLogoToDoctors= useCallback(() => {
        api.users.toggleShowLogoToDoctors({}).then((data) => {
            setShowLogoToDoctors(data.showLogoToDoctors);
        })
    }, [api, setShowLogoToDoctors]);

    useEffect(() => {
        setShowLogoToDoctors(me.sponsor.showLogoToDoctors);
    }, [me, setShowLogoToDoctors]);

    return (
        <div className='ConfigurationModal'>
            {showModal ?
                <Modal onClose={onClose} title="Configuración de aplicación">
                    <div className='text-center'>
                        <div className='config-section'>
                            <span>Logo</span>
                            <div className='row'>
                                <div className='col-3'>
                                    <div className='current-logo'>
                                        <img src={sponsor.logo ? api.appFiles.getFileUrl(sponsor.logo) : ''} alt=""/>
                                    </div>
                                </div>
                                <div className='col-9'>
                                    <ImageUploader
                                        withIcon={true}
                                        withPreview
                                        onChange={onDrop}
                                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                                        maxFileSize={5242880}
                                        singleImage
                                        buttonText='Seleccionar logo'
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className='config-section'>
                            <div className='toggleDataSharing' onClick={toggleShowLogoToDoctors}>
                                                <span className="plusIcon">
                                                    <FontAwesomeIcon
                                                        icon={showLogoToDoctors ? faCheckSquare : faSquare}/>
                                                </span> Mostrar logo a doctores
                            </div>

                            <div className='toggleDataSharing' onClick={toggleShowLogoToPatients}>
                                                <span className="plusIcon">
                                                    <FontAwesomeIcon
                                                        icon={showLogoToPatients ? faCheckSquare : faSquare}/>
                                                </span> Mostrar logo a pacientes
                            </div>
                        </div>
                        <hr/>
                        <div className='config-section'>
                            <span>Color principal de la aplicación</span>
                            <ChromePicker
                                color={color}
                                onChange={setColor}
                            />
                        </div>
                        <br/>

                        <div className='text-right'>
                            <button className='Button' onClick={onSave}>Guardar</button>
                        </div>

                    </div>
                </Modal> : null
            }
        </div>
    );
}

export default ConfigurationModal;