import React from 'react';
import { useSelector } from 'react-redux';
import MiscManagement from '../../components/MiscManagement/MiscManagement';
import DoctorProfileTopBar from "../../components/DoctorProfileTopBar/DoctorProfileTopBar";

const SymptomsManagement = () => {
    const groups = ['symptom_read', 'blame_creation', 'user_read_id'];
    const me = useSelector(({ api }) => api.me);
    return (
        <article>
            <DoctorProfileTopBar me={me} back={true} />
            <section>
                <MiscManagement
                    name="Síntoma"
                    entity="symptoms"
                    sGroups={groups}
                    addSearchBar={true}
                    formPlaceholder={['Escribe un nuevo síntoma', 'Edita un síntoma']}
                    searchBarPlaceholder="Buscar síntoma"
                />
            </section>
        </article>
    );
}
export default SymptomsManagement;