import './Login.scss';
import Logo from '../../assets/img/diario-transparente.png';
import LogoWhite from '../../assets/img/diario-transparente.png';
import PainFace from '../../components/PainFace/PainFace';
import cookiesPDF from '../../assets/files/Aviso_de_cookies.pdf';
import termsPDF from '../../assets/files/TERMINOS_DE_USO_Y_POLITICAS.pdf';

import React, {useCallback, useContext, useMemo, useState} from 'react';
import {ApiContext} from "../../services/api/api-config";
import useFormState from "../../hooks/useFormState";
import {useSelector} from "react-redux";
import {LOGIN_STATE, LOGIN_LOADING_ID} from "tide-api";
import {Redirect} from "react-router-dom";
import {version} from '../../pckg';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";


const Login = () => {
    const api = useContext(ApiContext);
    const {form, handleInputChange} = useFormState();
    const [error, setError] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);


    const acceptTerms = useCallback((e)=>{
        setTermsAccepted(!termsAccepted)
    }, [setTermsAccepted, termsAccepted]);


    // --- login action  ----
    const login = useCallback((e) => {
        e.preventDefault();
        if(!termsAccepted)
            return setError('Para ingresar debes aceptar los términos de servicio y políticas AMCEMIG');
        if (!form.user || !form.password)
            return setError('Llena todos los campos');
        setError(null);

        api.login(form.user, form.password)
            .catch(() => {
            });
    }, [api, form, termsAccepted]);

    // --- login state message ------
    const loginState = useSelector(({api}) => api.loggedIn);
    const loading = useSelector(({loadingIds}) => loadingIds[LOGIN_LOADING_ID] || loadingIds['Login.me']);

    const loginMsg = useMemo(() => {
        switch (loginState) {
            case LOGIN_STATE.LOGIN_ERROR:
                return 'Lo sentimos, hubo un error al iniciar sesión.';
            case LOGIN_STATE.BAD_CREDENTIALS:
                return 'El usuario y contraseña no coinciden';
            default:
                return null;
        }
    }, [loginState]);

    // ---- redirect if logged in -------
    const me = useSelector(({api}) => api.me);

    if (loginState === LOGIN_STATE.LOGGED_IN && me)
        return <Redirect to={'/'}/>;


    return (
        <React.Fragment>
            <div className='Login'>
                <img src={LogoWhite} alt="Amcemig logo" className="desktop-logo"/>

                <div className="initPage animated fadeIn">
                    <div className="initContainer">
                        <div>
                            <div className="logo-wrapper">
                                <div className="logo">
                                    <img src={Logo} alt="logo" className="mobile-form-image"/>
                                    <PainFace intensity={2} classNames="desktop-form-image"/>
                                </div>
                                <h5 className="logo-title">Diario de <span>Migraña</span></h5>
                                <p>¡Bienvenido! Completa los datos para poder ingresar:</p>
                            </div>
                            <div className='login-form'>
                                <form onSubmit={login}>
                                    <input autoFocus className='form-control Input' placeholder='Email'
                                           value={form.user || ''} onChange={handleInputChange('user')}/>
                                    <input className='form-control Input' type='password' placeholder='Contraseña'
                                           value={form.password || ''} onChange={handleInputChange('password')}/>

                                    <div className='terms' onClick={acceptTerms}>
                                                <span className="plusIcon">
                                                    <FontAwesomeIcon icon={termsAccepted?faCheckSquare:faSquare}/>
                                                </span> Acepto los términos de uso y políticas de AMCEMIG.
                                        <ul>
                                            <li>
                                                <a href={termsPDF} rel="noopener noreferrer" target="_blank">Términos de servicio</a>
                                            </li>
                                        </ul>
                                        <p>Lee también la política de privacidad incluida la declaración sobre el uso de
                                            cookies.</p>
                                        <ul>
                                            <li>
                                                <a href="https://amcemig.com/aviso-de-privacidad" target="_blank" rel="noopener noreferrer">Política de privacidad</a>
                                            </li>
                                            <li>
                                                <a href={cookiesPDF} target="_blank" rel="noopener noreferrer">Uso de cookies</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button className='Button' type='submit' onClick={login}
                                            disabled={loading}>{loading ? 'Conectando...' : 'Entrar'}</button>
                                    {error && <p className='error text-center'>{error}</p>}
                                    {loginMsg && <p className='error text-center'>{loginMsg}</p>}
                                </form>
                                <div className='forgot-password'>
                                    <Link to={'/forgot-password'}>
                                        <span> Olvidé mi contraseña</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='version'>{version}</p>

            </div>

        </React.Fragment>
    );
};
export default Login;

/**
 *         {
        <>
        {   matchMedia
            ?
            :   <div className="desktop-login">
                    <div className="login-row">
                        <div className="login-col">
                            <div className="logo-container">
                                <div className="head">
                                    <img src={LogoWhite} alt="logo"/>
                                </div>
                                <p className="title">Diario de <strong>Migraña</strong></p>
                            </div>
                        </div>
                        <div className="login-col">
                            <div className="form">
                                <PainFace intensity={2} switchColor={false}/>
                                <h4 className="light"><strong>¡Bienvenido!</strong> Completa los datos para poder ingresar:</h4>
                                <div className='login-form animated fadeIn'>
                                    <form onSubmit={login}>
                                        <input autoFocus className='form-control Input' placeholder='E mail' value={form.user||''} onChange={handleInputChange('user')}/>
                                        <input className='form-control Input' type='password' placeholder='Contraseña' value={form.password||''} onChange={handleInputChange('password')}/>
                                        <button className='Button' onClick={login} disabled={loading}>{loading?'Conectando...':'Entrar'}</button>
                                        {error && <p className='text-center'>{error}</p>}
                                        {loginMsg && <p className='text-center'>{loginMsg}</p>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
        }
 </>
 */
