import moment from 'moment';
import _ from 'lodash';
import {copyPropertyOrNull, serverDateTimeFormat} from "../formUtils";

export const prepareMigraineRecordForServer = (migraineRecord) => {

    if (!migraineRecord.fromDate)
        throw new Error('Debes agregar la fecha del episodio');
    if (!migraineRecord.intensity)
        throw new Error('Debes agregar la intensidad del episodio');

    const prepared = {
        ...migraineRecord,
        fromDate: migraineRecord.fromDate.format(serverDateTimeFormat),
        toDate: migraineRecord.toDate ? migraineRecord.toDate.format(serverDateTimeFormat) : null,
    };

    copyPropertyOrNull(migraineRecord, prepared, 'intensity');
    copyPropertyOrNull(migraineRecord, prepared, 'painLocations');

    if (migraineRecord.triggers && migraineRecord.triggers.length)
        prepared.triggers = _.map(migraineRecord.triggers, (trig) => {
            if (trig.id)
                return trig.id;
            return trig;
        });

    if (migraineRecord.symptoms && migraineRecord.symptoms.length)
        prepared.symptoms = _.map(migraineRecord.symptoms, (sym) => {
            if (sym.id)
                return sym.id;
            return sym;
        });

    if (migraineRecord.painCharacteristics && migraineRecord.painCharacteristics.length)
        prepared.painCharacteristics = _.map(migraineRecord.painCharacteristics, (pain) => {
            if (pain.id)
                return pain.id;
            return pain;
        });

    if (migraineRecord.medicines && migraineRecord.medicines.length)
        prepared.migraineRecordMedicines = _.map(_.filter(migraineRecord.medicines, (med) => med.quantity), (med) => (
            {
                medicine: med.id,
                quantity: med.quantity
            }
        ));

    return prepared;
};

export const migraineRecordForForm = (migraineRecord) => {

    return {
        ...migraineRecord,
        fromDate: moment(migraineRecord.fromDate),
        toDate: migraineRecord.toDate ? moment(migraineRecord.toDate) : null
    };
};


export const editSGroups = [
    'migraine_record_read',
    'migraine_record_read_triggers',
    'trigger_read',
    'migraine_record_read_symptoms',
    'symptom_read',
    'migraine_record_read_migraine_record_medicines',
    'migraine_record_medicine_read',
    'migraine_record_medicine_read_medicine',
    'medicine_read',
    'migraine_record_read_pain_characteristics',
    'pain_characteristic_read'
];
