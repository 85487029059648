import './MedicineFilter.scss';
import React, {useState} from 'react';
import PropTypes from 'prop-types';

const dataset = [{id: 0, value: "Todos"},{id: 1, value: "Abortivos"},{id: 2, value: "Preventivos"}];
const MedicineFilter = ({onChange}) => {
    //State
    const [currentTab, setCurrentTab] = useState(0);

    //Handlers
    const handleValue = (tabId) => ({target}) => {
        const value = target.getAttribute('data-value');

        if(value){
            (value === "Todos")
                ? onChange(null)
                : onChange(value);

            setCurrentTab(tabId);
        }
    };

    return (
        <div className="MedicineFilter">
            {dataset.map( ({id, value}) =>
                <button
                    key={id}
                    type="button"
                    data-value={value}
                    onClick={handleValue(id)}
                    className={ (currentTab === id) ? "active" : "" }
                >
                    {value}
                </button>
            )}
        </div>
    );
};
export default MedicineFilter;

MedicineFilter.propTypes = {
    onChange: PropTypes.func.isRequired
};