import React from 'react';
import './EpisodeRow.scss';
import PainFace from "../../../../components/PainFace/PainFace";
import moment from 'moment';
import { Link } from "react-router-dom";
import { paths } from "../../../../services/routes/appRoutes";


const EpisodeRow = ({ episode }) => {

    const getEpisodeIntensityLabel = (episode) => {
        if (episode.intensity <= 5)
            return 'Tolerable';
        else
            return 'Severa';
    };

    const dateFormat = (date) => {
        const formatL = moment.localeData().longDateFormat('L');
        const formatYearlessL = formatL.replace(/YYYY/g, 'YY');
        return moment(date).format(formatYearlessL);
    };

    return (
        <Link to={paths.editEpisode.replace(':id', episode.id)}>
            <div className='EpisodeRow'>
                <div className='face'>
                    <PainFace intensity={episode.intensity} />
                </div>
                <div className='intensity-label'>
                    Intensidad {getEpisodeIntensityLabel(episode)}
                </div>
                <div className='date'>
                    {dateFormat(episode.fromDate)}
                </div>
                <div className='hour'>
                    {moment(episode.fromDate).format('HH:mm')}
                </div>
            </div>
        </Link>
    );
};

export default EpisodeRow;
