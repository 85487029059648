import './DataTable.scss';
import React from 'react';

const DataTable = ({headers, children, classNames=''}) => {
    return (
        <div className={`DataTable ${classNames}`}>
            <table>
                <thead>
                    <tr>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
}

export default DataTable;