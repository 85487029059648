import Front1 from '../assets/lobes/front/m1.png';
import Front2 from '../assets/lobes/front/m2.png';
import Front3 from '../assets/lobes/front/m3.png';
import Front4 from '../assets/lobes/front/m4.png';
import Front5 from '../assets/lobes/front/m5.png';
import Front6 from '../assets/lobes/front/m6.png';
import Front7 from '../assets/lobes/front/m7.png';
import Front8 from '../assets/lobes/front/m8.png';

import Back1 from '../assets/lobes/back/atrasm1.png';
import Back2 from '../assets/lobes/back/atrasm2.png';
import Back3 from '../assets/lobes/back/atrasm3.png';
import Back4 from '../assets/lobes/back/atrasm4.png';


const painLocationConfig=[
    {
        index:1,
        map: 'front',
        x:0,
        y:0,
        width:0.5,
        height: 0.35,
        name:'Lóbulo frontal',
        img: Front1
    },
    {
        index:2,
        map: 'front',
        x:0.5,
        y:0,
        width:0.5,
        height: 0.35,
        name:'Lóbulo frontal.',
        img: Front2
    },
    {
        index:3,
        map: 'front',
        x:0,
        y:0.35,
        width:0.2,
        height: 0.26,
        name:'Lóbulo temporal der. (sien)',
        img: Front3
    },
    {
        index:4,
        map: 'front',
        x:.2,
        y:0.35,
        width:0.3,
        height: 0.26,
        name:'Ocular der.',
        img: Front4
    },
    {
        index:5,
        map: 'front',
        x:.5,
        y:0.35,
        width:0.3,
        height: 0.26,
        name:'Ocular izq.',
        img: Front5
    },
    {
        index:6,
        map: 'front',
        x:0.8,
        y:0.35,
        width:0.2,
        height: 0.26,
        name:'Lóbulo temporal izq. (sien)',
        img: Front6
    },
    {
        index:7,
        map: 'front',
        x:0,
        y:0.61,
        width:0.5,
        height: 0.26,
        name:'Maxilar der.',
        img: Front7
    },
    {
        index:8,
        map: 'front',
        x:0.5,
        y:0.61,
        width:0.5,
        height: 0.26,
        name:'Maxilar izq.',
        img: Front8
    },
    {
        index:9,
        map: 'back',
        x:0,
        y:0,
        width:0.5,
        height: 0.4,
        name:'Lóbulo parietal der.',
        img: Back1
    },{
        index:10,
        map: 'back',
        x:0.5,
        y:0,
        width:0.5,
        height: 0.4,
        name:'Lóbulo parietal izq.',
        img: Back2
    },{
        index:11,
        map: 'back',
        x:0,
        y:0.4,
        width:0.5,
        height: 0.40,
        name:'Lóbulo occipital der.',
        img: Back3
    },{
        index:12,
        map: 'back',
        x:0.5,
        y:0.4,
        width:0.5,
        height: 0.40,
        name:'Lóbulo occipital izq.',
        img: Back4
    }
];

export default painLocationConfig;

export const painLocationMaps=(()=>{
    return painLocationConfig.reduce((maps, location)=>{
        if(!maps[location.map])
            maps[location.map]=[];
        maps[location.map].push(location);
        return maps;
    },{})
})();
