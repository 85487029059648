import React from 'react';
import { useSelector } from 'react-redux';
import MiscManagement from '../../components/MiscManagement/MiscManagement';
import DoctorProfileTopBar from "../../components/DoctorProfileTopBar/DoctorProfileTopBar";

const PainCharacteristicsManagement = () => {
    const groups = ['pain_characteristic_read', 'blame_creation', 'user_read_id'];
    const me = useSelector(({ api }) => api.me);
    return (
        <article>
            <DoctorProfileTopBar me={me} back={true} />
            <section>
                <MiscManagement
                    name="Característica de Dolor"
                    entity="pain_characteristics"
                    sGroups={groups}
                    addSearchBar={true}
                    formPlaceholder={['Escribe una caracteríscica de dolor', 'Edita una característica de dolor']}
                    searchBarPlaceholder="Buscar característica de dolor"
                />
            </section>
        </article>
    );
}
export default PainCharacteristicsManagement;