import React from 'react';


//The styles for this component are defined in /public/index.html to be able to show a proper splash screen
//which is displayed while the app is being downloaded

const Splash=({children})=>{

    return (
        <div className='Splash'>
            <div className="initPage">
                <div className="logo-wrapper">
                    <div className="logo-loading">
                        <img src={"/ini/cabeza.png"} alt="logo"/>
                        {children ?
                            children :
                            <p className="logo-title">Diario de <span>Migraña</span></p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Splash;
