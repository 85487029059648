import React, {useCallback} from 'react';
import './MedicineCard.scss';
import OptionDots from "../../OptionDots/OptionDots";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Edit from '../../../assets/img/icons/edit.svg';

const MedicineCard = ({medicineInfo, selected, onSelect, patientPermissions, doctorPermissions, userInfo, getMedicineToEdit, removeMedicine, disableDots, withQuantity, onQuantityChange}) => {
    const handleClick = useCallback(() => {
        if (!withQuantity)
            onSelect(medicineInfo);
    }, [medicineInfo, onSelect, withQuantity]);


    const catchMedicineToRemove = id => () => removeMedicine(id);
    const catchMedicineDataToEdit = data => () => getMedicineToEdit(data);

    const switchStates = (medicineInfo, patientPermissions, doctorPermissions) => {
        if (doctorPermissions) {
            return (
                <div className="optionDots-wrapper">
                    {medicineInfo.createdBy.id === userInfo.id
                    &&
                    <span className="actions-container">
                            <button type="button" className="button--type__edit"
                                    onClick={catchMedicineDataToEdit(medicineInfo)}>
                                <img src={Edit} alt="Editar medicamento"/>
                            </button>
                            <button type="button" className="button--type__remove"
                                    onClick={catchMedicineToRemove(medicineInfo.id)}>
                                <FontAwesomeIcon icon={faTimesCircle}/>
                            </button>
                        </span>
                    }
                </div>
            )
        } else if (patientPermissions) {
            return (
                <div className="optionDots-wrapper">
                    {medicineInfo.createdBy.id === userInfo.id
                    &&
                    <span className="actions-container">
                            <button type="button" className="button--type__remove"
                                    onClick={catchMedicineToRemove(medicineInfo.id)}>
                                <FontAwesomeIcon icon={faTimesCircle}/>
                            </button>
                        </span>
                    }
                </div>
            );
        } else if (disableDots) {
            return;
        } else {
            return (
                <div className='optionDots-wrapper'>
                    <OptionDots/>
                </div>
            )
        }
    }

    return (

        <div className="MedicineCard">
            {switchStates(medicineInfo, patientPermissions, doctorPermissions)}
            <div className="container" onClick={handleClick}>

                {!onQuantityChange ?
                    selected ? <FontAwesomeIcon icon={faCheck}/> :
                        <div className={medicineInfo.icon === 'circle' ? 'pills-img' : 'oval-pills-img'}>
                            <div className={medicineInfo.icon === 'circle' ? 'circle-pill' : 'oval-pill'}/>
                        </div> : null}


                {withQuantity ?
                    <div className='quantity-changer'>
                        <span onClick={() => onQuantityChange(medicineInfo, -1)} className='less'>-</span>
                        {medicineInfo.quantity ? medicineInfo.quantity : 0}
                        <span onClick={() => onQuantityChange(medicineInfo, 1)} className='more'>+</span>
                    </div> : null}


                <p className='medicine-name'>
                    {medicineInfo.name}
                    {
                        //Temporal rule: Medicines group created by patients contains null, if let this without rule the program breaks.
                        medicineInfo.medicineGroup !== null &&
                        <span className='medicine-group'>{medicineInfo.medicineGroup.name}</span>
                    }
                </p>
            </div>
        </div>
    )
};
export default MedicineCard