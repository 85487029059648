import './FormCreator.scss';
import React, {useCallback, useEffect} from "react";
import _ from "lodash";

const FormCreator = ({
                         children,
                         dataModel,
                         formClassName = "",
                         formModel,
                         handleFormData,
                         handleInputData,
                         editMode = false,
                         autoComplete = 'off'
                     }) => {
    const [data, setData] = React.useState(dataModel);

    useEffect(() => {
        if (!data || (data && typeof data === 'object' && _.isEmpty(data))) {
            setData(dataModel);
        }
    }, [data, dataModel, setData]);

    const handleInputChange = useCallback((event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
        handleInputData && handleInputData(name, value);
    }, [data, setData, handleInputData]);

    const handleSubmitForm = useCallback((event) => {
        console.log(data);
        event.preventDefault();
        handleFormData(data);
    }, [data, handleFormData]);

    return (
        <form
            className={`FormCreator ${formClassName}`}
            onSubmit={handleSubmitForm}
        >
            <fieldset>
                {editMode ? (
                    <>
                        {formModel &&
                        formModel.map((element, index) => {
                            const Tag = element.tag;
                            return (
                                <label key={index}>
                                    {element.title && element.title}
                                    {
                                        Tag &&
                                        <Tag
                                            autoComplete={autoComplete}
                                            onChange={handleInputChange}
                                            name={element.name}
                                            type={element.type}
                                            placeholder={element.placeholder}
                                            defaultValue={data[element.name]} r
                                            {...element.attributes}
                                        />
                                    }
                                    {
                                        element.component &&
                                        element.component
                                    }
                                </label>
                            );
                        })}
                    </>
                ) : (
                    <>
                        {formModel &&
                        formModel.map((element, index) => {
                            const Tag = element.tag;
                            return (
                                <label key={index}>
                                    {element.title && element.title}
                                    {
                                        Tag &&
                                        <Tag
                                            autoComplete={autoComplete}
                                            onChange={handleInputChange}
                                            type={element.type}
                                            name={element.name}
                                            placeholder={element.placeholder}
                                            defaultValue={data[element.name]}
                                            {...element.attributes}
                                        />
                                    }
                                    {element.component &&
                                    element.component
                                    }
                                </label>
                            );
                        })}
                    </>
                )}
                {children}
            </fieldset>
        </form>
    );
};
export default FormCreator;