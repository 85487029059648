import './PainFace.scss';
import {ReactComponent as HeadFace} from "../../assets/img/head-face.svg";
import {ReactComponent as HeadFaceSad} from "../../assets/img/head-face-sad.svg";

import React from "react";

const getIntensityColor = intensity =>{
   if(intensity <=5 ){
       return 'green';
   }
   else {
       return 'red';
   }
};

const getIntensityFace = intensity =>{
    if(intensity<=5)
        return <HeadFace className='small-face'/>;
    else
        return <HeadFaceSad className='small-face'/>;
};

const PainFace = ({intensity, classNames}) => {
    return (
        <div className={`PainFace ${classNames}`}>
            <div className={'face-wrapper circled-back ' + getIntensityColor(intensity) }>
                {getIntensityFace(intensity)}
            </div>
        </div>
    )
};

export default PainFace;