import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {ReactComponent as HeadIcon} from "../../assets/img/head-face-sad.svg";
import './IntensitySlider.scss';
import Draggable from "react-draggable";
import classNames from 'classnames';
import useCallbackCreator from "use-callback-creator/src";

const headWidth=35;
const headStyle={width:headWidth};
const IntensitySlider = ({min=1, max=10, step=1, value, onChange})=>{

    const containerRef=useRef(null);
    const [width, setWidth]=useState(0);

    //We need to save the component width for two reasons:
    // - Calculate the percentage of the slider when moved
    // - Limit the x coordinate to prevent that it will get dragged out of the component
    useLayoutEffect(()=>{
        if(containerRef.current)
            setWidth(containerRef.current.offsetWidth);
    },[]);
    //And update the size whenever it changes
    useEffect(()=>{
        const resizeListener=()=>setWidth(containerRef.current.offsetWidth);
        window.addEventListener('resize', resizeListener);
        return ()=>window.removeEventListener('resize', resizeListener);
    },[]);

    const steps= useMemo(()=>{
        const nums=[];
        for(let i=min; i<=max; i+=step)
            nums.push(i);
        return nums;
    },[min, max, step]);

    // -----------        Handle dragging
    const handleDrag=useCallback((evt, dragInfo)=>{
        const pos=min+((max-min)*dragInfo.x/(width-headWidth));
        onChange(Math.round(pos));
    },[width, min, max, onChange]);

    // ---------     Direct selection
    const handleNumberClick=useCallbackCreator(onChange,[]);

    return (
        <div className='IntensitySlider' ref={containerRef}>
            <h4>Intensidad:</h4>
            <Draggable
                axis={'x'}
                bounds={{left:0, right: width-headWidth}}
                onDrag={handleDrag}
                position={{x:(value-min)*(width-headWidth)/(max-min), y:0}}
            >
                <div className='handle'>
                <HeadIcon className='HeadIcon' style={headStyle}/>
                <div className='small-circle'/>
                </div>
            </Draggable>
            <div className='guide-line'/>
            <div className='numbers-wrapper'>
                {steps.map(i=>
                    <span
                        key={i}
                        onClick={handleNumberClick(i)}
                        className={classNames('step-label', value===i&&'active')}>
                        {i}
                    </span>)}
            </div>
            <div className='level-texts'>
                <span>Muy ligero</span>
                <span>Severo</span>
            </div>
        </div>
    );
};

export default IntensitySlider;
