import Login from "../../scenes/Login/Login";
import Register from "../../scenes/Register/Register";
import ResetPassword from "../../scenes/ResetPassword/ResetPassword";
import ForgotPassword from "../../scenes/ForgotPassword/ForgotPassword";

export const paths={
    login:'/login',
    register:'/register',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password'
};

const notLoggedRoutes=[
    {path:paths.login, component: Login},
    {path:paths.register, component: Register},
    {path:paths.resetPassword, component: ResetPassword},
    {path:paths.forgotPassword, component: ForgotPassword},
];

export default notLoggedRoutes;
