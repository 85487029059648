import React from 'react';

const DropDown = ({ 
    primaryContent, 
    wrapperContent, 
    dropdownClassName   = "", 
    wrapperClassName    = ""
}) => {
    return (
        <div className={`DropDown ${dropdownClassName}`}>
            {primaryContent}
            <div className={`${wrapperClassName}`}>
                {wrapperContent}
            </div>
        </div>
    );
};
export default DropDown;


/*
    <button type="button">
        <h5>{me.doctor.name}</h5>
        <FontAwesomeIcon icon={faCaretDown}/>
    </button>
    <nav>
        <LogoutButton showLegend legend="Cerrar Sesión"/>
    </nav>
*/