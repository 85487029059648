import React from 'react';

const RemoveOption = props => {

    function removeSelectedOption(){
        const { id, entity, api } = props;
        api[entity].delete({id: id});
    }

    return (
        <button 
        onClick={ removeSelectedOption } 
        className='button button--action__primary'
        >
        {props.children}
        </button>
    )
}

export default RemoveOption;