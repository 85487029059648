import React, { useEffect, useMemo } from 'react';
import './ContextMenu.scss';
import { createPortal } from 'react-dom';
import useCallbackCreator from "use-callback-creator";

const ContextMenu = ({ options = [], onSelect, top, left, onClose }) => {

    const style = useMemo(() => ({ top, left }), [top, left]);

    const handleSelect = useCallbackCreator((index, e) => {
        e.stopPropagation();
        onSelect && onSelect(options[index]);
    }, [options, onSelect]);

    useEffect(() => {
        document.addEventListener('click', onClose);
        return () => document.removeEventListener('click', onClose);
    }, [onClose]);

    return createPortal(
        <div className='ContextMenu' style={style}>
            <ul className='options-list'>
                {options.map((opt, i) =>
                    <li key={i} onClick={handleSelect(i)} className='option-li'>
                        {opt.content}
                    </li>
                )}
            </ul>
        </div>
        , window.document.body);
};

export default ContextMenu;
