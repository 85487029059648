import { copyPropertyOrNull, copyPropertyOrThrow, serverDateFormat } from "../../services/formUtils";
import moment from "moment";

export const preparePatientFormForServer = (patientInfo, user) => {
    const patient = {};
    const newUser = { role: 4 }//Adding a role id: When we're create a profile, the role was defined "null".;

    copyPropertyOrThrow(patientInfo, patient, 'name', 'Debes llenar el nombre');
    copyPropertyOrThrow(patientInfo, patient, 'lastName', 'Debes llenar el apellido');
    copyPropertyOrThrow(patientInfo, newUser, 'email', 'Debes llenar el email');
    if (user) {
        copyPropertyOrNull(user, newUser, 'id');
        copyPropertyOrNull(patientInfo, newUser, 'password');
    }
    else
        copyPropertyOrThrow(patientInfo, newUser, 'password', 'Debes llenar la contraseña');


    if (!patientInfo.birthDate || !moment(patientInfo.birthDate).isValid())
        throw new Error('Ingresa una fecha valida');

    patient.birthDate = moment(patientInfo.birthDate).format(serverDateFormat);
    patient.gender = patientInfo.gender ? 'F' : 'M';//It's a boolean because we use a Switch in the form
    patient.user = newUser;

    return patient;
};

export const prepareFormFromPatient = (patient) => {
    const form = { ...patient };
    form.birthDate = moment(patient.birthDate).format('YYYY-MM-DD');
    form.gender = patient.gender === 'F';
    form.email = patient.user && patient.user.email;
    return form;
};

export const patientListSGroups = [
    'app_file_read',
    'patient_read',
    'patient_read_user',
    'patient_read_profile_image',
    'user_read_id',
    'user_read_email',
    'patient_read_medicines',
    'medicine_read_id',
    'medicine_read_name',
    'patient_read_diagnostics',
    'diagnostic_read_id',
    'diagnostic_read_name'
];