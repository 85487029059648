import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import './NewEpisode.scss';
import DateCarousel from "./components/DateCarousel/DateCarousel";
import TimePicker from 'rc-time-picker';
import IntensitySlider from "../../../components/IntensitySlider/IntensitySlider";
import useBoolean from "../../../hooks/useBoolean";
import PainLocationPicker from "../../../components/PainLocationPicker/PainLocationPicker";
import BlockPicker from "./components/BlockPicker/BlockPicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Splash from "../../../components/Splash/Splash";
import { Link, withRouter } from "react-router-dom";
import classNames from 'classnames';
import {
    editSGroups,
    migraineRecordForForm,
    prepareMigraineRecordForServer
} from "../../../services/modelUtils/migraineRecordUtils";
import { ApiContext } from "../../../services/api/api-config";
import { useSelector } from "react-redux";
import { ReactComponent as BackBtn } from "../../../assets/img/icons/Back-icon.svg";
import { useParams } from 'react-router-dom';
import MedicineList from "../../../components/MedicineList/MedicineList";
import _ from 'lodash';
import {medicineListSGroups} from '../../../services/modelUtils/medicineUtils';

const loadingId = '@NewEpisode.saving';
const NewEpisode = ({ history }) => {
    const api = useContext(ApiContext);
    const { id } = useParams();

    // --- STATES ---
    const [date, setDate] = useState(moment());
    const [intensity, setIntensity] = useState(1);
    const [locations, setLocations] = useState([]);
    const [medicines, setMedicines] = useState([]);
    const [triggers, setTriggers] = useState([]);
    const [symptoms, setSymptoms] = useState([]);
    const [painCharacteristics, setPainCharacteristics] = useState([]);
    const [durationHours, setDurationHours] = useState('');
    const [durationMinutes, setDurationMinutes] = useState('');
    const [patientComments, setPatientComments] = useState('');

    // --- BOOLEANS ---
    const [displayLocation, , , toggleLocation] = useBoolean();
    const [displayTriggers, , , toggleTriggers] = useBoolean();
    const [displaySymptoms, , , toggleSymptoms] = useBoolean();
    const [displayMedicine, , , toggleMedicine] = useBoolean();
    const [displayPainCharacteristics, , , togglePainCharacteristics] = useBoolean();

    // -------  Loading record for edit
    const me = useSelector(({api}) => api.me);
    const migraineRecordsProp = "@NewEpisode.MigraineRecords";
    const medicinesProp = "@NewEpisode.Medicines";
    const triggersProp = "@NewEpisode.Triggers";
    const symptomsProp = "@NewEpisode.Symptoms";
    const painCharacteristicsProp = "@NewEpisode.PainCharacteristics";
    useEffect(() => {
        if (id) {
            api.migraineRecords.get({ params: { id, sGroups: editSGroups, pagination: false }, customProp: migraineRecordsProp });
            api.medicines.get({ params: {sGroups: medicineListSGroups, pagination: false}, customProp: medicinesProp});
            api.triggers.get({customProp: triggersProp, params: {pagination: false}});
            api.symptoms.get({customProp: symptomsProp, params: {pagination: false}});
            api['pain_characteristics'].get({customProp: painCharacteristicsProp, params: {pagination: false}});
        }
    }, [api, id]);

    const oldRecords = useSelector(({ api }) => api[migraineRecordsProp]) || [];
    const apiSymptoms = useSelector(({ api }) => api[symptomsProp]) || [];
    const apiTriggers = useSelector(({ api }) => api[triggersProp]) || [];
    const apiMedicines = useSelector(({ api }) => api[medicinesProp]) || [];
    const apiPainCharacteristics = useSelector(({ api }) => api[painCharacteristicsProp]) || [];

    useEffect(() => {
        if (!id ||_.isEmpty(oldRecords) || !apiMedicines || apiMedicines.length===0){
            return;
        } else {
            const [oldRecord] = oldRecords;
            const form = migraineRecordForForm(oldRecord);

            //NOTA:
            //Hago estos pequeños filtros temporales porque veo que en migraineRecords contiene información que ya no se encuentra en el servidor (bueno sí).
            //
            //Por ejemplo: Hay medicinas en los migraineRecords que tienen diferente id. Puede que tengan el mismo nombre que las medicinas disponibles en el
            //endpoint ‘medicines’  pero el id es diferente y por ende, al momento de actualizar un episodio de migraña, el servidor lanza un error.
            //
            //Lo mismo pasa con triggers. Entonces supongo que lo mismo pasaría con síntomas y características de dolor:
            const viewIfFormMedicinesMatch = _.map(apiMedicines, (med) => {
                const newMed = { ...med };
                const mrm = _.filter(form.migraineRecordMedicines, (mrm) => mrm.medicine.id === med.id);
                for(let index in mrm){
                    if (mrm[index].medicine.id === newMed.id) {
                        newMed.quantity = mrm[index].quantity;
                    }
                }
                return newMed;
            }).filter( med => (med.createdBy.id === me.id) ||(med.type === "Abortivos"));

            const viewIfTriggersMatch = form.triggers.filter(trigger => apiTriggers.some(trigger2 => trigger.id === trigger2.id));
            const viewIfSymptomsMatch = form.symptoms.filter(symptom => apiSymptoms.some(symptom2 => symptom.id === symptom2.id));
            const viewIfPainCharacteristicsMatch = form.painCharacteristics.filter(pain => apiPainCharacteristics.some(pain2 => pain.id === pain2.id));


            setDate(moment.utc(form.fromDate));
            setIntensity(form.intensity);
            setLocations(form.painLocations || []);
            setTriggers(viewIfTriggersMatch);
            setSymptoms(viewIfSymptomsMatch);
            setMedicines(viewIfFormMedicinesMatch);
            setPainCharacteristics(viewIfPainCharacteristicsMatch);
            setDurationHours(Math.floor(form.durationMinutes / 60) || 0);
            setDurationMinutes(form.durationMinutes % 60 || 0);
            setPatientComments(form.comments || '');
        };
        return () => api.clearProperty(migraineRecordsProp);
    }, [oldRecords, id, apiSymptoms, apiMedicines, apiPainCharacteristics, apiTriggers, me.id, api]);

    // -------  Saving data ----------
    const [error, setError] = useState(null);
    const [saved, didSave] = useBoolean();
    const saveEpisode = useCallback(() => {
        if (moment(date).isAfter()) {
            return setError('No es posible asignar días futuros como fecha de este episodio');
        } else {
            setError(false);
            const migraineRecord = {
                fromDate: date,
                intensity,
                painLocations: locations,
                triggers,
                symptoms,
                medicines,
                painCharacteristics,
                comments: patientComments,
                durationMinutes: parseInt(durationHours||0) * 60 + parseInt(durationMinutes||0)
            };
            let prepared;
            try {
                prepared = prepareMigraineRecordForServer(migraineRecord);
            } catch (e) {
                setError(e.detail);
            }
            api.migraineRecords[id ? 'update' : 'create']({ id, params: {...prepared, sGroups: editSGroups}, loadingId, customProp: migraineRecordsProp })
                .then(didSave)
        }
    }, [api, didSave, date, intensity, locations, triggers, symptoms, medicines, id, durationHours, durationMinutes, painCharacteristics, patientComments]);


    const loading = useSelector(({ loadingIds }) => !!loadingIds[loadingId]);
    const focusDurationInput = (e) => {
        e.currentTarget.firstElementChild.focus();
    };
    const onChangeDurationMinutes = (e) => {
        setDurationMinutes(e.currentTarget.value);
    };
    const onChangeDurationHours = (e) => {
        setDurationHours(e.currentTarget.value);
    };

    const handlePatientCommentValues = event => {
        const { value } = event.currentTarget;
        setPatientComments(value);
    };

    return (
        <div className={classNames('NewEpisode', saved && 'saving')}>

            {saved &&
                <Splash>
                    <h2 className='logo-title'>Registro guardado</h2>
                    <p className='save-message'>¡Esperamos que te mejores pronto!</p>
                    <Link to={'/'}>
                        <button className='Button'>Regresar al inicio</button>
                    </Link>
                </Splash>}

            <div className='second-space'>
                <div className='top-space green'>
                    <button className='backBtn-datePicker'>
                        <BackBtn onClick={history.goBack} />
                    </button>
                    <h1 className='title'>{id ? 'Episodio Guardado' : 'Nuevo Episodio'}</h1>
                    <div className='calendar'>
                        <div className='date-string'>
                            {date.format('D MMMM YYYY')}
                        </div>
                        <DateCarousel value={date} onChange={setDate} />
                        <div className='time-selector'>
                            <p><b>Hora:</b> <span className='light'>(Registra la hora en que comenzó)</span></p>
                            <div>
                                <TimePicker
                                    className='hour-picker'
                                    value={date}
                                    onChange={setDate}
                                    showSecond={false}
                                    allowEmpty={false}
                                />
                            </div>
                        </div>
                        <div className='time-selector'>
                            <p><b>Duración:</b> <span className='light'>(Registra la duración del dolor)</span></p>
                            <div>
                                <div className='duration'>
                                    <div className='hours' onClick={focusDurationInput}>
                                        <input type="number" min="0" placeholder="(Agregar horas)" onChange={onChangeDurationHours} value={durationHours} />
                                        <label>Horas</label>
                                    </div>
                                    <div className='dots'>:</div>
                                    <div className='minutes' onClick={focusDurationInput} >
                                        <input type="number" min="0" placeholder="(Agregar minutos)" onChange={onChangeDurationMinutes} value={durationMinutes} />
                                        <label>Minutos</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='intensity-slider'>
                    <IntensitySlider
                        value={intensity}
                        onChange={setIntensity}
                    />
                </div>

                <div className="extra-blocks">
                    <h4>Parámetros:</h4>
                    <div className='extra-block'>
                        <button className='extra-btn Button' onClick={toggleLocation}>
                            <span className="plusIcon">
                                {locations.length ?
                                    <FontAwesomeIcon icon={faCheck} /> :
                                    <FontAwesomeIcon icon={faPlus} />
                                }
                            </span>
                            Localización
                        </button>
                        {displayLocation &&
                            <PainLocationPicker
                                className={'animated zoomInUp faster'}
                                value={locations}
                                onChange={setLocations}
                            />}
                    </div>
                    <div className='extra-block'>
                        <button className='extra-btn Button' onClick={toggleMedicine}>
                            <span className="plusIcon">
                                {medicines.length ?
                                    <FontAwesomeIcon icon={faCheck} /> :
                                    <FontAwesomeIcon icon={faPlus} />
                                }
                            </span>
                            Medicamentos
                        </button>
                        {displayMedicine &&
                            <MedicineList
                                dynamicMode
                                searchMedicineType="Abortivos"
                                patientsCanAddMedicines={true}
                                selected={medicines}
                                onChange={setMedicines}
                                medicinesAsProp={(id) ? medicines : null}
                                customProp={medicinesProp}
                                withQuantity
                                onQuantityChange={setMedicines}
                            />
                        }
                    </div>
                    <div className='extra-block'>
                        <button className='extra-btn Button' onClick={toggleTriggers}>
                            <span className="plusIcon">
                                {
                                    triggers.length
                                        ? <FontAwesomeIcon icon={faCheck} />
                                        : <FontAwesomeIcon icon={faPlus} />
                                }
                            </span>
                            Detonantes
                        </button>
                        {displayTriggers &&
                            <BlockPicker
                                entity='triggers'
                                value={triggers}
                                onChange={setTriggers}
                                placeHolder='Agrega un nuevo detonante'
                            />}
                    </div>
                    <div className='extra-block'>
                        <button className='extra-btn Button' onClick={toggleSymptoms}>
                            <span className="plusIcon">
                                {symptoms.length ?
                                    <FontAwesomeIcon icon={faCheck} /> :
                                    <FontAwesomeIcon icon={faPlus} />
                                }
                            </span>
                            Síntomas acompañantes
                        </button>
                        {displaySymptoms &&
                            <BlockPicker
                                entity='symptoms'
                                value={symptoms}
                                onChange={setSymptoms}
                                blockGrayScale={true}
                                placeHolder='Agrega un nuevo síntoma'
                            />}
                    </div>

                    <div className='extra-block'>
                        <button className='extra-btn Button' onClick={togglePainCharacteristics}>
                            <span className="plusIcon">
                                {painCharacteristics.length ?
                                    <FontAwesomeIcon icon={faCheck} /> :
                                    <FontAwesomeIcon icon={faPlus} />
                                }
                            </span>
                            Características de Dolor
                        </button>
                        {displayPainCharacteristics &&
                            <BlockPicker
                                entity='pain_characteristics'
                                value={painCharacteristics}
                                onChange={setPainCharacteristics}
                                placeHolder='Agrega una nueva característica'
                            />}
                    </div>
                </div>
            </div>
            <div className="patient-comments-section">
                <h4>Comentarios:</h4>
                <form>
                    <label>
                        Cuéntanos sobre tu episodio de migraña:
                        <textarea
                            type="text"
                            placeholder="Ej. Comienzo a tener intensos mareos..."
                            value={patientComments}
                            onChange={handlePatientCommentValues}
                        />
                    </label>
                </form>
            </div>
            <div className='save-wrap'>
                {error && <p>{error}</p>}
                <button className='Button' onClick={saveEpisode} disabled={loading}>
                    {loading ?
                        'Guardando...' :
                        'Guardar Episodio'}
                </button>
            </div>
        </div>
    );
};

export default withRouter(
    ({ history, location, match, staticContext, ...allProps }) => <NewEpisode history={history} {...allProps} />
);

/*export default NewEpisode;*/
