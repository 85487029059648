import './DoctorsHome.scss';
import { isEmpty } from 'lodash';
import React, {useState, useEffect, useContext, useCallback, useRef} from 'react';
import Plus from '../../assets/img/icons/plus.svg';
import {ApiContext} from '../../services/api/api-config';
import {useSelector} from 'react-redux';
import moment from "moment";
import {patientListSGroups} from "../../utils/modelUtils/patientUtils";
import {paths} from "../../services/routes/appRoutes";
import {Link, useHistory} from "react-router-dom";
import DotsButtons from "./components/DotsButton/DotsButtons";
import ContextMenu from "../../components/ContextMenu/ContextMenu";
import Modal from '../../components/Modal/Modal';
import DoctorProfileTopBar from './components/DoctorProfileTopBar/DoctorProfileTopBar';
import UserCircle from '../../assets/img/icons/user-circle.svg';
import {getNotifier} from "../../services/notifierUtils";
import PatientForm from './components/PatientForm/PatientForm';
import PatientFilters from './components/PatientFilters/PatientFilters';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare, faSquare} from "@fortawesome/free-regular-svg-icons";
import SponsorSplash from "../../components/SponsorSplash/SponsorSplash";
import _ from 'lodash';

const contextMenuOptions = [
    {content: 'Ver perfil', code: 'PROFILE'},
    {content: 'Reenviar bienvenida', code: 'SEND_WELCOME'},
    {content: 'Editar', code: 'EDIT'},
    {content: 'Eliminar', code: 'DELETE'}
];
const patientsCustomProp = "@DoctorsHome.Patients";
const sendWelcomeCustomProp = "@DoctorsHome.users.send_welcome";

//Modal actions
const REMOVE_PATIENT = "REMOVE_PATIENT";
const MANAGE_PATIENT_DATA = "MANAGE_PATIENT_DATA";

const DoctorsHome = () => {
    //Initializers
    const didMount = useRef(true);
    const api = useContext(ApiContext);
    const loadingId = "@DoctorsHome.Loader";
    const me = useSelector(({api}) => api.me);

    //State initializers
    const initModal = {action: null, dependencies: null};

    //State
    const [modal, setModal] = useState(initModal);
    const [filteredPatients, setFilteredPatients] = useState([]);

    //State cleaners
    const clearModal = () => setModal(initModal);

    // Init API Calls
    useEffect(() => {
        const params = {sGroups: patientListSGroups, pagination: false}
        api.patients.get({params, customProp: patientsCustomProp})
    }, [api]);

    //Redux
    const patients = useSelector(({api}) => api[patientsCustomProp]) || [];
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);

    useEffect(() => {
        if(didMount.current && !isEmpty(patients)){
            setFilteredPatients(patients);
            didMount.current = false;
        }
        if(!didMount.current){
            setFilteredPatients(patients);
        }
    }, [patients]);

    //Methods
    const deletePatient = ({id}) =>
        api.patients.delete({id, customProp: patientsCustomProp,loadingId})
        .then(() => {
            clearModal();
            api.me.get();
        })
        .catch(error =>
            getNotifier().error(error?.detail || "Hubo un problema al intentar eliminar esta cuenta")
        );

    // ----------   Context menu    ------------------
    const [contextMenu, setContextMenu] = useState(null);
    const openContextMenu = patient => e => {
        e.preventDefault();
        e.stopPropagation();

        const y = e.pageY;
        const x = e.pageX;
        setContextMenu({patient, top: y, left: x});
    };
    const closeContext = useCallback(() => setContextMenu(null), []);

    const history = useHistory();
    const handleContextClick = useCallback(option => {
        const {patient} = contextMenu;
        switch (option.code) {
            case 'PROFILE':
                history.push(paths.patientProfile.replace(':id', contextMenu.patient.id));
                break;
            case 'SEND_WELCOME':
                api.users.sendWelcome({userId: contextMenu.patient.user.id, customProp: sendWelcomeCustomProp})
                    .then(() => {
                        getNotifier().success('Contraseña enviada');
                    })
                    .catch(e => {
                        getNotifier().error(e.detail);
                    });
                break;
            case 'EDIT':
                setModal({action: MANAGE_PATIENT_DATA, dependencies: {patient}});
                break;
            case 'DELETE':
                setModal({
                    action: REMOVE_PATIENT,
                    dependencies: {message: "¿Estás seguro que quieres eliminar esta cuenta?", patient}
                });
                break;
            default:
                break;
        }
        closeContext();
    }, [history, contextMenu, closeContext, api]);


    const [, setShareDataAllowed] = useState(false);

    const toggleDataSharing = useCallback((e,patient)=>{
        e.preventDefault();
        api.users.toggleDataSharingPermissions({patient: patient.id}).then((data)=>{
            const newFilteredPatients = _.map(filteredPatients, (pat)=> {
                return patient.id === pat.id ? {...patient, dataSharingAllowedByDoctor: data.dataSharingAllowedByDoctor} : pat;
            });
            setFilteredPatients(newFilteredPatients);
        })

    },[api, filteredPatients, setFilteredPatients]);


    useEffect(()=>{
        if(me.doctor){
            setShareDataAllowed(me.doctor.allowDataSharing);
        }
    }, [me, setShareDataAllowed]);

    const [sponsor,setSponsor] = useState(null);
    useEffect(()=>{
        if(me.doctor){
            setSponsor(me.doctor.sponsor)
        }
    }, [me])

    return (
        <div className='DoctorsHome'>
            <SponsorSplash sponsor={sponsor}/>
            <DoctorProfileTopBar me={me}/>
            <div id="finder" className="d-flex justify-content-between align-items-center flex-wrap"
                 style={{background: (me && me.doctor.sponsor && me.doctor.sponsor.appConfig && me.doctor.sponsor.appConfig.color) ? me.doctor.sponsor.appConfig.color.hex : null}}
            >
                <div className="d-flex align-items-center finder-container">
                    <PatientFilters patients={patients} setFilteredPatients={setFilteredPatients}/>
                </div>
                <div className="d-flex align-items-center">
                    <button
                        type="button"
                        className="create-patient"
                        onClick={() => setModal({action: MANAGE_PATIENT_DATA})}
                    >
                        <img src={Plus} alt="Agregar paciente"/>
                        Agregar paciente
                    </button>
                </div>
            </div>

            <div className='container-fluid cards-container'>
                <h6>Número de licencias: {me.doctor.usedLicences} / {me.doctor.assignedLicences}</h6>
                <div className='row'>
                    {(!isEmpty(filteredPatients))
                    ? (filteredPatients.map((patient, index) =>
                        <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3 patient-col'>
                            <div className="patient-card">
                                <Link to={paths.patientProfile.replace(':id', patient.id)}>

                                    <div className="profile-info">
                                        <h4 className='name'>{`${patient.name} ${patient.lastName}`}</h4>
                                        <img
                                            className={patient.profileImage !== null ? 'profile-photo' : 'no-photo'}
                                            src={patient.profileImage !== null ? api.appFiles.getFileUrl(patient.profileImage) : UserCircle}
                                            alt="Fotografía del paciente"
                                        />
                                    </div>
                                    <h6 className='patient-info'>
                                        <span>Género:</span> {patient.gender === 'M' ? 'Masculino' : 'Femenino'}</h6>
                                    <h6 className='patient-info'>
                                        <span>Edad:</span> {
                                        (patient.birthDate) ? moment().diff(patient.birthDate, 'years') : "Sin especificar"
                                        }</h6>
                                    <h6 className='patient-info'><span>Correo:</span> {patient.user.email}</h6>
                                    <p className='patient-info'>
                                        <span>Medicinas:</span> {patient.medicines.length > 0 ? patient.medicines.map(medicine => medicine.name).join(', ') : 'Aún sin asignar'}
                                    </p>
                                    <p className='patient-info'>
                                        <span>Diagnostico:</span> {patient.diagnostics.length > 0 ? patient.diagnostics.map(diagnostic => diagnostic.name).join(', ') : 'Aún sin asignar'}
                                    </p>
                                    <p className='patient-info'>
                                        <span>Comparte información:</span> {patient.allowDataSharing?'Si':'No'}
                                    </p>
                                    <div className='toggleDataSharing' onClick={(e)=>toggleDataSharing(e,patient)}>
                                                <span className="plusIcon">
                                                    <FontAwesomeIcon icon={patient.dataSharingAllowedByDoctor?faCheckSquare:faSquare}/>
                                                </span> Deseo compartir información del paciente con AMCEMIG para fines  para fines de estudio y/o estadísticos. Compartir la información es opcional y anónimo.
                                    </div>

                                </Link>
                                <p className='patient-info'>
                                    <a href={api.migraineRecords.exportUrl({
                                        patient,
                                        filters: {pagination: false, patient: patient.id}
                                    })}>
                                        <button className='btn btn-success'>Descargar registros</button>
                                    </a>
                                </p>
                                <DotsButtons onClick={openContextMenu(patient)}/>
                            </div>
                        </div>
                    ))
                    : "Por ahora no tienes pacientes. Intenta añadir uno."
                    }
                </div>
            </div>
            {contextMenu &&
            <ContextMenu
                options={contextMenuOptions}
                onSelect={handleContextClick}
                onClose={closeContext}
                top={contextMenu.top}
                left={contextMenu.left}
            />
            }
            {/* Modal Zone */}
            {
                (modal.action === MANAGE_PATIENT_DATA) &&
                <Modal
                    title={(modal?.dependencies?.patient) ? "Editar cuenta" : "Crear cuenta"}
                    onClose={clearModal}
                >
                    <PatientForm patient={modal?.dependencies?.patient} onSubmitFinished={clearModal} customProp={patientsCustomProp}/>
                </Modal>
            }
            {
                (modal.action === REMOVE_PATIENT) &&
                <Modal
                    onClose={clearModal}
                    title="Eliminar paciente"
                    classNames="modal-warning"
                    description={<h6 className="modal-warning-message">{modal.dependencies.message}</h6>}
                >
                    <div className="take-action">
                        <button className="button-action-remove" onClick={() => deletePatient(modal.dependencies.patient)}>
                            {(loading) ? "Eliminando..." : "Eliminar"}
                        </button>
                        <button className="button-action-cancel"onClick={clearModal}>Cancelar</button>
                    </div>
                </Modal>
            }
        </div>
    );
};
export default DoctorsHome;