import './DiagnosticsManagement.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import MiscManagement from '../../components/MiscManagement/MiscManagement';
import DoctorProfileTopBar from "../../components/DoctorProfileTopBar/DoctorProfileTopBar";
import { ApiContext } from '../../../../services/api/api-config';
import { patientListSGroups } from '../../../../utils/modelUtils/patientUtils';
import DataTable from '../../../../components/DataTable/DataTable';

const DiagnosticsManagement = () => {
    const api = React.useContext(ApiContext);
    const diagnosticListSGroups = ['diagnostic_read_id', 'diagnostic_read_name', 'diagnostic_read_patients', 'patient_read'];

    //API CALLS
    React.useEffect(() => {
        function getPatients() {
            api.patients.get({
                params: {
                    sGroups: patientListSGroups
                }
            });
        }
        getPatients();
    }, [api])

    //API CALL DATA
    const me = useSelector(({ api }) => api.me);
    const patients = useSelector(({ api }) => api.patients) || [];


    const TableHeaders = () => (
        <React.Fragment>
            <th>Nombre del paciente</th>
            <th>Diagnostico</th>
        </React.Fragment>
    );

    return (
        <article>
            <DoctorProfileTopBar me={me} back={true} />
            <section>
                <MiscManagement
                    name="Diagnóstico"
                    entity="diagnostics"
                    sGroups={diagnosticListSGroups}
                    noBlameCreator={true}
                    addSearchBar={true}
                    formPlaceholder={['Escribe un Diagnóstico', 'Edita un Diagnóstico']}
                    searchBarPlaceholder="Buscar Diagnóstico"
                />
            </section>
            <hr />
            <section className="container patient-diagnostic-container">
                <h4>Pacientes diagnosticados:</h4>
                <DataTable headers={<TableHeaders />} >
                    {
                        patients.map(patient => (
                            <tr role="row" key={patient.id}>
                                <td>{patient.name} {patient.lastName}</td>
                                {
                                    patient.diagnostics.length > 0
                                        ? (
                                            <td>
                                                {
                                                    patient.diagnostics.map(diagnostic =>
                                                        <span key={diagnostic.id} className="diagnostic-container">{diagnostic.name}</span>
                                                    )
                                                }
                                            </td>
                                        )
                                        : <td>Sin información</td>
                                }
                            </tr>
                        ))

                    }
                </DataTable>
            </section>
        </article>
    );
}
export default DiagnosticsManagement;