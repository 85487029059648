import './SponsorList.scss';

import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import Plus from '../../../assets/img/icons/plus.svg';
import Trash from '../../../assets/img/icons/trash.svg';
import {ApiContext} from '../../../services/api/api-config';
import Modal from '../../../components/Modal/Modal';
import TopBar from '../../../components/TopBar/TopBar';
import Sorter from '../../../components/Sorter/Sorter';
import DataTable from '../../../components/DataTable/DataTable';
import SearchBar from '../../../components/Searchbar/SearchBar';
import FormCreator from '../../../components/FormCreator/FormCreator';
import {listSGroups} from '../../../utils/modelUtils/sponsorUtils';
import LogoutButton from '../../../components/LogoutButton/LogoutButton';
import {sorterConfig, DataTableHeaders} from './components.config';
import {getNotifier} from "../../../services/notifierUtils";
import {dataCreateModel, formCreateModel, formEditModel, prepareEditModel} from './form.config';
import {sponsor} from '../../../utils/modelUtils/roles';
import useCallbackCreator from "use-callback-creator";
import moment from "moment";

function Association() {
    /* ---- STATES ---- */
    const [errorModal, setErrorModal] = React.useState(false);
    const [modalState, setModalState] = React.useState(false); //Form create state
    const [editMode, setEditMode] = React.useState(false); //Form edit state
    const [typingState, setTypingState] = React.useState(false);    //Checks the getEditInputData function
    const [error, setErrorState] = React.useState(false);    // check if there's a form mistake or not.
    const [errorMessage, setErrorMessage] = React.useState('');       //Sets a error message.
    const [searchVal, setSearchVal] = React.useState('');       //SearchBar State
    const [order, setOrder] = React.useState('');       //Sorter State: Type of order
    const [orderVal, setOrderVal] = React.useState('');       //Sorter State: Order value
    const [dataEditModel, setDataEditModel] = React.useState({}); //State for get the prepared editModel from prepareEditModel() function
    const [removingSponsorId, setRemovingSponsorId] = useState(null);

    const loadingId = '@Sponsors.apiCalls';
    const sendingWelcomeLoadingId = '@Sponsors.users.sendingWelcome';
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);
    const loadingIds = useSelector(({loadingIds}) => loadingIds);

    /* ---- API CALLS---- */
    const api = React.useContext(ApiContext);


    const fetchSponsors = useCallback(()=>{
        api.sponsors.get({
            params: {
                sGroups: listSGroups,
                name: searchVal,
                [order]: orderVal
            }
        });
    }, [api, order, searchVal, orderVal])

    React.useEffect(() => {
        fetchSponsors();
    }, [fetchSponsors]);

    const data = useSelector(({api}) => api.sponsors) || [];

    /* --- COMPONENTS METHODS ---- */
    //Search Method
    const handleSearchValues = values => setSearchVal(values)

    const getEditInputData = () => setTypingState(true);


    const removeSponsor = id => () => api.sponsors.delete({id: id}).catch(e => {
        console.log(id);
        setErrorModal(true);
        setErrorMessage(e.detail);
    });


    const handleFormData = (sGroups, method, currentCachedData) => data => {
        const params = {
            sGroups: sGroups,
            name: data.name,
            contactName: data.contactName,
            assignedLicences: parseInt(data.assignedLicences)
        };
        const sendData = () => {
            if (method !== 'create') {
                if (data.email !== currentCachedData.email) {
                    params.user = {...params.user, id: data.userId, email: data.email}
                }
            } else {
                if (data.email) {
                    //params.usedLicences = 0;
                    params.user = {
                        role: sponsor,
                        email: data.email
                    };
                }
            }
            api.sponsors[method](
                method === 'create'
                    ? {params: params, loadingId}
                    : {id: data.id, params: params, loadingId}
            )
                .then(() => setModalState(false))
                .catch(error => {
                    setErrorState(true);
                    setErrorMessage(error.detail);
                });
            setErrorState(false);
        }

        //Validations
        if (method === 'create') {
            const maxLength = 3;
            if (!data['name'] || data['name'].length < maxLength) {
                setErrorState(true);
                setErrorMessage('El nombre del laboratorio debe tener más de ' + maxLength + ' caracteres');
            } else if (!data['contactName'] || data['contactName'].length < maxLength) {
                setErrorState(true);
                setErrorMessage('El nombre de contacto debe tener más de ' + maxLength + ' caracteres');
            } else if (!data['email'] || data['email'].length < maxLength) {
                setErrorState(true);
                setErrorMessage('El email debe tener más de ' + maxLength + ' caracteres');
            } else if (data['email'].includes('@') === false || data['email'].includes('.') === false) {
                setErrorState(true);
                setErrorMessage('Parece ser que el email no es válido');
            } else {
                sendData();
            }
        } else {
            if (data['assignedLicences'] < data['usedLicences']) {
                setErrorState(true);
                setErrorMessage(`No es posible asignar menos licencias de las utilizadas: Actualmente has usado ${data['usedLicences']} licencias.`);
            } else {
                sendData();
            }
        }
    };

    const sendWelcome =  useCallbackCreator((id, e) => {
        e.stopPropagation();
        api.users.sendWelcome({userId: id, customProp: sendingWelcomeLoadingId + id})
            .then((response) => {
                fetchSponsors();
                getNotifier().success('Contraseña enviada');
            })
            .catch(e => {
                getNotifier().error(e.detail);
            });
    }, [fetchSponsors])



    // SORTER METHODS
    const handleSelectedOption = option => {
        if (option === 'name') {
            setOrder('order[name]');
            setOrderVal('ASC');
        } else {
            setOrder('order[assignedLicences]');
            setOrderVal('DESC');
        }
    }

    const preparedEditModel = data => {
        return setDataEditModel(prepareEditModel(data));
    }
    const ModalDescription = ({isEditing}) => <p className="description">Completa los campos
        para {isEditing ? 'editar' : 'crear'} una cuenta</p>;
    ;

    const manageAccount = (data, accountConfig) => api.sponsors.update(
        {
            id: data.id,
            params: {
                sGroups: listSGroups,
                user: {
                    id: data.userId,
                    isActive: accountConfig
                }
            },
            loadingId
        })
        .then(() => {
            getNotifier().success(`${data.name} ha sido ${accountConfig ? 'activado' : 'desactivado'}.`);
            setModalState(false)
        })
        .catch((error) => {
            setErrorState(true);
            setErrorMessage(error.detail);
        });

    return (
        <div className='Sponsors fluid-container'>
            <TopBar isInDesktop={true} customClassName="topBar" childrenClassName="justify-content-between">
                <h1 className="title">Asignación de Licencias</h1>
                <LogoutButton classNames="logout-white"/>
            </TopBar>

            <div className="sponsor-content">
                <header className="row align-items-center">
                    <div className="col-lg-9 d-flex align-items-center">
                        <SearchBar className="searchBar" onInputChange={handleSearchValues}
                                   placeHolder="Buscar laboratorio" mustDebounceValues={true} debounceTime={200}/>
                        <Sorter title="Ordenar por:" config={sorterConfig} getSelectedOption={handleSelectedOption}/>
                    </div>
                    <div className="col-lg-3 d-flex justify-content-end">
                        <button className="create-button" onClick={() => {
                            setModalState(true);
                            setEditMode(false)
                        }}>
                            <img src={Plus} alt="Add Element"/>
                            Nueva licencia
                        </button>
                    </div>
                </header>


                <DataTable headers={<DataTableHeaders/>}>
                    {data && data.map((element, rowIndex) =>
                        <tr role="row" key={rowIndex}>
                            <td className={element.user.isActive ? '' : 'disabled'}>
                                <div className={element.user.isActive ? 'lab-active' : 'lab-disabled'}></div>
                                {element.name}
                            </td>
                            <td className={element.user.isActive ? '' : 'disabled'}>{element.contactName}</td>
                            <td className={element.user.isActive ? '' : 'disabled'}>{element.user.email}</td>
                            <td className={element.user.isActive ? '' : 'disabled'}>{element.assignedLicencesToPatients + '/' + element.usedLicences + '/' + element.assignedLicences}</td>
                            <td>{element.sharedInfo}</td>
                            <td>
                                <span className="actions">
                                    <button className="edit" onClick={() => {
                                        setModalState(true);
                                        setEditMode(true);
                                        preparedEditModel(data[rowIndex]);
                                    }}>Editar</button>

                                    <button className="edit send-welcome" onClick={sendWelcome(element.user.id)}
                                            disabled={!!loadingIds[sendingWelcomeLoadingId + element.id]}>
                                        {element.welcomeMailSentDate ? 'Reenviar' : 'Envíar'} bienvenida

                                        {element.welcomeMailSentDate &&
                                         <small>Envíada el {moment(element.welcomeMailSentDate).format('DD-MM-Y hh:mm')}</small>
                                        }
                                    </button>
                                    <button className="remove" onClick={() => setRemovingSponsorId(element.id)}>
                                        <img src={Trash} alt="Eliminar"/>
                                    </button>
                                </span>
                            </td>
                        </tr>
                    )}
                </DataTable>
                {
                    modalState &&
                    <Modal
                        title={editMode ? 'Editar cuenta' : 'Nueva cuenta'}
                        description={<ModalDescription isEditing={editMode}/>}
                        onClose={() => {
                            setModalState(false);
                            setErrorState(false);
                            setTypingState(false);
                        }
                        }
                    >
                        <FormCreator
                            editMode={editMode ? true : false}
                            dataModel={editMode ? dataEditModel : dataCreateModel}
                            formModel={editMode ? formEditModel : formCreateModel}
                            formClassName={error ? 'animated shake' : ''}
                            handleFormData={editMode ? handleFormData(listSGroups, 'update', dataEditModel) : handleFormData(listSGroups, 'create')}
                            handleInputData={getEditInputData}
                            mustGetInputData={true}
                        >
                            {error &&
                            <div className="alert alert-danger animated fadeIn" role="alert">
                                {errorMessage}
                            </div>
                            }
                            <button
                                type="submit"
                                disabled={typingState ? false : true}
                                className={typingState ? "active" : "muted"}
                            >
                                {
                                    !editMode
                                        ? loading ? 'Enviando...' : 'Crear Cuenta'
                                        : loading ? 'Enviando...' : 'Actualizar Cuenta'
                                }
                            </button>
                            {
                                editMode &&
                                <button
                                    type="button"
                                    className={
                                        dataEditModel.isActive
                                            ? 'mute-account'
                                            : 'active-account'
                                    }
                                    onClick={
                                        () =>
                                            dataEditModel.isActive
                                                ? manageAccount(dataEditModel, false)
                                                : manageAccount(dataEditModel, true)
                                    }
                                >
                                    {
                                        dataEditModel.isActive
                                            ? loading ? 'Desactivando...' : 'Desactivar Cuenta'
                                            : loading ? 'Activando...' : 'Activar Cuenta'
                                    }
                                </button>
                            }
                        </FormCreator>
                    </Modal>
                }
                {
                    errorModal &&
                    <Modal
                        title="Mensaje de Error"
                        onClose={() => setErrorModal(false)}>
                        <div className="animated shake alert alert-danger m-0" role="alert">
                            <p>{errorMessage}</p>
                            <button type="button" className="accept-error"
                                    onClick={() => setErrorModal(false)}>Entendido
                            </button>
                        </div>
                    </Modal>
                }
                {
                    removingSponsorId &&
                    <Modal
                        title="Advertencia!"
                        onClose={() => setErrorModal(false)}>
                        <div className="animated shake alert alert-danger m-0" role="alert">
                            <p>Todos los datos asociados a este patrocinador serán eliminados, incluidos doctores
                                registrados, pacientes y sus registros.</p>
                            <p>¿Estás seguro de borrar esa información?</p>
                            <button type="button" className="btn btn-danger"
                                    onClick={removeSponsor(removingSponsorId)}>Si, borrar todo.
                            </button>
                            &nbsp;
                            <button type="button" className="btn btn-info"
                                    onClick={() => setRemovingSponsorId(null)}>Cancelar
                            </button>
                        </div>
                    </Modal>
                }
            </div>
        </div>
    );
}

export default Association;