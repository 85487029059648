import React, {useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../../services/api/api-config";
import {useSelector} from "react-redux";
import TriggerBar from "./TriggerBar";
import './MainTriggersReport.scss';


const MainTriggersReport = () => {
    const colors = [
        'red',
        'red',
        'green',
        'blue',
        'purple',
        'green',
        'purple',
        'yellow',
        'gray',
        'black',
        'red',
        'green',
        'purple',
        'yellow',
        'gray',
        'black'
    ];

    const api = useContext(ApiContext);
    //const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);
    const triggersReport = useSelector(({api}) => api.triggersReport || []);
    useEffect(() => {
        api.migraineRecords.triggersReport();
    }, [api]);

    const totalTriggersCount = useMemo(()=>{
        return triggersReport.reduce((a, b)=>{
            if(b.triggerId!==null)
                return a+parseInt(b.count);
            else
                return a;
            }
            ,0)
    }, [triggersReport]);

    const getTriggerPercentage = (count)=>{
       return parseInt((count/totalTriggersCount) *100);
    };


    return (
        <>
        {
            triggersReport.length !== 0 &&
            <div className='MainTriggersReport'>

                {triggersReport.length && triggersReport.map((report, key) => (
                    report.triggerId?
                    <TriggerBar
                        key={key}
                        color={colors[key]}
                        iconClass={report.triggerIcon}
                        triggerName={report.triggerName}
                        triggerPercentage={getTriggerPercentage(report.count)}
                    />:null
                ))}
            </div>
        }
        </>
    )
};

export default MainTriggersReport;