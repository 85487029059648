import './MedicineListPage.scss';
import React from 'react';
import { ApiContext } from '../../../services/api/api-config';
import { useSelector } from 'react-redux';
import Plus from '../../../assets/img/icons/plus.svg';
import Modal from '../../../components/Modal/Modal';
import SearchBar from '../../../components/Searchbar/SearchBar';
import FormCreator from '../../../components/FormCreator/FormCreator';
import MedicineList from '../../../components/MedicineList/MedicineList';
import { medicineListSGroups } from '../../../services/modelUtils/medicineUtils';
import DoctorProfileTopBar from '../components/DoctorProfileTopBar/DoctorProfileTopBar';
import _ from 'lodash';
import MedicineFilter from '../../../components/MedicineList/MedicineFilter/MedicineFilter';

const MedicineListPage = () => {

    const loadingId = '@Patients.apiCalls';
    const loading = useSelector(({ loadingIds }) => !!loadingIds[loadingId]);

    //STATES
    const [modalState, setModalState] = React.useState(false);
    const [editMode, setEditMode] = React.useState(false);
    const [errorState, setErrorState] = React.useState(false);
    const [searchVal, setSearchVal] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [medicineGroup, setMedicineGroup] = React.useState('0') //State for SelectMedicinesGroup Component
    const [medicineType, setMedicineType] = React.useState('0');
    const [dataEditModel, setDataEditModel] = React.useState({});
    const [medicineSearchType, setMedicineSearchType] = React.useState(null);


    //API CONFIG
    const api = React.useContext(ApiContext);
    React.useEffect(() => {
        api['medicine_groups'].get();
        let params = {sGroups: medicineListSGroups, pagination:false};

        if(searchVal) params.name = searchVal;
        if(medicineSearchType) params.type = medicineSearchType;

        api.medicines.get({params});
    }, [api, medicineSearchType, searchVal]);

    const me = useSelector(({ api }) => api.me);
    const medicinesGroup = useSelector(({ api }) => api['medicine_groups']) || [];
    const medicines = useSelector(({ api }) => api.medicines);



    //SEARCH METHOD
    const handleInputValues = values => setSearchVal(values.trim());

    //FORM METHODS
    const handleFormData = (method, id) => data => {

        //Set external data from components to our dataCreateModel:
        data.medicineGroup = medicineGroup === 0 ? null : medicineGroup;
        data.type = medicineType;

        if (data.name.length === 0) {
            setErrorState(true);
            setErrorMessage('Nombra un medicamento');
        } else {
            const params = {
                sGroups: medicineListSGroups,
                name: data.name,
                type: data.type,
                medicineGroup: data.medicineGroup
            };
            api.medicines[method](
                {
                    id: method === 'update' && id,
                    params,
                    loadingId
                }).then(() => {
                    setModalState(false);
                    setEditMode(false);
                }).catch(error => {
                    setErrorState(true);
                    setErrorMessage(error.detail);
                });
            setErrorState(false);
        }
    };
    //FORM COMPONENTS
    const SelectMedicineValues = ({ groups = [], defaultValue, setMedicineVal, types }) => {
        const handleSelectedOption = event => {
            const { value } = event.target;
            groups && setMedicineVal(parseInt(value));
            types && setMedicineVal(value);
        };
        return (
            <select className="SelectMedicinesGroup" defaultValue={defaultValue} onChange={handleSelectedOption}>
                <option value="0">--</option>
                {
                    groups &&
                    groups.map((element, index) => {
                        return element !== null &&
                            <option key={index} value={element.id}>{element.name}</option>
                    }
                    )
                }
                {
                    types &&
                    types.map((type, index) => <option key={index} value={type.value}>{type.typeName}</option>)
                }
            </select>
        );
    };

    const medicineTypes = [
        { typeName: "Abortivos", value: "Abortivos" },
        { typeName: "Preventivos", value: "Preventivos" }
    ];

    //FORM CONFIG
    const dataCreateModel = {
        name: "",
        type: "",
        medicineGroup: ""
    };
    const formCreateModel = [
        {
            title: "Nombre del Medicamento:",
            tag: "input",
            type: "text",
            name: "name",
            placeholder: "Acyclovir"
        },
        {
            title: "Tipo de medicamento:",
            component: <SelectMedicineValues
                types={medicineTypes}
                defaultValue={medicineType}
                setMedicineVal={setMedicineType}
            />
        },
        {
            title: "Grupo de medicinas:",
            component: <SelectMedicineValues
                groups={medicinesGroup}
                defaultValue={medicineGroup}
                setMedicineVal={setMedicineGroup}
            />
        }
    ];

    const enableEditMode = dataEditModel => {
        const findIdOfMedicineGroup = dataEditModel.medicineGroup !== null ? _.find(medicinesGroup,group => group.name === dataEditModel.medicineGroup.name) : '0';
        setModalState(true);
        setEditMode(true);
        setDataEditModel({
            id: dataEditModel.id,
            name: dataEditModel.name
        });
        setMedicineType(dataEditModel.type !== null ? dataEditModel.type : '0');
        setMedicineGroup(findIdOfMedicineGroup.id);
    }

    return (
        <article className="MedicineListPage">
            <DoctorProfileTopBar me={me} back={true} />
            <section className="container">
                <header className="d-flex align-items-center justify-content-between">
                    <SearchBar
                        onInputChange={handleInputValues}
                        placeHolder="Buscar medicamento"
                        mustDebounceValues={true}
                        debounceTime={10}
                        className="searchMedicineBar"
                    />
                    <button type="button" className="button-addMedicine" onClick={() => setModalState(true)}>
                        <img src={Plus} alt="Agregar medicamento" />
                        Nuevo Medicamento
                    </button>
                </header>

                <MedicineFilter onChange={setMedicineSearchType}/>
                <MedicineList
                    searchText={searchVal}
                    medicinesAsProp={medicines}
                    searchMedicineType={medicineSearchType}
                    enableEditMode={enableEditMode}
                    doctorsCanManageMedicines={true}
                    dynamicMode={medicineSearchType === 'Todos' ? false : true}
                />
            </section>

            {
                modalState &&
                <Modal
                    title={editMode ? "Editar medicamento" : "Nuevo Medicamento"}
                    description={<p className="modal-description">Completa los siguientes campos para {editMode ? 'editar' : 'añadir'} un medicamento.</p>}
                    onClose={() => {
                        setModalState(false);
                        setErrorState(false);
                        setEditMode(false);
                        setMedicineType('0');
                        setMedicineGroup('0');
                    }}>

                    <FormCreator
                        editMode={editMode}
                        dataModel={editMode ? dataEditModel : dataCreateModel}
                        formModel={formCreateModel}
                        formClassName={errorState ? 'animated shake' : ''}
                        handleFormData={editMode ? handleFormData('update', dataEditModel.id) : handleFormData('create')}
                    >
                        {errorState && <div className="alert alert-danger animated fadeIn">{errorMessage}</div>}
                        <button type="submit">{loading ? 'Enviando...' : `${editMode ? 'Editar' : 'Añadir'} medicamento`}</button>
                    </FormCreator>

                </Modal>
            }
        </article>
    );
}
export default MedicineListPage;