import './Recommendations.scss';
import React from 'react';
import TopBar from '../../../components/TopBar/TopBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch, faWalking, faCloudMoon, faMugHot, faPills, faUtensils, faSpa, faBrain} from '@fortawesome/free-solid-svg-icons';

const Recommendations = () => {
    const recommendationsList = [
        {text: "Identificar y evitar en lo posible los desencadenantes de su dolor de cabeza.", img: faSearch},
        {text: "Realizar ejercicio 3 veces por semana.", img: faWalking},
        {text: "Tener buenos hábitos de sueño.", img: faCloudMoon},
        {text: "Evitar el exceso de cafeína.", img: faMugHot},
        {text: "Limitar la ingesta de analgésicos a 2 veces por semana.", img: faPills},
        {text: "Evitar ayuno mayor a cuatro horas.", img: faUtensils},
        {text: "Mindfulness", img: faSpa},
        {text: "Biofeedback", img: faBrain},
    ];
    return (
        <article className="Recommendations">
            <header>
                <TopBar backButton childrenClassName="justify-content-center">
                    <h1 className="title">Recomendaciones</h1>
                </TopBar>
            </header>
            <section>
                <h5>Recomendaciones:</h5>
                <ul>
                   {
                       recommendationsList.map( (recommendation, index) => 
                            <li key={index} className="row">
                                <div className="col-lg-1">
                                    <FontAwesomeIcon icon={recommendation.img}/>
                                </div>
                                <div className="col-lg-11">
                                    {recommendation.text}
                                </div>
                            </li> 
                       )
                   } 
                </ul>
            </section>
        </article>
    ); 
};
export default Recommendations;