import React, {useContext, useEffect} from 'react';
import './SponsorSplash.scss';
import {useSelector} from "react-redux";
import {ApiContext} from "../../services/api/api-config";
import useBoolean from "../../hooks/useBoolean";

const SponsorSplash = ({sponsor}) => {
    const api = useContext(ApiContext);
    const me = useSelector(({api}) => api.me);

    //To prevent a flicker on start up, we force the splash screen to be visible for a second
    const [forceSplash, , stopSplash] = useBoolean(true);
    useEffect(() => {
        setTimeout(stopSplash, 3000);
    }, [stopSplash]);

    const shouldDisplaySponsorLogo = () => {
        if (me.patient && sponsor.showLogoToPatients) {
            return true;
        }
        if (me.doctor && sponsor.showLogoToDoctors) {
            return true;
        }
        return false;
    };

    return (
        <>
            {sponsor && forceSplash && shouldDisplaySponsorLogo() && sponsor.logo &&
            <div className='SponsorSplash'>
                <div className="initPage">
                    <div className="logo-wrapper">
                        <div className="logo-loading">
                            <img src={api.appFiles.getFileUrl(sponsor.logo)} alt="logo"/>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default SponsorSplash;
