import React from 'react';
import './TriggerBar.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as faIcons from '@fortawesome/free-solid-svg-icons';
import tinyColor from 'tinycolor2';

const TriggerBar = ({iconClass, color, triggerName, triggerPercentage}) => {
    return (
        <div className='TriggerBar'>
            <div className='icon-container' >
                <div className='icon' style={{color, backgroundColor: tinyColor(color).setAlpha(.4)}} >
                    <FontAwesomeIcon icon={faIcons['faCheck']}/>
                </div>
            </div>
            <div className='percentage-container'>
                <div className='trigger-name'>
                    {triggerName}
                </div>
                <div className='percentage-number'>
                    {triggerPercentage} %
                </div>
                <div className='percentage-bar' style={{backgroundColor: tinyColor(color).setAlpha(.4)}}>
                    <div className='percentage-bar-fill' style={{backgroundColor: color, width: triggerPercentage+'%'}}/>
                </div>
            </div>
        </div>
    )
};

export default TriggerBar;