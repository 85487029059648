import moment from 'moment';
import { patient } from '../../../../utils/modelUtils/roles';
import prependZero from '../../../../utils/prependZero';
import { isEmpty } from 'lodash';
export const validatePatientDataForServer = (data, setError, {date, gender, patient:patientToEdit}) => {
    const serverTimeFormat = 'YYYY-MM-DDTHH:mm';
    const {name, lastName, email, password} = data || {};

    if (!name) {
        setError('Olvidaste el nombre del paciente');
        return false;
    };

    if (!lastName){
        setError('Olvidaste el apellido del paciente');
        return false;
    };

    if (!email) {
        setError('Escribe un correo electrónico');
        return false;
    };

    if (email.length < 5 || !email.includes('@') || !email.includes('.')){
        setError('Parece que el correo que escribiste no es válido')
        return false;
    };

    if (!patientToEdit && (!password || password.length < 5)){
        setError('La contraseña debe tener más de 5 caracteres');
        return false;
    };

    //Insertion data
    const patientData = {name, lastName, user: {email, role: patient}};
    patientData.gender = (gender) ? "F" : "M";

    if(!isEmpty(date)){
        if(date.year && date.month && date.day){
            patientData.birthDate = moment(`${date.year}-${prependZero(date.month)}-${prependZero(date.day)}`).format(serverTimeFormat);
        } else {
            setError("La fecha de nacimiento no está completo");
            return false;
        }
    }

    if(patientToEdit){
        patientData.user = {...patientData.user, id: patientToEdit.user.id};
    }

    if(password){
        patientData.user = {...patientData.user, password};
    };

    //Remove error messages
    setError("");

    return patientData;
};