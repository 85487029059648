import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import _ from 'lodash';
import './BlockPicker.scss';
import RemoveOption from './RemoveOption';
import { useSelector } from "react-redux";
import useCallbackCreator from "use-callback-creator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiContext } from "../../../../../services/api/api-config";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as PlusIcon } from '../../../../../assets/img/icons/plus.svg';

const BlockPicker = ({ entity, accessor = 'name', onChange, value = [], placeHolder = '' }) => {
    // ------ Load data --------
    const api = useContext(ApiContext);
    const me = useSelector(({ api }) => api.me);

    useEffect(() => {
        api[entity].get({
            params: {
                "order[name]": "DESC",
                "pagination": false,
                "sGroups": [
                    "trigger_read",
                    "symptom_read",
                    'pain_characteristic_read',
                    "blame_creation",
                    'user_read_id'
                ]
            }
        });
    }, [api, entity, me]);
    const options = useSelector(({ api }) => api[entity]);

    // -------  display stuff --------
    const [left, right] = useMemo(() => {
        const assignColor = (x, index, thisArray) => {
            const length = thisArray.length - 1;
            if(index > length / 3 || index === length){
                x.colorLevel = "high";
            } else if (index <= length / 3 && index > length % 3){
                x.colorLevel = "medium";
            } else {
                x.colorLevel = "low";
            }
            return x;
        };

        return [
            _.filter(options, (o, i) => !(i % 2)).map(assignColor),
            _.filter(options, (o, i) => i % 2).map(assignColor)
        ]
    }, [options]);

    const paddings = useMemo(() => {
        if (!options) return [];
        const randomPaddings = [];
        for (let i = 0; i < options.length; i++)
            randomPaddings.push(Math.random() * 40 + 10);
        return randomPaddings;
    }, [options]);

    // ------ state and selection ------
    const handleSelect = useCallbackCreator(
        (id, option) => {
            const newVal = value ? [...value] : [];
            const oldIndex = _.findIndex(value, opt => opt.id === option.id);

            if (oldIndex === -1) {
                newVal.push(option);
            }
            else {
                newVal.splice(oldIndex, 1);
            }
            onChange(newVal);
        }, [onChange, value]);


    //  -- Form States --
    const [isTyping, setTyping] = useState(false);
    const [inputValue, setInputValue] = useState({ name: "" });


    const handleExitForm = useCallback(() => {
        setInputValue({ name: '' });
        setTyping(false)
    }, []);


    const handleSubmitForm = useCallback((event) => {
        event.preventDefault();
        api[entity].create({ params: inputValue })
            .then(res => onChange([...(value || []), res]));
        handleExitForm();
    }, [api, entity, inputValue, onChange, handleExitForm, value]);


    const handleInputChange = useCallback((event) => {
        const { value } = event.target;
        const alphaNumericRegex = /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g;
        const onlyAlphaNumericType = alphaNumericRegex.test(value);

        if (value !== '') {
            if (onlyAlphaNumericType === true) {
                setInputValue({ name: value })
                setTyping(true);
            }
        } else {
            handleExitForm();
        }

    }, [handleExitForm])

    return (
        <div className='BlockPicker container-fluid'>
            <div className='row'>
                <div className='col-6 block-picker-col'>
                    {
                        left.map((option, i) =>
                            <div key={option.id} className={`selectable-block green-${option.colorLevel}`}>
                                <div className='selection-mark'>
                                    {
                                        value && _.find(value, (v) => v.id === option.id)
                                            ? <FontAwesomeIcon icon={faCheck} />
                                            : option.createdBy.id !== me.id
                                                ? <PlusIcon className={'icon'} onClick={handleSelect(option.id, option)} />
                                                : <>
                                                    <PlusIcon className={'icon'} onClick={handleSelect(option.id, option)} />
                                                    <RemoveOption entity={entity} api={api} id={option.id}>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </RemoveOption>
                                                </>
                                    }
                                </div>
                                <div
                                    style={{ paddingTop: paddings[i * 2] }}
                                    onClick={handleSelect(option.id, option)}
                                >
                                    <p className='block-title'>{option[accessor]}</p>
                                </div>
                            </div>
                        )}
                </div>

                <div className='col-6 block-picker-col'>
                    {
                        right.map((option, i) =>
                            <div key={option.id} className={`selectable-block green-${option.colorLevel}`}>
                                <div className='selection-mark'>
                                    {
                                        value && _.find(value, (v) => v.id === option.id)
                                            ? <FontAwesomeIcon icon={faCheck} />
                                            : option.createdBy.id !== me.id
                                                ? <PlusIcon className={'icon'} onClick={handleSelect(option.id, option)} />
                                                : <>
                                                    <PlusIcon className={'icon'} onClick={handleSelect(option.id, option)} />
                                                    <RemoveOption entity={entity} api={api} id={option.id}>
                                                        <FontAwesomeIcon icon={faTimes} />
                                                    </RemoveOption>
                                                </>
                                    }
                                </div>
                                <div
                                    style={{ paddingTop: paddings[i * 2] }}
                                    onClick={handleSelect(option.id, option)}
                                >
                                    <p className='block-title'>{option[accessor]}</p>
                                </div>
                            </div>
                        )}
                </div>
            </div>


            <div className='row'>
                <div className='col-12 block-picker-col'>
                    <div className='selectable-block'>
                        <div className='selection-mark'>
                            <PlusIcon className={'icon'} />
                        </div>
                        <form onSubmit={handleSubmitForm}>
                            <input
                                placeholder={placeHolder}
                                onChange={handleInputChange}
                                value={inputValue.name} />
                            {
                                isTyping
                                    ? (
                                        <>
                                            <button type='submit' className=' button button--action__primary'>Guardar</button>
                                            <button type='button' className=' button button--action__secondary' onClick={handleExitForm}>Cancelar</button>
                                        </>
                                    )
                                    : <></>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockPicker;