import './DoctorProfileTopBar.scss';
import React from 'react';
import { paths } from "../../../../services/routes/appRoutes";
import { Link } from 'react-router-dom';
import DropDown from '../../../../components/DropDown/DropDown';
import LogoutButton from '../../../../components/LogoutButton/LogoutButton';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TopBar from '../../../../components/TopBar/TopBar';
import {version} from "../../../../pckg.json";

const DoctorProfileTopBar = ({ me, back = false }) => {
    return (
        <div className="DoctorProfile">
            <p className='version'>{version}</p>

            <TopBar isInDesktop={true} childrenClassName="custom-doctor-topbar-container">
                <h1 className="title">Gestión de Pacientes</h1>
                <div className="d-flex align-items-center">
                    <DropDown
                        dropdownClassName="management-dropdown"
                        wrapperClassName="management-dropdown-wrapper"
                        primaryContent={
                            <button type="button" disabled className="management-button">
                                Gestión
                                    <FontAwesomeIcon icon={faCaretDown} />
                            </button>
                        }
                        wrapperContent={
                            <ul>

                                <li><Link to={paths.doctorsHome}><button type="button" className="medicineList-button">Gestionar Pacientes</button></Link></li>

                                <li><Link to={paths.medicineListPage}><button type="button" className="medicineList-button">Gestionar Medicamentos</button></Link></li>

                                <li><Link to={paths.medicineGroupsPage}><button type="button" className="medicineList-button">Gestionar Grupos medicinales</button></Link></li>

                                <li><Link to={paths.triggersManagement}><button type="button" className="medicineList-button">Gestionar Detonantes</button></Link></li>

                                <li><Link to={paths.symptomsManagement}><button type="button" className="medicineList-button">Gestionar Síntomas</button></Link></li>

                                <li><Link to={paths.painCharacteristicsManagement}><button type="button" className="medicineList-button">Gestionar Características de dolor</button></Link></li>

                                <li><Link to={paths.diagnosticsManagement}><button type="button" className="medicineList-button">Gestionar Diagnósticos</button></Link></li>
                            </ul>
                        } />
                    <DropDown
                        dropdownClassName="profile-dropdown"
                        wrapperClassName="dropdown-wrapper"
                        primaryContent={
                            <button type="button">
                                <h5>{me.doctor.name} {me.doctor.lastName}</h5>
                                <FontAwesomeIcon icon={faCaretDown} />
                            </button>
                        }
                        wrapperContent={
                            <nav>
                                {
                                    back
                                        ? <LogoutButton legend="Regresar" back />
                                        : <LogoutButton legend="Cerrar Sesión" />
                                }
                            </nav>
                        }
                    />
                </div>
            </TopBar>
        </div>
    );
};

export default DoctorProfileTopBar;