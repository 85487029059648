import './LogoutButton.scss'
import React from 'react'
import {ApiContext} from '../../services/api/api-config';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt, faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";


const LogoutButton = ({classNames, legend, back=false}) => {
    const history = useHistory();
    const api = React.useContext(ApiContext);

    return ( 
        <button 
            className={ classNames ? classNames : "Logout-Button" } 
            onClick={ () => back ? history.push('/') : api.logout()}
        >
            {legend && legend}
            { back 
                ? <FontAwesomeIcon icon={faUndoAlt} />
                : <FontAwesomeIcon icon={faSignOutAlt} />
            }
        </button>
    )
}
export default LogoutButton;
