import _ from 'lodash';
import React from 'react';
import './SearchBar.scss';
import {ReactComponent as SearchIcon} from "../../assets/img/icons/searchIcon.svg";
import classNames from 'classnames';

const SearchBar = ({className, onInputChange, placeHolder, inputProps, mustDebounceValues, debounceTime}) => {

    //Debounce Method
    let delayTimer;
    const inputDataHandled = value => {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => onInputChange(value), debounceTime);
    }
    const handleInputDebounced = event => {
        const {value} = event.currentTarget;
        _.debounce(inputDataHandled, debounceTime)(value);
    }


    const onChange = (event)=>{
        onInputChange(event.target.value);
    };

    const classes = classNames(
        'SearchBar',
        className
    );


    return (
        <div className={classes}>
            <SearchIcon className='search-icon' />
            <input className='Search-input' onChange={ mustDebounceValues ?  handleInputDebounced : onChange} placeholder={placeHolder} {...inputProps}/>
        </div>
    )
};
export default SearchBar
