import './Sorter.scss';
import React from 'react';

const Sorter = ({config, title, getSelectedOption}) => {
    const handleSelectedOption = event => {
        const {value} = event.target;
        getSelectedOption(value);
    } 

    return (
        <form className="Sorter">
            <label>{title}</label>
            <select defaultValue={config.defaultValue} onChange={handleSelectedOption}>
                { 
                config.options.map( (element, index) => 
                    <option key={index} value={element.value}>{element.title}</option>
                )
                }
            </select>
        </form>
    );
}

export default Sorter;