import React, {useContext, useEffect} from 'react';
import './ExpisodeList.scss';
import TopBar from "../../../components/TopBar/TopBar";
import EpisodeRow from "./Components/EpisodeRow";
import {ApiContext} from "../../../services/api/api-config";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {paths} from "../../../services/routes/appRoutes";
import moment from 'moment';
import _ from 'lodash';

const loadingId = 'EpisodeList';
const EpisodeList = () => {
    const api = useContext(ApiContext);
    const me = useSelector(({api}) => api.me);
    const migraineRecords = useSelector(({api}) => api.migraineRecords || []);
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);

    useEffect(() => {
        api.migraineRecords.get({
            params: {
                'order[fromDate]': 'DESC',
                'order[id]': 'DESC',
                'patient': me.patient.id
            }, loadingId
        });
    }, [api, me]);


    //GROUP ELEMENTS BY DATES
    const dates = migraineRecords.map(migraine => (
        {
            when: moment(migraine.fromDate).calendar(null, {
                sameDay: '[Hoy] (MMMM D)',
                lastDay: '[Ayer] (MMMM D)',
                lastWeek: '[La semana pasada]',
                sameElse: 'MMMM D (dddd)'
            }),
            episodes: migraine
        }
    ));
    const groupDates = _.groupBy(dates, 'when');
    const keys = [];
    for (let key in groupDates) {
        keys.push({when: groupDates[key][0].when, data: groupDates[key]});
    }


    return (
        <div className='EpisodeList'>
            <TopBar backButton childrenClassName="justify-content-center historyTopBar">
                <h1 className="title">Lista de episodios</h1>
            </TopBar>

            <div className='content scrollable-content gray-background'>
                {
                    loading ? <h5>Cargando...</h5> :
                        (
                            migraineRecords && !!migraineRecords.length
                                ?
                                <div>
                                    {
                                        keys.map((key, index) => {
                                            return (<React.Fragment key={index}>
                                                <h6 className="date-content">{key.when}</h6>
                                                {key.data.map(data =>
                                                    <EpisodeRow episode={data.episodes} key={data.episodes.id}/>
                                                )
                                                }
                                            </React.Fragment>)
                                        })
                                    }
                                </div>
                                :
                                <div className='no-episodes'>
                                    <h5>Aún no cuentas con episodios registrados</h5>
                                    <Link to={paths.newEpisode}>
                                        <button className='Button new-episode-btn'>+ Nuevo episodio</button>
                                    </Link>
                                </div>
                        )
                }
            </div>
        </div>
    );
};
export default EpisodeList;