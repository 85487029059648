import React, {useContext, useEffect, useMemo, useState} from 'react';
import './MigraineRecordCalendar.scss';
import {ApiContext} from "../../../../../services/api/api-config";
import Calendar from 'react-calendar';
import {useSelector} from "react-redux";
import {editSGroups} from "../../../../../services/modelUtils/migraineRecordUtils";
import _ from 'lodash';
import moment from 'moment';
//import NewEpisode from "../../../../Patient/NewEpisode/NewEpisode";

const loadingId = '@MigraineRecordCalendar.migraineRecords.get';

const MigraineRecordCalendar = ({patientId, onMonthChange}) => {

    const api = useContext(ApiContext);

    const monthMigraineRecords = useSelector(({api}) => api['PatientProfile.monthMigraineRecords']);
    const [selectedCalendarDate, setSelectedCalendarDate] = useState(moment());
    const [selectedCalendarMonthDate, setSelectedCalendarMonthDate] = useState(moment());
    //const sendingEmailLoadingId = '@Sponsors.sendingEmail.';
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);    //const [selectedEpisode, setSelectedEpisode] = useState(null);

    useEffect(() => {
        api.migraineRecords.get(
            {
                params: {
                    sGroups: editSGroups,
                    patient: patientId,
                    pagination: false,
                    'fromDate[after]': selectedCalendarMonthDate.startOf('month').format('YYYY-MM-DD 00:00:00'),
                    'fromDate[before]': selectedCalendarMonthDate.endOf('month').format('YYYY-MM-DD 23:59:59'),
                },
                customProp: 'PatientProfile.monthMigraineRecords',
                loadingId
            });
    }, [api, selectedCalendarMonthDate, patientId]);

    const datesWithEpisodes = useMemo(() => {
        return _.map(monthMigraineRecords, (migraineRecord) => {
            return moment(migraineRecord.fromDate).format('YYYY-MM-DD');
        })
    }, [monthMigraineRecords]);

    const episodesInSelectedDate = useMemo(() => {
        return _.filter(monthMigraineRecords, (mr) => moment(mr.fromDate).format('YYYY-MM-DD') === selectedCalendarDate.format('YYYY-MM-DD'))
    }, [selectedCalendarDate, monthMigraineRecords]);


    const getCalendarDayStyle = (activeDate) => {

        if (datesWithEpisodes.indexOf(moment(activeDate.date).format('YYYY-MM-DD')) !== -1) {
            return ['dayWithEpisode']
        }
    };

    const onCalendarMonthChange = ({activeStartDate}) => {
        setSelectedCalendarMonthDate(moment(activeStartDate));
        if(onMonthChange)
            onMonthChange(moment(activeStartDate));
        //setSelectedEpisode(null);
    };

    const onCalendarMonthClicked = (value) => {
        setSelectedCalendarMonthDate(moment(value));
        if(onMonthChange)
            onMonthChange(moment(value));
        //setSelectedEpisode(null);
    };


    const onCalendarDateChange = (value) => {
        setSelectedCalendarDate(moment(value));
    };

    /*
    const onEpisodeClick = useCallback((e)=>{
       const episodeId = parseInt(e.currentTarget.dataset.episodeid);
       setSelectedEpisode(_.find(monthMigraineRecords, (mr)=>mr.id===episodeId));
    },[monthMigraineRecords]);
    */
    return (

        <div className='MigraineRecordCalendar'>

            {datesWithEpisodes ?
                <Calendar
                    tileClassName={getCalendarDayStyle}
                    className={loading?'loading':''}
                    onActiveDateChange={onCalendarMonthChange}
                    onChange={onCalendarDateChange}
                    onClickMonth={onCalendarMonthClicked}
                /> : null}

            {episodesInSelectedDate.length ?
                <div className='migraine-records-container animated fadeInDown fast'>
                    {episodesInSelectedDate.map((migraineRecord, index) => (
                        <div className='episode' key={migraineRecord.id} data-episodeid={migraineRecord.id}>
                            <span className='ep-title'>Episodio #{index + 1}</span>
                            <span><b>Hora de inicio:</b> {moment(migraineRecord.fromDate).format('HH:mm')}</span>
                            <span><b>Duración:</b> {migraineRecord.durationMinutes ? (Math.floor(migraineRecord.durationMinutes / 60) + ' horas ' + migraineRecord.durationMinutes % 60 + ' minutos') : 'Sin especificar'}</span>
                            <span><b>Intensidad:</b> {migraineRecord.intensity}</span>
                            <span><b>Sintomas acompañates:</b> {migraineRecord.symptoms.length ? _.map(migraineRecord.symptoms, 'name').join(', ') : 'Ninguno'}</span>
                            <span><b>Desencadentantes:</b> {migraineRecord.triggers.length ? _.map(migraineRecord.triggers, 'name').join(', ') : 'Ninguno'}</span>
                            <span><b>Medicamentos:</b> {migraineRecord.migraineRecordMedicines.length ? _.map(migraineRecord.migraineRecordMedicines, (mrm) => `${mrm.medicine.name} (${mrm.quantity})`).join(', ') : 'Ninguno'}</span>
                            <span><b>Características de Dolor:</b> {migraineRecord.painCharacteristics.length ? _.map(migraineRecord.painCharacteristics, 'name').join(',') : 'Ninguno'}</span>
                            <span><b>Comentarios:</b> {migraineRecord.comments !== null ? _.map(migraineRecord.comments) : 'Ninguno'}</span>
                        </div>
                    ))}
                </div> : null
            }
            {
                /*selectedEpisode?
                <NewEpisode episode={selectedEpisode}/>:null

                 */
            }
        </div>

    )
};
export default MigraineRecordCalendar